<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="add"
                            :loading="dataForm.addLoading" v-if="page.isNew">Salvar</v-btn>
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="update"
                            :loading="dataForm.updateLoading" v-if="!page.isNew">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined
                            to="/colaboradores">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="Nome" v-model="dataset.data.name" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="Email" v-model="dataset.data.email" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-file-input dense accept="image/png, image/jpeg, application/pdf" prepend-icon=""
                                                outlined counter :rules="[$rules.fileSize(15), (value) => !!dataset.data.profile_url || !!value || 'Campo obrigatório']"
                                                hint="O arquivo não pode ser superior a 15MB" hide-details="auto"
                                                label="Imagem" :clearable="true" @change="onFileSelected($event)"
                                                ref="file">
                                            </v-file-input>

                                            <v-img  v-if="dataset.data.profile_url" 
                                                @click="openDoc(dataset.data.profile_url)" class="rounded-xl"
                                                :height="100" :width="100" :src="dataset.data.profile_url">
                                            </v-img>
                                         
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <p class="font-weight-bold">Descrição</p>
                                            <tiptap-vuetify v-model="dataset.data.description" :rules="[$rules.required]"
                                                :extensions="extensions" hide-details="auto" />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

        <v-dialog v-model="dialogViewDoc" max-width="60%">
            <v-card>
                <v-img :src="selectedDoc" alt="" contain />
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>

import string from "@/common/util/string"

import axios from "axios"

import {
    TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph
} from 'tiptap-vuetify'


export default {
    name: "PostDetails",

    components: { 'tiptap-vuetify': TiptapVuetify },

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            breadcrumbItems: [],
            data: {  
            },
        },
        dialogDynamicText: false,
        dynamicTextSmallType: false,
        selectedDoc: null,
        dialogViewDoc: false,
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            showPassword: false,
            showPasswordConfirmation: false,
        },
        extensions: [
            Underline,
            Strike,
            Italic,
            Bold,
            Paragraph,
        ],
    }),

    created() {
        this.loadData()
    },

    methods: {
        goBack() {
            this.$router.replace("/colaboradores");
        },

        openDoc(cover) {
            this.selectedDoc = cover;
            this.dialogViewDoc = true;
        },

        async loadData() {
            this.setPage();
            this.listBreadCrumbItems();
            await this.load();
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = `${this.page.isNew ? 'Novo colaborador' : 'Detalhes do colaborador'}`;
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Colaboradores",
                disabled: false,
                to: "/colaboradores",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.title,
                disabled: true,
                exact: true,
            });
        },

        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/api/admin/employees/${this.$route.params.id}`);
                console.log('result ', result)

                const data = result.data.content;

                this.dataset.data = data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },


        async add() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o colaborador?',
                title: 'Incluir colaborador',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/api/admin/employees', payload);

                this.$dialog.notify.info("Colaborador salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar dados do colaborador?',
                title: 'Atualizar colaborador',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/api/admin/employees/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Colaborador salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        onFileSelected(file) {
            this.dataset.data.file = file;
        },

        buildPayload() {
            const formData = new FormData()

            if (this.dataset.data.name)
                formData.append('name', this.dataset.data.name);

            if (this.dataset.data.email)
                formData.append('email', this.dataset.data.email);

            if (this.dataset.data.description)
                formData.append('description', this.dataset.data.description);

            if (this.dataset.data.file)
                formData.append('file', this.dataset.data.file)

            return formData;
        },

    },
};
</script>

<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}
</style>