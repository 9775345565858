<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›"
                            :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn v-on="on" id="btnBack" type="button" elevation="0" color="primary" outlined
                                    to="/agcs">Voltar</v-btn>
                            </template>
                            <span>Voltar para Assembleia</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
              <v-sheet elevation="1" class="pa-2" style="border-radius: 4px;" color="grey lighten-4">
                <v-col v-if='dataset.generalMeetingName' class="text-uppercase">
                  <span class="primary--text"> Assembléia - </span> <strong>{{ dataset.generalMeetingName }}</strong>
                </v-col>
                <v-col v-if='dataset.votingName' class="text-uppercase mt-n5">
                  <span class="primary--text">Votação - </span> <strong>{{ dataset.votingName }}</strong>
                </v-col>
              </v-sheet>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="filterSearch()">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field dense id="name" label="Nome" v-model="dataset.filter.name"
                                        hide-details outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-select dense :items="dataset.votingTypes" label="Votação"
                                        v-model="dataset.filter.voting" item-value="id" item-text="name" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn id="btnFilter" type="submit" color="primary" dark
                                        elevation="2">Filtrar</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="8">
                <v-card class="table-card">
                    <v-card-title class="table-card-title d-flex justify-space-between">Credores
                        <div class="icon-container">
                            <v-spacer></v-spacer>
                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-icon icon v-on="on" @click="generateCSV()" size="large">
                                        mdi-microsoft-excel
                                    </v-icon>
                                </template>
                                <span>Gerar CSV votação</span>
                            </v-tooltip>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhuma lista de nomes foi encontrada" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">
                            <template v-slot:[`item.name`]="{ item }">
                                <v-row class="align-center ">
                                    <v-col cols="12">
                                        <span><strong>{{ item.name.toUpperCase() }}</strong></span>
                                    </v-col>
                                </v-row>
                            </template>

                            <template v-slot:[`item.uc_type`]="{ item }">
                                <v-row class="align-center ">
                                    <v-col cols="12">
                                        <span>{{ item.uc_type | enum(dataset.creditorTypes, 'id', 'name') }}</span>
                                    </v-col>
                                </v-row>
                            </template>

                            <template v-slot:[`item.vote`]="{ item }">
                                <v-chip :color="getColorByStatus(item.vote ? item.vote : 0)" label>
                                    <template v-if="item.vote">
                                        <span :style="{ color: item.vote === 3 ? 'black' : 'white' }">
                                            {{ item.vote | enum(eventStatus, 'id', 'name') }}
                                        </span>
                                    </template>
                                    <template v-else>
                                        <span>Pendente</span>
                                    </template>
                                </v-chip>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon icon v-on="on" @click="actionGeneralMeeting(item, 3)" size="large"
                                            color="grey" class="me-2">
                                            mdi-circle-off-outline
                                        </v-icon>
                                    </template>
                                    <span>Votar NULO</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon icon v-on="on" @click="actionGeneralMeeting(item, 2)" size="large"
                                            color="red" class="me-2">
                                            mdi-close-circle-outline
                                        </v-icon>
                                    </template>
                                    <span>Votar NÃO</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon icon v-on="on" @click="actionGeneralMeeting(item, 1)" size="large"
                                            color="green" class="me-2">
                                            mdi-check-circle-outline
                                        </v-icon>
                                    </template>
                                    <span>Votar SIM</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="4">
                <v-card class="table-card">
                    <v-card-title class=" d-flex justify-space-between table-card-title ">Estatística

                        <div class="icon-container">
                            <v-spacer></v-spacer>
                            <template>
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon icon v-on="on" @click="generatePDF()" size="large" class="me-2">
                                            mdi-file-pdf-box
                                        </v-icon>
                                    </template>
                                    <span>Gerar laudo Assembleia</span>
                                </v-tooltip>
                            </template>
                            <template>
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon icon v-on="on" @click="openAssemblyResulModal()" size="large" class="mr-2">
                                            mdi-account-details
                                        </v-icon>
                                    </template>
                                    <span>Gerar resultado Assembleia</span>
                                </v-tooltip>
                            </template>
                            <template>
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon icon v-on="on" @click="generatePdfFinal()" size="large"
                                            >
                                            mdi-chart-bar
                                        </v-icon>
                                    </template>
                                    <span>Gerar relatório final</span>
                                </v-tooltip>
                            </template>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <div class="summary-item">
                            Participante(s): <span class="summary-value">{{ dataList.data.length }}</span>
                            <br />
                        </div>
                        <div class="summary-item">
                            SIM: <span class="summary-value color-yes">{{ yesCount }}</span> <br />
                            Percentual SIM: <span class="summary-value color-yes">{{ yesPercentage }} %</span>
                        </div>
                        <div class="summary-item">
                            NÃO: <span class="summary-value color-no">{{ noCount }}</span> <br />
                            Percentual NÃO: <span class="summary-value color-no">{{ noPercentage }} %</span>
                        </div>
                        <div class="summary-item">
                            NULO: <span class="summary-value color-null">{{ nullCount }}</span> <br />
                            Percentual NULO: <span class="summary-value color-null">{{ nullPercentage }} %</span>
                        </div>
                        <div class="summary-item">
                            PENDENTE: <span class="summary-value color-pending">{{ pendingCount }}</span> <br />
                            Percentual PENDENTE:
                            <span class="summary-value color-pending">{{ pendingPercentage }} %</span>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogAssemblyResult" max-width="800px">
            <v-card>
                <v-card-title class="modal-title">Resultado Assembleia</v-card-title>
                <v-card-text ref="pdfContent">
                    <br />

                    <!-- <v-progress-linear v-if="isLoadingModal" indeterminate color="primary"></v-progress-linear>

                    <div v-else> -->

                    <v-data-table :headers="assemblyResultData.headers" :items="assemblyResultData.items"
                        :loading="assemblyResultData.loading" :item-key="assemblyResultData.itemKey" hide-default-footer
                        :disable-sort="true" class="elevation-1 dasboard-voting-table" header-class="header-title"
                        loading-text="Carregando dados...">
                        <template v-slot:item.class="{ item }">
                            <span v-if="!item.final" :class="{ 'class-text': true }">{{
                                item.class | enum(dataset.creditorTypes, 'id', 'name')
                                }}</span>
                            <span v-else class="class-text">
                                <strong>{{ item.class }}</strong>
                            </span>
                        </template>

                        <template v-slot:[`item.creditors_number`]="{ item }">
                            <v-layout row wrap>
                                <v-flex xs="6">
                                    <div class="label-row">SIM</div>
                                    <div class="value-row">{{ item.creditors_number.yes }}</div>
                                </v-flex>
                                <v-flex xs="6">
                                    <div class="label-row">NÃO</div>
                                    <div class="value-row">{{ item.creditors_number.no }}</div>
                                </v-flex>
                            </v-layout>
                        </template>

                        <template v-slot:[`item.head`]="{ item }">
                            <v-layout row wrap>
                                <v-flex xs="6">
                                    <div class="label-row">SIM</div>
                                    <div class="value-row">{{ item.head.yes }}%</div>
                                </v-flex>
                                <v-flex xs="6">
                                    <div class="label-row">NÃO</div>
                                    <div class="value-row">{{ item.head.no }}%</div>
                                </v-flex>
                            </v-layout>
                        </template>

                        <template v-slot:[`item.credit`]="{ item }">
                            <v-layout row wrap>
                                <v-flex xs="6">
                                    <div class="label-row">SIM</div>
                                    <div class="value-row">{{ item.credit.yes }}%</div>
                                </v-flex>
                                <v-flex xs="6">
                                    <div class="label-row">NÃO</div>
                                    <div class="value-row">{{ item.credit.no }}%</div>
                                </v-flex>
                            </v-layout>
                        </template>

                        <template v-slot:[`item.plan`]="{ item }">
                            <v-chip :class="{
                                'green-chip': item.plan,
                                'red-chip': !item.plan
                            }" label>
                                <span>{{ item.plan ? 'Aprovado' : 'Rejeitado' }}</span>
                            </v-chip>
                        </template>
                    </v-data-table>

                    <br />
                    <span>Diante disso, seguindo os critérios do art. 45, da Lei n. 11.101/2005, o plano foi
                        rejeitado.
                    </span>

                    <!-- </div> -->
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="dialogAssemblyResult = false" color="primary" dark>Cancelar</v-btn>
                    <v-btn @click="generatePdfAssemblyResult()" color="primary" dark :loading="isLoadingPdfModal">Gerar
                        PDF</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>

import votingTypes from '@/common/data/voting-types.data'
import creditorTypes from '@/common/data/creditor-types.data'
import string from '@/common/util/string'

import object from '@/common/util/object'
import axios from 'axios'
import html2pdf from 'html2pdf.js'

export default {
    name: 'GeneralMeetingVoting',

    data: () => ({
        page: {
            isNew: false,
            title: ''
        },
        menu: false,
        dialogAssemblyResult: false,
        isLoadingPdfModal: false,
        isLoadingModal: false,
        dataset: {
            breadcrumbItems: [],
            date: null,
            data: {},
            clients: [],
            filter: {
                name: '',
                voting: '',
                general_meeting_id: -1
            },
            votingTypes: [ {id: "", name: "Todos"}, ...votingTypes],
            creditorTypes: creditorTypes,
            generalMeetingId: null
        },
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false
        },

        eventStatus: [
            { id: 0, name: 'Pendente' },
            { id: 1, name: 'SIM' },
            { id: 2, name: 'NÃO' },
            { id: 3, name: 'NULO' }
        ],
        assemblyResultData: {
            itemKey: 'name',
            headers: [
                { text: 'CLASSE', value: 'class', class: 'header-label' },
                { text: 'N. CREDORES', value: 'creditors_number', class: 'header-label' },
                { text: '% CABEÇA', value: 'head', class: 'header-label' },
                { text: '% CRÉDITO', value: 'credit', class: 'header-label' },
                { text: 'PLANO', value: 'plan', class: 'header-label' }
            ],
            items: [],
            loading: false
        },
        dataList: {
            headers: [
                { text: 'Nome', value: 'uc_name' },
                { text: 'Classe', value: 'uc_type' },
                { text: 'Votação', value: 'vote' },
                { text: 'Ações', value: 'actions', sortable: false }
            ],
            data: [],
            loading: false,
            //count: 11,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name'],
                sortDesc: [false]
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
                pageText: '{0}-{1} de {2}'
            }
        },
        yesCount: 0,
        noCount: 0,
        nullCount: 0,
        pendingCount: 0,

        yesPercentage: 0,
        noPercentage: 0,
        nullPercentage: 0,
        pendingPercentage: 0
    }),

    // mounted() {
    //     let data = this.$route.params.data;

    //     this.dataset.generalMeetingId = data.general_meeting_id;
    //     this.dataset.generalMeetingName = data.general_meeting_name;
    //     this.dataset.voting_id = data.voting_id;
    //     this.dataset.votingName = data.voting_name;
    //     this.dataset.voting_status = data.voting_status;
    //     this.dataset.voting_type = data.voting_type;


    //     console.log("Recebido: ", this.dataset.generalMeetingId, this.dataset.general_meeting_name, this.dataset.voting_id, this.dataset.voting_name,
    //         this.dataset.voting_status, this.dataset.voting_type
    //     )

    //     //this.loadVotingResult();

    //     this.loadData()

    // },

    created() {
        this.dataset.generalMeetingVotingId = this.$route.params.id
        this.loadData()
    },

    computed: {
        computedDateFormatted() {
            return this.formatDate(this.dataset.data.date)
        },

        checkedCountComputed() {
            return this.dataList.data.filter(item => item.checked).length
        },
        uncheckedCountComputed() {
            return this.dataList.data.filter(item => !item.checked).length
        },
        checkedPercentageComputed() {
            return ((this.checkedCountComputed / this.dataList.data.length) * 100).toFixed(2)
        },
        uncheckedPercentageComputed() {
            return ((this.uncheckedCountComputed / this.dataList.data.length) * 100).toFixed(2)
        }
    },

    methods: {
        pageChanged(page) {
            this.dataList.options.page = page
            this.filterSearch()
        },

        pageSizeChanged(size) {
            this.dataList.options.itemsPerPage = size
            this.filterSearch(true)
        },

        goBack() {
            this.$router.replace('/agcs')
        },

        async loadData() {
            this.setPage()
            this.listBreadCrumbItems()

            // await this.loadClients()
            //await this.load()
            await this.filterSearch()
            await this.updateSummary()
        },

        setPage() {
            this.page.title = `Votação`
        },

        formatDate(date) {
            if (!date) return null

            return this.$moment(date).format('DD/MM/YYYY')
        },
        parseDate(date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: 'Homepage',
                    disabled: false,
                    to: '/home',
                    exact: true
                }
            ]

            this.dataset.breadcrumbItems.push({
                text: 'AGC',
                disabled: false,
                to: '/agcs',
                exact: true
            })

            this.dataset.breadcrumbItems.push({
                text: `Votação`,
                disabled: true,
                exact: true
            })
        },

        async updateSummary() {
            const yesItems = this.dataList.data.filter( item => item.vote === 1 )
            const noItems = this.dataList.data.filter( item => item.vote === 2 )
            const nullItems = this.dataList.data.filter( item => item.vote === 3 )
            const pendingItems = this.dataList.data.filter( item => !item.vote || item.vote === 0 )
            
            this.yesCount = yesItems.length
            this.noCount = noItems.length
            this.pendingCount = pendingItems.length
            this.nullCount = nullItems.length

            this.yesPercentage = this.yesCount
                ? ((this.yesCount / this.dataList.data.length) * 100).toFixed(2)
                : 0
            this.noPercentage = this.noCount
                ? ((this.noCount / this.dataList.data.length) * 100).toFixed(2)
                : 0
            this.pendingPercentage = this.pendingCount
                ? ((this.pendingCount / this.dataList.data.length) * 100).toFixed(2)
                : 0
            this.nullPercentage = this.nullCount
                ? ((this.nullCount / this.dataList.data.length) * 100).toFixed(2)
                : 0
        },

        getColorByStatus(status) {
            switch (status) {
                case 0:
                    return 'warning'
                case 1:
                    return 'accent'
                case 2:
                    return 'error'
                default:
                    break
            }
        },

        getFormattedDate() {
            const currentDate = new Date();
            const day = String(currentDate.getDate()).padStart(2, '0'); 
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const year = currentDate.getFullYear(); 

            return `${day}-${month}-${year}`;
        },

        // async load() {
        //     const id =  this.dataset.generalMeetingId

        //     try {
        //         const result = await axios.get(`/api/admin/general-meetings/${id}`)

        //         const data = result.data.content

        //         this.dataset.data = data
        //     } catch (error) {
        //         this.$dialog.notify.error(this.$codes(error), {
        //             position: 'top-right',
        //             timeout: 2000
        //         })
        //     }
        // },

        async filterSearch() {
            let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : {}

            //search.general_meeting_id = this.dataset.generalMeetingId
            search.general_meeting_voting_id = this.dataset.generalMeetingVotingId
            search.presence = '1'

            if (!search.voting && this.dataset.filter.voting === 0) search.voting = 0

            this.dataList.loading = true

            var limit = this.dataList.options.itemsPerPage

            try {

                const result = await axios.get('/api/admin/general-meeting-votings/voting-list', {
                    params: { limit: limit, search: search }
                })

                const resultContent = result.data.content

                console.log("result: ", resultContent)

                this.dataList.data = resultContent.items

                this.dataset.generalMeetingName = result.data.content.general_meeting_name
                this.dataset.votingName = result.data.content.voting_name

                this.dataList.count = resultContent.length
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 })
            } finally {
                this.dataList.loading = false
            }
        },

        // async loadClients() {
        //     try {
        //         const result = await axios.get(`/api/clients`)

        //         const data = result.data.content

        //         this.dataset.clients = data
        //     } catch (error) {
        //         this.$dialog.notify.error(this.$codes(error), {
        //             position: 'top-right',
        //             timeout: 2000
        //         })
        //     }
        // },

        async loadResultGeneralMeeting() {
            try {
                this.assemblyResultData.loading = true;
                const result = await axios.get(`/api/admin/dashboard/result-general-meeting`, {
                    params: { general_meeting_voting_id: this.dataset.generalMeetingVotingId }
                })

                const data = result.data.content

                let resultData = []

                for (let item of data.result) {
                    const itemData = {
                        class: item.type,
                        creditors_number: { yes: item.total_status_1, no: item.total_status_2 },
                        head: {
                            yes: item.total_status_1_percentage.toFixed(0),
                            no: item.total_status_2_percentage.toFixed(0)
                        },
                        credit: {
                            yes: item.total_amount_status_1_percentage.toFixed(0),
                            no: item.total_amount_status_2_percentage.toFixed(0)
                        },
                        plan: item.approved
                    }

                    resultData.push(itemData)
                }

                resultData.push({
                    class: 'RESULTADO FINAL',
                    creditors_number: { yes: data.total.total_status_1, no: data.total.total_status_2 },
                    head: {
                        yes: data.total.total_status_1_percentage.toFixed(0),
                        no: data.total.total_status_2_percentage.toFixed(0)
                    },
                    credit: {
                        yes: data.total.total_amount_status_1_percentage.toFixed(0),
                        no: data.total.total_amount_status_2_percentage.toFixed(0)
                    },
                    plan: data.total.approved,
                    final: true
                })

                this.assemblyResultData.items = resultData

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.assemblyResultData.loading = false;
            }
        },

        buildPayload() {
            const data = {
                name: this.dataset.data.name,
                date: this.dataset.data.date,
                client: this.dataset.data.client_id,
                description: this.dataset.data.description
            }

            return data
        },

        async generateCSV() {
            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja exportar os dados para CSV?',
                title: 'Exportar dados',
                actions: {
                    false: 'Cancelar',
                    true: 'Exportar'
                }
            })

            if (!proceedAction) return

            const data = this.dataList.data

            if (data.length == 0) {
                this.$dialog.notify.error('Sem dados para gerar CSV!', {
                    position: 'top-right',
                    timeout: 2000
                })

                return
            }

            const newData = data.map(objeto => ({
                'NOME CREDOR': objeto.uc_name.toUpperCase(),
                'EMAIL CREDOR': objeto.uc_email,
                'NOME PROCURADOR': objeto.procurator_name || 'N/A',
                // 'SOLICITADO EM': objeto.created_at,
                VOTACAO: objeto.vote
                    ? objeto.vote === 1
                        ? 'SIM'
                        : objeto.vote === 2
                            ? 'NAO'
                            : objeto.vote === 3
                                ? 'NULO'
                                : 'PENDENTE'
                    : 'PENDENTE'
            }))

            try {
                const header = Object.keys(newData[0])
                const csvContent = [header.join(';')]

                newData.forEach(item => {
                    const row = header
                        .map(key => {
                            const value = item[key]
                            return typeof value === 'object' && value !== null ? value.join(';') : value
                        })
                        .join(';')
                    csvContent.push(row)
                })

                const csv = csvContent.join('\r\n')

                const formattedDate = this.getFormattedDate();

                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.setAttribute('href', url)
                link.setAttribute('download', this.dataset.votingName + '_' + formattedDate + '.csv');
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                this.$dialog.notify.info('Arquivo CSV gerado com sucesso!', {
                    position: 'top-right',
                    timeout: 2000
                })
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async generatePDF() {
            //if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Gerar PDF da votação ?',
                title: 'Gerar PDF',
                actions: {
                    false: 'Cancelar',
                    true: 'Gerar PDF'
                }
            })

            if (!proceedAction) return

            console.log('gerando pdf da votacao')

            console.log(this.dataset.data.id)

            this.dataset.loading = true

            try {
                const response = await axios.post(
                    `/api/admin/general-meetings/voting-pdf/${this.dataset.generalMeetingVotingId}`,
                    { responseType: 'blob' }
                )

                const blob = new Blob([response.data], { type: 'application/pdf' })
                const url = window.URL.createObjectURL(blob)

                const formattedDate = this.getFormattedDate();

                // Create a link element to trigger the download
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'laudo-votacao-' + this.dataset.votingName + '_' + formattedDate + '.pdf')

                // Append the link to the body and click it to start the download
                document.body.appendChild(link)
                link.click()

                // Clean up by revoking the URL object
                window.URL.revokeObjectURL(url)
            } catch (error) {
                console.error('Failed to generate PDF:', error)

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false
            }
        },

        async actionGeneralMeeting(item, status) {


            console.log("Item-send: ", item.uc_id, status)

            const index = this.dataList.data.findIndex(x => x.uc_id === item.uc_id)

            const voting = this.dataList.data[index].vote

            // !voting
            //     ? (this.dataList.data[index].voting = { status })
            //     : (this.dataList.data[index].voting.status = status)

            try {
                const response = await axios.post(`/api/admin/general-meetings/voting`, {
                    user_creditor_id: this.dataList.data[index].uc_id,
                    general_meeting_id: this.dataset.generalMeetingId,
                    general_meeting_voting_id: this.dataset.generalMeetingVotingId,
                    vote: status
                })

                this.$dialog.notify.info('Voto contabilizado com sucesso', {
                    position: 'top-right',
                    timeout: 2000
                })
            } catch (error) {
                voting ? (this.dataList.data[index].voting = voting) : null

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }

            await this.filterSearch();

            await this.updateSummary();
        },

        async openAssemblyResulModal() {
            // this.isLoadingModal = true;
            this.dialogAssemblyResult = true
            await this.loadResultGeneralMeeting()
            // await this.loadResultData();
            //  this.isLoadingModal = false;
        },

        // async loadResultData() {

        //     try {

        //         const result = await axios.get(`/api/admin/general-meetings/${id}`);

        //         this.dataList.assemblyResultData = result.data.content;

        //     } catch (error) {
        //         this.$dialog.notify.error(this.$codes(error), {
        //             position: 'top-right',
        //             timeout: 2000
        //         })
        //     }
        // },

        async generatePdfAssemblyResult() {
            this.isLoadingPdfModal = true

            const titleContent = this.$refs.pdfContent.previousElementSibling.innerHTML
            const modalContent = this.$refs.pdfContent.innerHTML

            const formattedDate = this.getFormattedDate();

            const fileName = 'resultado-' + this.dataset.votingName + '_' + formattedDate + '.pdf'

            const content = `<div style="font-weight: 700; font-size: 28px; margin-bottom: 16px; color: var(--v-primary-base);">${titleContent}</div><div>${modalContent}</div>`
            const element = document.createElement('div')
            element.innerHTML = content

            await html2pdf(element, {
                margin: [1, 1, 1, 1], // top, right, bottom, left
                filename: fileName,
                image: { type: 'jpeg', quality: 2 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'A4', orientation: 'landscape' }
            })

            this.isLoadingPdfModal = false
        },

        async generatePdfFinal() {

            const proceedAction = await this.$dialog.confirm({
                text: 'Gerar PDF da votação <strong>' + this.dataset.votingName + '</strong> ?',
                title: 'Gerar relatório final',
                actions: {
                    false: 'Cancelar',
                    true: 'Gerar PDF'
                }
            })

            if (!proceedAction) return;

            console.log("gerando pdf da assembleia")

            this.dataset.loading = true;


            try {
                const response = await axios.post(`/api/admin/general-meetings/generate-pdf/${this.dataset.generalMeetingVotingId}`, { responseType: 'blob' })

                const formattedDate = this.getFormattedDate();

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);

                // Create a link element to trigger the download
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'relatorio-final-' + this.dataset.votingName + '_' + formattedDate + '.pdf');

                // Append the link to the body and click it to start the download
                document.body.appendChild(link);
                link.click();

                // Clean up by revoking the URL object
                window.URL.revokeObjectURL(url);

            } catch (error) {
                console.error('Failed to generate PDF:', error)

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }


            // try {

            //     const result = await axios.delete(`/api/admin/general-meetings/${item.id}`)

            //     this.$dialog.notify.info("Assembléia excluída com sucesso", { position: "top-right", timeout: 2000 });              

            // } catch (error) {

            //     this.$dialog.notify.error(this.$codes(error), {
            //         position: 'top-right',
            //         timeout: 2000
            //     })

            // } finally {
            //     this.dataset.loading = false;
            // }

        },
    }
}
</script>

<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}

.list-card {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
}

.summary-item {
    margin-bottom: 8px;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
}

.summary-value {
    font-weight: bold;
}

.label-row {
    // font-weight: bold;
    margin-bottom: 2px;
    text-align: left;
}

.value-row {
    margin-bottom: 2px;
    font-weight: bold;
    font-size: 12px !important;
}

.dasboard-voting-table tbody tr td {
    border-right: 1px solid #ccc;
}

.modal-title {
    color: var(--v-primary-base) !important;
    // font-family: Montserrat;
    font-size: 28px !important;
    font-style: bold !important;
    font-weight: 700 !important;
}

.v-data-table-header th {
    font-size: 16px !important;
}

.green-chip {
    color: white !important;
    background-color: green !important;
}

.red-chip {
    color: white !important;
    background-color: red !important;
}

.header-label {
    background-color: #f0f0f0;
    padding: 10px;
}

.class-cell {
    background-color: #f0f0f0;
    padding: 8px;
}
</style>
