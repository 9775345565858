<template>
    <v-container class="py-8 px-6 full-height" fluid>
        <v-row class="full-height">
            <v-col sm="3">
                <v-card class="full-height" outlined>
                    <v-card-text>
                        <v-row>
                            <v-col cols="8">
                                <span class="assembly-title">Assembleias</span>
                            </v-col>
                            <v-col cols="4" class="text-right">
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-btn v-on="on" color="green" to="/agcs/novo" dark rounded>
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Criar nova assembleia</span>
                                </v-tooltip>
                            </v-col>

                        </v-row>

                        <br>

                        <v-row>
                            <v-col>
                                <v-text-field dense label="Pesquisar" v-model="dataset.search" outlined filled
                                    hide-details="auto" @keyup.enter="assemblySearch()">

                                    <template #append>
                                        <v-btn icon @click="assemblySearch()">
                                            <v-icon>mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-select dense :items="dataset.searchOptions" label="Opções de pesquisa"
                                    v-model="dataset.assemblySearch" item-value="id" item-text="name" outlined
                                    hide-details="auto">
                                </v-select>
                            </v-col>
                        </v-row>

                    </v-card-text>

                    <v-card-text>
                        <v-row>
                            <v-col>
                                <span><strong>Próximas</strong></span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col v-if="!dataNext.loading">
                                <v-list v-if="dataNext.data.length > 0">
                                    <v-list-item class="px-0" v-for="item in dataNext.data" :key="item.name" link>
                                        <v-avatar class="avatar-date-next">
                                            <span class="avatar-text" v-html="formatDate(item.date).__html"> </span>
                                        </v-avatar>
                                        <v-list-item-content @click="generalMeetingDetails(item.id, true)">
                                            <v-list-item-title class="text-title">{{ item.name }}</v-list-item-title>
                                            <v-list-item-subtitle class="text-subtitle">{{ item.client.name
                                            }}</v-list-item-subtitle>

                                            <v-list-item-subtitle class="text-subtitle">
                                                <v-chip x-small label outlined :color="avatarStatusColor(item.status)">
                                                    <strong> {{ avatarStatusDescription(item.status) }}</strong></v-chip>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-btn text color="primary" @click="seeMoreNext()" elevation="0" outlined>
                                        Ver Mais
                                        <v-icon right>mdi-arrow-down</v-icon>
                                    </v-btn>
                                </v-list>
                                <span v-else>Nenhuma assembleia encontrada</span>
                            </v-col>
                            <v-col v-else>
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-text>
                        <v-row>
                            <v-col>
                                <span><strong>Anteriores</strong></span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0 ">
                            <v-col v-if="!dataPrevious.loading">
                                <v-list v-if="dataPrevious.data.length > 0">
                                    <v-list-item class="px-0" v-for="item in dataPrevious.data" :key="item.name" link>
                                        <v-avatar class="avatar-date-previous">
                                            <span class="avatar-text" v-html="formatDate(item.date).__html"> </span>
                                        </v-avatar>
                                        <v-list-item-content @click="generalMeetingDetails(item.id, true)">
                                            <v-list-item-title class="text-title">{{ item.name }}</v-list-item-title>
                                            <v-list-item-subtitle class="text-subtitle">{{ item.client.name
                                            }}</v-list-item-subtitle>
                                            <v-list-item-subtitle class="text-subtitle">
                                                <v-chip x-small label outlined :color="avatarStatusColor(item.status)">
                                                    <strong> {{ avatarStatusDescription(item.status) }}</strong></v-chip>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                </v-list>
                                <span v-else>Nenhuma assembleia encontrada</span>
                            </v-col>
                            <v-col v-else>
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-btn text color="primary" @click="seeMorePrevious()" elevation="0" outlined>
                                    Ver Mais
                                    <v-icon right>mdi-arrow-down</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>

                </v-card>

            </v-col>
            <v-col sm="9">
                <v-row>
                    <v-col>
                        <v-row>
                            <v-col>
                                <v-card class="card-form-outlined">
                                    <v-card-text>
                                        <v-row justify="center" align="center">
                                            <v-col align="center">
                                                <span class="dashboard-card-title">{{ dataset.analytics.total_accreditations
                                                    || '---' }}</span>
                                            </v-col>
                                            <v-col align="center">
                                                <span class="dashboard-card-metric">Total<br>credenciados</span>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card class="card-form-outlined">
                                    <v-card-text>
                                        <v-row justify="center" align="center">
                                            <v-col align="center">
                                                <span class="dashboard-card-title" v-if="dataset.analytics.total_amount">R$
                                                    {{ dataset.analytics.total_amount | currency }}</span>
                                                <span class="dashboard-card-title" v-else>---</span>
                                            </v-col>
                                            <v-col align="center">
                                                <span class="dashboard-card-metric">Total<br>crédito</span>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card class="card-form-outlined">
                                    <v-card-text>
                                        <v-row justify="center" align="center">
                                            <v-col align="center">
                                                <span class="dashboard-card-title">{{
                                                    dataset.analytics.total_amount_percentage || 0 }}</span>
                                            </v-col>
                                            <v-col align="center">
                                                <span class="dashboard-card-metric">% baseada<br>no caso</span>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-row>

                        <v-row v-if="!dataset.data && !dataset.loading">
                            <v-col>
                                <v-card class="card-info-not-selected-general-meeting">
                                    <v-card-text class="text-center">
                                        <span>Nenhuma assembleia selecionada</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row v-if="dataset.loading">
                            <v-col>
                                <v-card class="card-info-not-selected-general-meeting">
                                    <v-card-text class="text-center">
                                        <v-progress-circular :size="70" :width="7" color="primary"
                                            indeterminate></v-progress-circular>
                                        <!-- <span>Carregando informações...</span> -->
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <template v-if="dataset.data && !dataset.loading">
                            <!-- <v-row v-if="dataset.data.lastEvent && dataset.data.lastEvent.type === 3"> -->
                            <v-row v-if="dataset.data.status === 3">
                                <v-col>
                                    <v-banner single-line outlined>
                                        <v-icon slot="icon" color="warning" size="36">
                                            mdi-progress-alert
                                        </v-icon>
                                        Assembleia suspensa
                                        <!-- <template v-slot:actions>
                                            <v-btn color="primary" text
                                                @click="actionGeneralMeeting(dataset.data.id, 1, 0)">
                                                ABRIR
                                            </v-btn>
                                        </template> -->
                                    </v-banner>
                                </v-col>
                            </v-row>

                            <!-- <v-row v-if="dataset.data.lastEvent && dataset.data.lastEvent.type === 7"> -->
                            <v-row v-if="dataset.data && dataset.data.status === 2">
                                <v-col>
                                    <v-banner single-line outlined>
                                        <v-icon slot="icon" color="error" size="36">
                                            mdi-progress-close
                                        </v-icon>
                                        Assembleia interrompida
                                    </v-banner>
                                </v-col>
                            </v-row>

                            <v-row v-if="dataset.data && dataset.data.status === 0">
                                <v-col>
                                    <v-banner single-line outlined>
                                        <v-icon slot="icon" color="primary" size="36">
                                            mdi-progress-helper
                                        </v-icon>
                                        Assembleia aberta
                                    </v-banner>
                                </v-col>
                            </v-row>

                            <v-row v-if="dataset.data && dataset.data.status === 5">
                                <v-col>
                                    <v-banner single-line outlined>
                                        <v-icon slot="icon" color="accent" size="36">
                                            mdi-progress-clock
                                        </v-icon>
                                        Assembleia em andamento
                                    </v-banner>
                                </v-col>
                            </v-row>

                            <v-row v-if="dataset.data && dataset.data.status === 1">
                                <v-col>
                                    <v-banner single-line outlined>
                                        <v-icon slot="icon" color="primary" size="36">
                                            mdi-alert-outline
                                        </v-icon>
                                        Assembleia fechada
                                        <template v-slot:actions>
                                            <v-tooltip bottom>
                                                <template #activator="{ on }">
                                                    <v-btn v-on="on" color="primary" text
                                                        @click="actionGeneralMeeting(dataset.data.id, 6, 0)">
                                                        ABRIR
                                                    </v-btn>
                                                </template>
                                            </v-tooltip>
                                        </template>
                                    </v-banner>
                                </v-col>
                            </v-row>

                            <v-row v-if="dataset.data && dataset.data.status === 4">
                                <v-col>
                                    <v-banner single-line outlined>
                                        <v-icon slot="icon" color="grey" size="36">
                                            mdi-progress-check
                                        </v-icon>
                                        Assembleia finalizada
                                    </v-banner>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-card class="table-card">
                                        <v-card-title class="d-flex justify-space-between align-center table-card-title ">
                                            Detalhes da Assembleia

                                                <v-spacer></v-spacer>

                                                <!-- <v-btn id="btnSave" type="button" elevation="0" color="primary" dark  to="/votacao" class="mr-5"
                                                :loading="dataForm.addLoading">Votação</v-btn> -->

                                                <!-- <v-btn id="btnCreateVoting" type="button" @click="goToNewVoting()" elevation="0" color="primary" dark   class="mr-5"
                                                :loading="dataForm.addLoading">Votação</v-btn> -->

                                                <div class="icon-container">
                                                    <template v-if="dataset.data.status === 3 || dataset.data.status === 1">
                                                        <v-tooltip bottom>
                                                            <template #activator="{ on }">
                                                                <v-icon icon v-on="on"
                                                                    @click="actionGeneralMeeting(dataset.data.id, 1, 0)"
                                                                    size="large" class="me-2 border ">
                                                                    mdi-reload
                                                                </v-icon>
                                                            </template>
                                                            <span>Abrir</span>
                                                        </v-tooltip>
                                                    </template>

                                                    <template v-if="dataset.data.status === 5">
                                                        <v-tooltip bottom>
                                                            <template #activator="{ on }">
                                                                <v-icon icon v-on="on"
                                                                    @click="actionGeneralMeeting(dataset.data.id, 9, 4)"
                                                                    size="large" class="me-2 border ">
                                                                    mdi-check-circle-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>Finalizar</span>
                                                        </v-tooltip>
                                                    </template>

                                                    <template v-if="dataset.data.status === 1 || dataset.data.status === 0">
                                                        <v-tooltip bottom>
                                                            <template #activator="{ on }">
                                                                <v-icon icon v-on="on"
                                                                    @click="actionGeneralMeeting(dataset.data.id, 8, 5)"
                                                                    size="large" class="me-2 border ">
                                                                    mdi-play-circle-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>Iniciar</span>
                                                        </v-tooltip>
                                                    </template>

                                                    <template v-if="dataset.data.status === 5 || dataset.data.status === 0 || dataset.data.status === 1">
                                                        <v-tooltip bottom>
                                                            <template #activator="{ on }">
                                                                <v-icon icon v-on="on"
                                                                    @click="actionGeneralMeeting(dataset.data.id, 3, 3)"
                                                                    size="large" class="me-2 border ">
                                                                    mdi-stop-circle-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>Suspender</span>
                                                        </v-tooltip>
                                                    </template>

                                                    <template v-if="!(dataset.data.status === 4 || dataset.data.status === 2)">
                                                        <v-tooltip bottom>
                                                            <template #activator="{ on }">
                                                                <v-icon icon v-on="on"
                                                                    @click="actionGeneralMeeting(dataset.data.id, 7, 2)"
                                                                    size="large" class="me-2 border ">
                                                                    mdi-close-circle-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>Interromper</span>
                                                        </v-tooltip>
                                                    </template>

                                                    <template v-if="dataset.data.status === 0 || dataset.data.status === 1">
                                                        <v-tooltip bottom>
                                                            <template #activator="{ on }">
                                                                <v-icon icon v-on="on"
                                                                    @click="openDialogInsertNotice(dataset.data)"
                                                                    size="large" class="me-2 border ">
                                                                    mdi-file-document-plus-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>Adicionar Edital</span>
                                                        </v-tooltip>
                                                    </template>

                                                    <template v-if="dataset.data.status === 0 || dataset.data.status === 1">
                                                        <v-tooltip bottom>
                                                            <template #activator="{ on }">
                                                                <v-icon icon v-on="on"
                                                                    @click="openAddInformationModal(dataset.data)"
                                                                    size="large" class="me-2 border ">
                                                                    mdi-file-document-edit-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>Adicionar Informações</span>
                                                        </v-tooltip>
                                                    </template>

                                                    <!-- <template v-if="dataset.data.status === 5">
                                                        <v-tooltip bottom>
                                                            <template #activator="{ on }">
                                                                <v-icon icon v-on="on" @click="votingItem(dataset.data)"
                                                                    size="large" class="me-2 border ">
                                                                    mdi-vote-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>Votação</span>
                                                        </v-tooltip>
                                                    </template> -->

                                                    <template v-if="dataset.data.status === 0 || dataset.data.status === 5">
                                                        <v-tooltip bottom>
                                                            <template #activator="{ on }">
                                                                <v-icon icon v-on="on" @click="presenceItem(dataset.data)"
                                                                    size="large" class="me-2">
                                                                    mdi-account-check-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>Presença</span>
                                                        </v-tooltip>
                                                    </template>

                                                    <template v-if="dataset.data.status === 0 || dataset.data.status === 1">
                                                        <v-tooltip bottom>
                                                            <template #activator="{ on }">
                                                                <v-icon icon v-on="on" @click="editItem(dataset.data)"
                                                                    size="large" class="me-2 border ">
                                                                    mdi-pencil-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>Editar</span>
                                                        </v-tooltip>
                                                    </template>

                                                    <template>
                                                        <v-tooltip bottom>
                                                            <template #activator="{ on }">
                                                                <v-icon icon v-on="on" @click="deleteItem(dataset.data)"
                                                                    size="large" class="me-2">
                                                                    mdi-delete-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>Excluir</span>
                                                        </v-tooltip>
                                                    </template>
                                                </div>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field dense label="Nome" v-model="dataset.data.name" outlined
                                                        hide-details="true" disabled readonly>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6">
                                                    <v-text-field dense label="Data" v-model="computedDateFormatted"
                                                        outlined hide-details="true" disabled readonly>
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="12">
                                                    <v-text-field dense label="Caso" v-model="dataset.data.client.name"
                                                        outlined hide-details="true" disabled readonly>
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="12">
                                                    <v-textarea rows="1" dense label="Descrição"
                                                        v-model="dataset.data.description" outlined hide-details="auto"
                                                        readonly disabled auto-grow>
                                                    </v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="dataset.data.votingExist" >
                                    <v-banner single-line outlined>
                                        <v-row class="align-center">
                                            <v-col class="d-flex align-center">
                                                <v-icon color="accent" size="36">
                                                    mdi-vote-outline
                                                </v-icon>
                                                <span class="ml-5">Esta assembléia já tem votação</span>
                                            </v-col>
                                            <v-col class="d-flex justify-end">
                                                <v-tooltip bottom>
                                                    <template #activator="{ on }"> 
                                                        <v-btn v-on="on" elevation="0" type="button" color="primary" @click="openVotingListModal(dataset.data)" >
                                                            Visualizar
                                                        </v-btn>
                                                    </template>
                                                    <span>Visualizar votações</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-banner>
                                </v-col>   
                                
                                <v-col v-else>
                                    <v-banner single-line outlined>
                                        <v-row class="align-center">
                                            <v-col class="d-flex align-center">
                                                <v-icon color="error" size="36">
                                                    mdi-vote-outline
                                                </v-icon>
                                                <span class="ml-5">Esta assembléia não tem votação</span>
                                            </v-col>
                                            <v-col class="d-flex justify-end">
                                                <v-tooltip bottom>
                                                    <template #activator="{ on }"> 
                                                        <v-btn v-on="on" id="btnNew" type="button" color="primary" 
                                                            elevation="0" @click="goToNewVoting()">
                                                            Incluir
                                                        </v-btn>
                                                    </template>
                                                    <span>Nova votação</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-banner>
                                </v-col>   
                            </v-row>

                            <v-row v-if="dataset.data.status == 0">
                                <v-col>
                                    <v-card class="card-form-outlined table-card">
                                        <v-card-title class="table-card-title">
                                            Prazo para credenciamento
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row align="center" class="mb-2" no-gutters>
                                                <v-col sm="6">
                                                    <v-progress-linear class="mb-2" :background-opacity="0.4"
                                                        :value="dataset.data.totalDiffPercentage" :height="5"
                                                        :color="resolveColorDeadlineAccreditation(dataset.data.totalDiffPercentage)"></v-progress-linear>

                                                    <span v-if="dataset.data.totalDiff > 0">
                                                        {{ dataset.data.totalDiff }} <strong>dia(s) restante(s)</strong>
                                                    </span>
                                                    <span v-else>
                                                        <strong>Encerrado</strong>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>

                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row v-if="dataset.data">
                                <v-col cols="12">
                                    <v-card class="table-card">
                                        <v-card-title class="table-card-title ">Credenciamentos (site)
                                            <v-spacer></v-spacer>
                                            <div class="icon-container">
                                                <v-tooltip bottom>
                                                    <template #activator="{ on }">
                                                        <v-icon icon v-on="on" @click="generateCSV()" size="large">
                                                            mdi-microsoft-excel
                                                        </v-icon>
                                                    </template>
                                                    <span>Gerar CSV credenciamentos</span>
                                                </v-tooltip>
                                            </div>

                                        </v-card-title>

                                        <v-card-text>
                                            <v-data-table :headers="dataAccreditations.headers"
                                                :items="dataAccreditations.data"
                                                :server-items-length="dataAccreditations.count"
                                                @update:items-per-page="pageSizeChanged" @update:page="pageChanged"
                                                :disable-sort="true" :loading="dataAccreditations.loading"
                                                no-data-text="Nenhum credenciamento foi encontrado"
                                                loading-text="Carregando dados..." class="app-table hover-table"
                                                :options.sync="dataAccreditations.options"
                                                :footer-props="dataAccreditations.footerOptions">

                                                <template v-slot:[`item.accreditation.user.name`]="{ item }">
                                                    <span>{{ item.accreditation.user.name.toUpperCase() }}</span>
                                                </template>

                                                <template v-slot:[`item.procurator_name`]="{ item }">
                                                    <span>{{ item.procurator_name ? item.procurator_name.toUpperCase() :
                                                        'N/A' }}</span>

                                                </template>

                                                <template v-slot:[`item.created_at`]="{ item }">
                                                    {{ item.created_at | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                                                </template>

                                                <template v-slot:[`item.status`]="{ item }">
                                                    <v-chip :color="getColorByStatus(item.status)" label text-color="white">
                                                        {{ item.status | enum(dataset.accreditationStatus, "id", "name") }}
                                                    </v-chip>
                                                </template>

                                                <template v-slot:[`item.actions`]="{ item }">
                                                    <v-tooltip bottom>
                                                        <template #activator="{ on }">
                                                            <v-icon icon v-on="on" :disabled="!$check('users/1', ['PATCH'])"
                                                                @click="viewItem(item)" size="large" class="me-2">
                                                                mdi-pencil-outline
                                                            </v-icon>
                                                        </template>
                                                        <span>Detalhes do credenciamento</span>
                                                    </v-tooltip>
                                                </template>

                                            </v-data-table>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                        </template>

                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <general-meeting-notice-file-upload ref="fileUploadDialogRef"></general-meeting-notice-file-upload>

        <accreditation-details-modal ref="accreditationDetailsModalRef"></accreditation-details-modal>

        <general-meeting-comments-modal ref="generalMeetingCommentsModalRef"></general-meeting-comments-modal>

        <general-meeting-voting-modal ref="generalMeetingVotingModalRef"></general-meeting-voting-modal>

    </v-container>
</template>
  
<script>


import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import object from "@/common/util/object"
import eventStatus from "@/common/data/event-status.data";

import AccreditationDetailsModal from "@/components/AccreditationDetailsModal.vue";
import GeneralMeetingCommentsModal from "@/components/GeneralMeetingCommentsModal.vue";
import GeneralMeetingVotingModal from "@/components/GeneralMeetingVotingModal.vue";
import GeneralMeetingNoticeFileUpload from '@/components/GeneralMeetingNoticeFileUpload.vue';

import accreditationStatus from "@/common/data/accreditation-status.data";

import { eventHub } from "@/main";

Vue.use(VueLodash)

export default {
    name: "GeneralMeetingList",

    components: {
        'accreditation-details-modal': AccreditationDetailsModal,
        'general-meeting-comments-modal': GeneralMeetingCommentsModal,
        'general-meeting-voting-modal': GeneralMeetingVotingModal,
        'general-meeting-notice-file-upload': GeneralMeetingNoticeFileUpload
    },

    data: () => ({
        //cards: ['Today', 'Yesterday'],
        eventStatus: [{ id: "", name: "Todos" }, ...eventStatus],
        drawer: null,
        dataset: {
            data: null,
            loading: false,
            assemblySearch: 1, 
            filter: {},
            search: '',
            searchOptions: [{ id: "", name: "Todas assembléias" }, { id: 1, name: "Próximas" }, { id: 2, name: "Anteriores" }],
            accreditation: null,
            generalComments: null,
            generalVoting: null,
            assemblyNoticeFile: null,
            accreditationSmallReason: null,
            accreditationReason: null,
            dynamicTexts: [],
            selectedAccreditation: null,
            selectedComments: null,
            selectedVoting: null,
            selectedNoticeFile: null,
            analytics: {
                total_accreditations: 0,
                total_amount: 0,
                total_amount_percentage: 0
            },
            assemblyCommentText: null,
            doc_url: null,
            accreditationStatus: accreditationStatus,
        },

        dataPrevious: {
            data: [],
            count: 0,
            loading: false,
            options: {
                page: 1,
                itemsPerPage: 5,
                sortDesc: [false],
            },
        },

        dataNext: {
            data: [],
            count: 0,
            loading: false,
            options: {
                page: 1,
                itemsPerPage: 5,
                sortDesc: [false],
            },
        },

        dataAccreditations: {
            data: [],
            loading: false,
            headers: [
                { text: "Nome", value: "accreditation.user.name" },
                { text: "Procurador", value: "procurator_name" },
                { text: "Criado em", value: "created_at" },
                { text: "Status", value: "status" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },

            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
                pageText: '{0}-{1} de {2}',
            },
        },

        lastEventsType1: {
            title: null
        },
        dialogInsertNotice: false,
        dialogAccreditation: false,
        dialogDynamicText: false,
        dialogGeneralMeetingComments: false,
        dialogGeneralMeetingVoting: false,
        dynamicTextSmallType: false,
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
        },

    }),

    computed: {
        computedDateFormatted() {

            return this.$moment(this.dataset.data.date).format("DD/MM/YYYY HH:mm")
        },

    },

    created() {
        this.loadPreviousGeneralMeetings();
        this.loadNextGeneralMeetings();

        eventHub.$on('DIALOG-ACCREDITATION-DETAILS', value => {
            this.dialogAccreditation = value;
        });

        eventHub.$on('DIALOG-GENERAL-METTING-COMMENTS', value => {
            this.dialogGeneralMeetingComments = value;
        });

        eventHub.$on('DIALOG-GENERAL-METTING-VOTING', value => {
            this.dialogGeneralMeetingVoting = value;
        });

        eventHub.$on('DIALOG-ASSEMBLY-NOTICE-FILE-UPLOAD', value => {
            this.dialogInsertNotice = value;
        });
    },

    methods: {

        loadData() {

            this.loadPreviousGeneralMeetings();
            this.loadNextGeneralMeetings();
        },

        getColorByStatus(status) {
            switch (status) {
                case 0:
                    return 'warning'
                case 1:
                    return 'accent'
                case 2:
                    return 'error'
                default:
                    break;
            }
        },

        pageChanged(page) {
            this.dataAccreditations.options.page = page;
            this.loadAccreditations();
        },

        pageSizeChanged(size) {
            this.dataAccreditations.options.itemsPerPage = size;
            this.loadAccreditations(true);
        },

        goToNewVoting(){
          
            let data = {
                id: this.dataset.data.id,
                agc_name: this.dataset.data.name,
                new: true
            };
      
            this.$router.push({
                name: "VotingDetails",
                params: { data }
            });
        },


        async loadMoreData() {
            if (this.dataList.loading) return;
            this.dataList.loading = true;

            try {
                const result = await this.fetchMoreDataFromApi(this.dataList.options.page, this.dataList.options.itemsPerPage);
                this.dataList.creditors = this.dataList.creditors.concat(result.data);
                this.dataList.count = result.totalCount;
                this.dataList.options.page++;
            } catch (error) {
                console.log(error)
            } finally {
                this.dataList.loading = false;
            }
        },

        onTableScroll(e) {
            const bottomOfTable = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if (bottomOfTable) {
                this.loadMoreData();
            }
        },

        async fetchMoreDataFromApi(page, limit) {

            var search = {}

            const result = await axios.get('/api/admin/accreditations', { params: { start: page, limit: limit, search: search } });

            this.dataList.creditors = result.data.content;
            this.dataList.count = result.data.count;

        },

        formatDate(created_at) {
            const months = [
                'jan', 'fev', 'mar', 'abr', 'mai', 'jun',
                'jul', 'ago', 'set', 'out', 'nov', 'dez'
            ];

            const date = new Date(created_at);
            const day = date.getDate();
            const monthIndex = date.getMonth();
            const month = months[monthIndex];

            return {
                __html: `${day}<br>${month.toUpperCase()}`
            };
        },

        async actionGeneralMeeting(id, type, status) {

            let continueStatus = true;

            let payload = {
                status,
                type,
            };

            if (type === 1) {
                const resultDate = await this.$dialog.prompt({
                    text: 'Informe a nova data da Assembleia',
                    title: 'Atenção',
                    textField: {
                        type: 'datetime-local'
                    },
                    actions: {
                        false: 'Cancelar',
                        true: 'Confirmar'
                    }
                })

                if (!resultDate) {
                    this.$dialog.notify.error("Selecione uma data para continuar!", {
                        position: 'top-right',
                        timeout: 2000
                    })

                    continueStatus = false;
                }

                payload.date = resultDate

            }

            if (!continueStatus) return

            let dialogMessage = "Deseja atualizar a Assembleia?"

            switch (type) {
                case 3:
                    dialogMessage = "Deseja suspender a Assembleia?"
                    break;

                case 7:
                    dialogMessage = "Deseja interromper a Assembleia?"
                    break;

                case 1:
                case 6:
                    dialogMessage = "Deseja abrir a Assembleia?"
                    break;

                case 8:
                    dialogMessage = "Deseja iniciar a Assembleia?"
                    break;

                case 9:
                    dialogMessage = "Deseja finalizar a Assembleia?"
                    break;

                default:
                    break;
            }

            const proceedAction = await this.$dialog.confirm({
                text: dialogMessage,
                title: 'Atenção!',
                actions: {
                    false: 'Cancelar',
                    true: 'Confirmar'
                }
            })

            if (!proceedAction) return;

            try {
                const response = await axios.patch(`/api/admin/general-meetings/action/${id}`, payload);

                this.$dialog.notify.info("Assembleia atualizada com sucesso", { position: "top-right", timeout: 2000 });

                this.loadData();
                this.generalMeetingDetails(id);

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async loadAnalytics(id) {

            try {
                const result = await axios.get(`/api/admin/general-meetings/analytics/${id}`);

                this.dataset.analytics = result.data.content

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                // this.dataPrevious.loading = false;
            }

        },

        seeMorePrevious() {
            this.dataPrevious.options.itemsPerPage += this.dataPrevious.options.itemsPerPage;
            this.loadPreviousGeneralMeetings();
        },

        async loadPreviousGeneralMeetings() {

            let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            this.dataPrevious.loading = true;

            var limit = this.dataPrevious.options.itemsPerPage;

            try {
                const result = await axios.get('/api/general-meetings/previous', { params: { limit: limit, search: search } });

                const resultContent = result.data.content

                this.dataPrevious.data = resultContent.data;

                this.dataPrevious.count = resultContent.paging.total;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataPrevious.loading = false;
            }

        },

        seeMoreNext() {
            this.dataNext.options.itemsPerPage += this.dataNext.options.itemsPerPage;
            this.loadNextGeneralMeetings();
        },

        async loadNextGeneralMeetings() {

            let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            var limit = this.dataNext.options.itemsPerPage;

            this.dataNext.loading = true;

            try {
                const result = await axios.get('/api/general-meetings/next', { params: { limit: limit, search: search } });

                this.dataNext.data = result.data.content.data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataNext.loading = false;
            }

        },

        async generalMeetingDetails(id, scroll = false) {

            if (scroll) window.scrollTo(0, 0);

            this.dataset.loading = true;

            try {
                const result = await axios.get(`/api/admin/general-meetings/${id}`);

                this.dataset.data = result.data.content;

                await this.loadAnalytics(this.dataset.data.id)
                await this.loadAccreditations();

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.loading = false;
            }
        },

        async loadAccreditations() {

            let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : {};

            search.general_meeting_id = this.dataset.data.id

            this.dataAccreditations.loading = true;

            var limit = this.dataAccreditations.options.itemsPerPage;

            try {
                const result = await axios.get('/api/admin/accreditations', { params: { page: this.dataAccreditations.options.page, limit: limit, search: search } });

                const resultContent = result.data.content

                this.dataAccreditations.data = resultContent.data;

                this.dataAccreditations.count = resultContent.paging.total;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataAccreditations.loading = false;
            }

        },

        async viewItem(item) {

            this.$refs.accreditationDetailsModalRef.setData(item);

            this.$refs.accreditationDetailsModalRef.open().then((results) => {

                this.generalMeetingDetails(item.general_meeting_id);
            });
        },

        async openAddInformationModal(item) {

            this.$refs.generalMeetingCommentsModalRef.setData(item);

            this.$refs.generalMeetingCommentsModalRef.open().then((results) => {
                this.generalMeetingDetails(item.id);
            });

        },

        async openVotingListModal(item) {

            this.$refs.generalMeetingVotingModalRef.setData(item);

            this.$refs.generalMeetingVotingModalRef.open().then((results) => {
                this.generalMeetingDetails(item.id);
            });

        },

        openDialogInsertNotice(item) {

            this.$refs.fileUploadDialogRef.setData(item);

            this.$refs.fileUploadDialogRef.open().then((results) => {
                this.generalMeetingDetails(item.id);
            });
        },

        async saveProcuratorField() {

            try {
                const response = await axios.patch(`/api/admin/accreditations/${this.$route.params.id}`, { procurator_name: this.dataset.data.procurator });

                this.$dialog.notify.info(`Nome do procurador alterado com sucesso!`, { position: "top-right", timeout: 2000 });

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },


        editItem(item) {
            this.$router.push(`agcs/${item.id}`);
        },

        votingItem(item) {
            this.$router.push(`agcs/votacao/${item.id}`);
        },

        presenceItem(item) {
            this.$router.push(`agcs/presenca/${item.id}`);
        },

        resolveColorDeadlineAccreditation(status) {

            if (status > 0 && status < 50)
                return '#FFCC80';

            if (status > 50 && status < 80)
                return '#F9AA33';

            if (status > 80 && status < 90)
                return '#FF5252';

            return '#FF5252';

        },

        async deleteItem(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Tem certeza que deseja excluir <strong>' + item.name + '</strong> ?<br><br>Esta ação é irreversível!',
                title: 'Excluir Assembléia',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataset.loading = true;

            try {

                const result = await axios.delete(`/api/admin/general-meetings/${item.id}`)

                this.$dialog.notify.info("Assembléia excluída com sucesso", { position: "top-right", timeout: 2000 });

                this.dataset.data = null;
                this.loadPreviousGeneralMeetings();
                this.loadNextGeneralMeetings();

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataset.loading = false;
            }

        },


        closeAddInformationModal() {

            this.addInformationModal = false;
        },


        avatarStatusColor(status) {

            switch (status) {
                case 0:
                    return 'primary'
                case 1:
                    return 'primary'
                case 2:
                    return 'error'
                case 3:
                    return 'warning'
                case 4:
                    return 'grey'
                case 5:
                    return 'accent'
                default:
                    break;
            }

        },

        avatarStatusDescription(status) {

            switch (status) {
                case 0:
                    return 'Aberta'
                case 1:
                    return 'Fechada'
                case 2:
                    return 'Interrompida'
                case 3:
                    return 'Suspensa'
                case 4:
                    return 'Finalizada'
                case 5:
                    return 'Em andamento'
                default:
                    break;
            }

        },

        async generatePdf(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Gerar PDF da assembleia <strong>' + item.name + '</strong> ?',
                title: 'Gerar relatório final',
                actions: {
                    false: 'Cancelar',
                    true: 'Gerar PDF'
                }
            })

            if (!proceedAction) return;

            console.log("gerando pdf da assembleia")

            this.dataset.loading = true;


            try {
                const response = await axios.post(`/api/admin/general-meetings/generate-pdf/${item.id}`, { responseType: 'blob' })


                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);

                // Create a link element to trigger the download
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file_new.pdf');

                // Append the link to the body and click it to start the download
                document.body.appendChild(link);
                link.click();

                // Clean up by revoking the URL object
                window.URL.revokeObjectURL(url);

            } catch (error) {
                console.error('Failed to generate PDF:', error)

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }




            // try {

            //     const result = await axios.delete(`/api/admin/general-meetings/${item.id}`)

            //     this.$dialog.notify.info("Assembléia excluída com sucesso", { position: "top-right", timeout: 2000 });              

            // } catch (error) {

            //     this.$dialog.notify.error(this.$codes(error), {
            //         position: 'top-right',
            //         timeout: 2000
            //     })

            // } finally {
            //     this.dataset.loading = false;
            // }

        },

        async generateCSV() {

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja exportar os dados para CSV?',
                title: 'Exportar dados credenciamentos',
                actions: {
                    false: 'Cancelar',
                    true: 'Exportar'
                }
            })

            if (!proceedAction) return;

            const data = this.dataAccreditations.data

            if (data.length == 0) {
                this.$dialog.notify.error("Sem dados para gerar CSV!", { position: "top-right", timeout: 2000 });

                return;
            }

            const newData = data.map(objeto => ({
                "NOME CREDOR": objeto.accreditation.user.name,
                "EMAIL CREDOR": objeto.accreditation.user.email,
                "NOME PROCURADOR": objeto.procurator_name || "N/A",
                "EMAIL PROCURADOR": objeto.procurator_email || "N/A",
                "SOLICITADO EM": objeto.created_at,
                "STATUS": objeto.status === 0 ? "Pendente" : (objeto.status === 1 ? "Aprovado" : "Rejeitado"),
            }));

            try {

                const header = Object.keys(newData[0]);
                const csvContent = [header.join(';')];

                newData.forEach(item => {
                    const row = header.map(key => {
                        const value = item[key];
                        return typeof value === 'object' && value !== null ? value.join(';') : value;
                    }).join(';');
                    csvContent.push(row);
                });

                const csv = csvContent.join('\r\n');

                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', this.dataAccreditations.data[0].accreditation.client.name + '.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                this.$dialog.notify.info("Arquivo CSV gerado com sucesso!", { position: "top-right", timeout: 2000 });

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        assemblySearch() {

            if (!this.dataset.search && this.dataset.assemblySearch !== '' ) {
                this.$dialog.notify.error("Informe dados para pesquisa!", { position: "top-right", timeout: 2000 });

                return;
            }

            const { assemblySearch } = this.dataset
            
            if (assemblySearch === 1) {

                this.dataset.filter = {name: this.dataset.search }
                this.dataNext.data = []
                this.dataPrevious.data = []
                this.loadNextGeneralMeetings();

            } else if (assemblySearch === 2) {
                
                this.dataset.filter = {name: this.dataset.search }
                this.dataPrevious.data = []
                this.dataNext.data = []
                this.loadPreviousGeneralMeetings();

            } else if (assemblySearch === '') {
                 
                this.dataset.filter = {name: this.dataset.search }
                this.dataPrevious.data = []
                this.dataNext.data = []
                this.loadNextGeneralMeetings();
                this.loadPreviousGeneralMeetings();

            } else if (assemblySearch === '' && !this.dataset.search ) {
                 
                this.loadNextGeneralMeetings();
                this.loadPreviousGeneralMeetings();
            } 

        },
    }
}
</script>



<style>
.assembly-title {
    font-size: 24px !important;
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.text-title {
    font-family: Montserrat;
    font-size: 12px !important;
    font-weight: bold;
}

.text-subtitle {
    font-size: 12px !important;
}

.text-right {
    text-align: right;
}

.avatar-date-next {
    background-color: var(--v-primary-base);
    margin-right: 10px;

}

.avatar-date-previous {
    background-color: gray;
    margin-right: 10px;
}

.avatar-text {
    font-size: 12px;
    font-weight: bold;
    color: white;
}

.dashboard-card-metric {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: 500;
}

.dashboard-card-title {
    color: var(--v-primary-base);
    font-size: 24px;
    font-weight: 700;
}

.list-scroll {
    max-height: 500px;
    overflow-y: auto;
}

.card-info-not-selected-general-meeting {
    padding-top: 20px;
    padding-bottom: 20px;
}

.icon-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
}
</style>