import axios from "axios";
//import LocalStorageService from "./services/storage/localstorageservice";
import router from "../router";
var parse = require("json-parse-safe");

// axios.defaults.baseURL = process.env.VUE_APP_API_URL || "http://localhost:3000";
// axios.defaults.baseURL = "http://localhost:3001";
// axios.defaults.baseURL = "http://localhost:3000";
// axios.defaults.baseURL = "https://rj123milhas-955db039f6df.herokuapp.com";
// axios.defaults.baseURL = "https://brizolaejapur-backend-api-0ba6563c28db.herokuapp.com/";
axios.defaults.baseURL = "https://seal-app-5zuf2.ondigitalocean.app";

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const data = parse(<any>localStorage.getItem("token"));

    console.log('token ', data)

    if (data.value) {
      config.headers["Authorization"] = "Bearer " + data.value.token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (error.request.status == 0) {
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      !originalRequest.url.includes("/sessions")
    ) {
      router.push("/login");
      return Promise.reject(error);
    }

    // if (error.response.status === 419 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   const token = parse(<any>localStorage.getItem("token"));

    //   return axios
    //     .get(
    //       `/sessions/${token.value.session}/access/new?access=${token.value.access}`
    //     )
    //     .then((res) => {
    //       if (res.status === 200) {
    //         localStorage.setItem("token", JSON.stringify(res.data.data));
    //         axios.defaults.headers.common["Authorization"] =
    //           "Bearer " + res.data.data.access;
    //       }
    //       return axios(originalRequest);
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //       router.push("/login");
    //       return Promise.reject(error);
    //     });
    // }

    //check error format
    if (error.response.data && "success" in error.response.data) {
      return Promise.reject(error.response.data);
    }

    return Promise.reject(error);
  }
);
