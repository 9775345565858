<template>
    <v-dialog v-model="dialog.show" persistent max-width="600px">
        <v-card >
            <v-card-text>
                <v-row>
              <v-col>
                <div class="font-weight-bold mt-6 title-page">Adicionar edital</div>
              </v-col>
            </v-row>    
                <br>
                <v-row no-gutters justify="center" align="center">
                    <v-col cols="12">
                        <v-file-input ref="fileInput" dense outlined show-size hide-details="auto" truncate-length="40" label="Arquivo" prepend-icon=""
                            @change="onFileSelected($event)">
                        </v-file-input>
                    </v-col>
                </v-row>
                <v-row >
                    <v-col cols="12">
                        <v-progress-linear v-model="dataset.progress" color="green" height="25"
                            reactive>
                            <strong>{{ dataset.progress }} %</strong>
                        </v-progress-linear>
                    </v-col>
                </v-row>    
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <div class="button">
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-btn v-on="on" @click="uploadFile" color="primary" dark elevation="2" :loading="dataset.loading">
                                Enviar
                            </v-btn>
                        </template>
                        <span>Enviar arquivo</span>
                    </v-tooltip>
                </div>
                <div>
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-btn v-on="on" color="grey" text @click.native="goBack">Cancelar</v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  

<script>
import 'moment/locale/pt-br';

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';
import string from "@/common/util/string"

Vue.use(VueLodash)

export default {

    name: "upload-files",

    data() {
        return {            
            dataset: {
                data: {
                    file: undefined,
                },
                progress: null,
                loading: false,
            },
            data: [],
            dialog: {
                show: false,
                resolve: null,
                reject: null,
            },
        }
    },

    methods: {

        open() {
            this.dialog.show = true;

            return new Promise((resolve, reject) => {
                this.dialog.resolve = resolve;
                this.dialog.reject = reject;
            });
        },

        close() {
            this.dialog.show = false;
            this.dialog.resolve()
        },

        setData(data) {
            console.log('set data ', data)
            this.dataset.data = data;
        },

        goBack() {
            this.dataset.data = {};
            this.$refs.fileInput.reset();
            this.close();
        },

        onFileSelected(file) {
            this.dataset.progress = 0;
            this.dataset.data.file = file;
        },

        
        async uploadFile() {

            if (!this.dataset.data.file) {
                this.$dialog.notify.error("Selecione um arquivo para ser enviado!", { position: "top-right", timeout: 5000 });
                return;
            }

            if (this.dataset.data.public_notice_doc) {

                const proceedAction = await this.$dialog.confirm({
                    title: 'ATENÇÃO!!!',
                    text: 'Já existe um arquivo de edital para esta assembléia!<br><br>Deseja substituir o arquivo atual?',
                    actions: {
                        false: 'Cancelar',
                        true: 'Substituir',
                        view: 'Visualizar'
                    }
                })

                if (!proceedAction) {
                    this.goBack();
                    return;
                }

                if (proceedAction === 'view') {

                    window.open(this.dataset.data.publicNoticeDocUrl, '_blank');

                    return;
                }

            }

            let formData = new FormData();
            formData.append("file", this.dataset.data.file);

            const id = this.dataset.data.id

            this.dataset.loading = true;
            this.dataset.progress = 0;

            try {
                const response = await axios.patch(`/api/admin/general-meetings/public-notice-doc/${id}`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    onUploadProgress: (event) => {
                        this.dataset.progress = Math.round((100 * event.loaded) / event.total);
                    }
                })

                this.$dialog.notify.info("Arquivo edital da assembleia enviado com sucesso!", { position: "top-right", timeout: 5000 });

                this.goBack()

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }

            this.dataset.progress = 0;
            this.dataset.data.file = undefined;

        },

    }

};
</script>


<style>
.button {
  margin-right: 10px;
}

.title-page {
    color: var(--v-primary-base);
    font-family: "Raleway";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

</style>