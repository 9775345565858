const data = [
  // { id: 1, name: "Aprovada" },
  // { id: 2, name: "Rejeitada" },
  // { id: 3, name: "Modificada" },
  // { id: 4, name: "Indefinida" },
  { id: 1, name: "Criada" },
  { id: 2, name: "Iniciada" },
  { id: 3, name: "Parada" },
  { id: 4, name: "Finalizada" },
];

  
export default [...data];
  