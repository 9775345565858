<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="add"
                            :loading="dataForm.addLoading" v-if="page.isNew">Salvar</v-btn>
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="update"
                            :loading="dataForm.updateLoading" v-if="!page.isNew">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined to="/modelo-documentos">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        
            <v-form ref="form" v-model="dataForm.validForm">
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-card class="card-form-outlined">
                                    <v-card-title class="card-group-title">Detalhes</v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field dense label="Nome" v-model="dataset.data.name" outlined
                                                    hide-details="auto">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row>
                                            <v-col cols="12">
                                                <v-file-input dense accept="image/png, image/jpeg, application/pdf, .csv, application/vnd.ms-excel, .docx"
                                                    prepend-icon="" outlined counter required show-size
                                                    :rules="fileRules"
                                                    hint="O arquivo não pode ser superior a 15MB" hide-details="auto"
                                                    label="Documento" :clearable="true" @change="onFileSelected($event)"
                                                    ref="logoFile" >
                                                </v-file-input>
                                            </v-col>
                                        </v-row>
                                       
                                        <template v-if="dataset.data.file_url">
                                            <v-row class="mt-n5" >
                                                <v-col class="doc-info">
                                                <span >Documento atual</span>  
                                                </v-col>
                                            </v-row> 
                                            <v-row class="mt-n3">
                                                <v-col>  
                                                    <iframe
                                                        :src="dataset.data.file_url"
                                                        width="250"
                                                        height="150"
                                                        frameborder="0"
                                                        scrolling="no" class="rounded-xl"
                                                    ></iframe>
    
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-btn type="button" elevation="0" color="primary" outlined :href="dataset.data.file_url"
                                                        target="_blank">Visualizar documento
                                                    </v-btn> 
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-form>
    </v-container>
</template>
  
<script>
import string from "@/common/util/string"

import axios from "axios"

export default {
    name: "TemplateDocDetails",

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            breadcrumbItems: [],
            data: {
                small_description: '',
                description: ''
            },
        },
        dataForm: {
            files: {},
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            created: null,
        },
    }),

    created() {
        this.setPage();
        this.listBreadCrumbItems();
        this.load();
    },

    computed: {
        fileRules() {
            const rules = [];
            if (!this.dataset.data.doc_url) {
                
                rules.push(this.$rules.required);
            }
            rules.push(this.$rules.fileSize(15));
            return rules;
        },
    },

    methods: {
        goBack() {
            this.$router.replace("/modelo-documentos");
        },

        openDoc(doc) {
            this.selectedDoc = doc;
            this.dialogViewDoc = true;
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = this.page.isNew ? "Novo documento" : "Detalhes do documento";
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Modelo de documentos",
                disabled: false,
                to: "/modelo-documentos",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.isNew ? "Incluir documento" : "Detalhes do documento",
                disabled: true,
                exact: true,
            });
        },

        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/api/admin/document-models/${this.$route.params.id}`);

                const data = result.data.content;

                this.dataset.data = data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async add() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja salvar o documento?',
                title: 'Incluir documento',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/api/admin/document-models', payload);

                this.$dialog.notify.info("Documento salvo com sucesso!", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },


        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar o documento?',
                title: 'Atualizar documento',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/api/admin/document-models/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Documento atualizado com sucesso!", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        onFileSelected(file) {
            this.dataset.data.file = file;
        },

        buildPayload() {
            const formData = new FormData()

            if (this.dataset.data.name)
                formData.append('name', this.dataset.data.name);

            if (this.dataset.data.file)
                formData.append('file', this.dataset.data.file)

            return formData;
        },

    },
};
</script>
  
<style lang="scss">
.card-group-title {
    text-transform: capitalize;
    font-weight: normal;
    font-size: 22px;
    color: #2c3153;
}

.theme-card {

    .card-section-title {
        color: var(--v-mediumGray-base) !important;
        text-transform: capitalize !important;
        // font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }

    background: var(--v-gray-base) !important;
}


input[type="color"] {
    height: 32px;
    width: 32px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    cursor: pointer;
}

</style>