const data = [
  { 
    id: 1, 
    name: "Geral", 
    permissions: ["*"] 
  },
  { 
    id: 2, 
    name: "Site", 
    permissions: [
      {
        path: "Site",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "Faq",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "GeneralMeeting",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "Blog",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "BlogCategory",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "Manager",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "Client",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "DynamicInfo",
        method: "GET,POST,PATCH,DELETE"
      },
    ] 
  },
  { 
    id: 3, 
    name: "RVC",
    permissions: [
      {
        path: "Manager",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "Document",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "Client",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "District",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "DynamicText",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "User",
        method: "GET,PATCH"
      },
    ] 
  },
  { 
    id: 4, 
    name: "RVC + Site",
    permissions: [
      {
        path: "Manager",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "Site",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "Document",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "Client",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "District",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "DynamicText",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "User",
        method: "GET,PATCH"
      },
      {
        path: "Faq",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "GeneralMeeting",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "Blog",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "BlogCategory",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "DynamicInfo",
        method: "GET,POST,PATCH,DELETE"
      },
    ] 
  },
  { 
    id: 5, 
    name: "Contabilidade",
    permissions: [
      {
        path: "Manager",
        method: "GET,POST,PATCH,DELETE"
      },
      {
        path: "Event",
        method: "GET,POST,PATCH,DELETE"
      },
    ] 
  },
];

export default [...data];
