var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('br'),_c('span',{staticClass:"page-title",attrs:{"id":"title"}},[_vm._v(" Associação Credor")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"filter-card"},[_c('v-card-title',{staticClass:"filter-card-title"},[_vm._v("Filtro")]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.load()}}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","id":"name","label":"Nome","hide-details":"","outlined":""},model:{value:(_vm.dataList.filter.name),callback:function ($$v) {_vm.$set(_vm.dataList.filter, "name", $$v)},expression:"dataList.filter.name"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"dense":"","id":"registration","label":"CPF","hide-details":"","outlined":""},model:{value:(_vm.dataList.filter.registration),callback:function ($$v) {_vm.$set(_vm.dataList.filter, "registration", $$v)},expression:"dataList.filter.registration"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"btnFilter","type":"submit","color":"primary","dark":"","elevation":"2"}},on),[_vm._v("Filtrar")])]}}])},[_c('span',[_vm._v("Filtrar dados selecionados")])])],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"table-card"},[_c('v-card-title',{staticClass:"table-card-title"},[_vm._v("Credores")]),_c('v-card-text',[_c('v-data-table',{staticClass:"app-table hover-table",attrs:{"headers":_vm.dataList.headers,"items":_vm.dataList.data,"server-items-length":_vm.dataList.count,"disable-sort":true,"loading":_vm.dataList.loading,"no-data-text":"Nenhum credor foi encontrado","loading-text":"Carregando dados...","options":_vm.dataList.options,"footer-props":_vm.dataList.footerOptions},on:{"update:items-per-page":_vm.pageSizeChanged,"update:page":_vm.pageChanged,"update:options":function($event){return _vm.$set(_vm.dataList, "options", $event)}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("defaultDate")(item.created_at,"DD/MM/YYYY HH:mm:ss"))+" ")]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(" "+_vm._s(item.client && item.client.name ? item.client.name : 'N/A'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"me-2",attrs:{"disabled":!_vm.$check('Document', 'PATCH'),"size":"large"},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" mdi-pencil-outline ")]),_c('v-icon',{attrs:{"disabled":!_vm.$check('Document', 'DELETE'),"size":"large"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete-outline ")])]}},{key:"item.checkbox",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item},on:{"change":function($event){return _vm.handleCheckboxChange(item)}},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})]}}],null,true)})],1),_c('v-divider'),_c('v-card-actions',[_c('div',{staticClass:"button"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","dark":"","elevation":"2","loading":_vm.dataset.loading},on:{"click":function($event){return _vm.associateSelectedCreditor()}}},on),[_vm._v(" Associar ")])]}}])},[_c('span',[_vm._v("Associar credor selecionado")])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"grey","text":""},nativeOn:{"click":function($event){return _vm.goBack()}}},on),[_vm._v("Cancelar")])]}}])},[_c('span',[_vm._v("Fechar")])])],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }