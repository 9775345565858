<template>
    <v-dialog v-model="dialog.show" persistent max-width="800px">

        <v-card>
      <v-card-text>
        <br>

        <v-row>
            <v-col class="card-group-title"> 
                <span> {{ page.title }} </span>

            </v-col>
        </v-row>
       
        <br>
    
        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                               
                                <v-card-text>
                                    <v-row>
                                        
                                        <v-col cols="12" sm="12">
                                            <v-text-field dense label="Caso" disabled v-model="dataset.data.name" outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        
                                    </v-row>
                                    
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="Nome" v-model="dataset.doc.name" outlined
                                                hide-details="auto"  :rules="rules">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select dense id="type" :items="dataset.clientDocTypes" label="Tipo"
                                                v-model="dataset.doc.type" item-value="id" item-text="name" outlined
                                                hide-details="auto" :rules="rules">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    
                                    <v-row>
                                        <v-col cols="12">
                                            <v-file-input dense accept="image/png, image/jpeg, application/pdf, .csv, application/vnd.ms-excel"
                                                prepend-icon="" outlined counter required show-size v-model="fileInputValue"
                                                :rules="rules"
                                                hint="O arquivo não pode ser superior a 15MB" hide-details="auto"
                                                label="Documento" :clearable="true" @change="onFileSelected($event)"
                                                ref="docFile" >
                                            </v-file-input>
                                        </v-col>
                                    </v-row>
                                  
                                    <template v-if="dataset.doc.doc_url">
                                        <!-- <v-row class="mt-n5" >
                                            <v-col class="doc-info">
                                            <span >Documento atual</span>  
                                            </v-col>
                                        </v-row>  -->
                                        <!-- <v-row class="mt-n3">
                                            <v-col>  
                                                <iframe
                                                    :src="dataset.data.doc_url"
                                                    width="250"
                                                    height="150"
                                                    frameborder="0"
                                                    scrolling="no" class="rounded-xl"
                                                ></iframe>

                                            </v-col>
                                        </v-row> -->
                                        <v-row class="mt-n5">
                                            <v-col cols="12">
                                                <v-btn type="button" elevation="0" color="primary" outlined :href="dataset.doc.doc_url"
                                                    target="_blank">Visualizar documento
                                                </v-btn> 
                                            </v-col>
                                        </v-row>
                                    </template>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

    </v-card-text>

        <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="saveDocData()" color="primary" :loading="dataset.loading" dark>Salvar</v-btn>
        <v-btn color="grey" text @click.native="goBack()">Cancelar</v-btn>
      </v-card-actions>
    </v-card>

    </v-dialog>
</template>


<script>

import clientDocTypes from "@/common/data/client-doc-types.data"
import string from "@/common/util/string"

import axios from "axios"

export default {
    name: "ClientDocDetailsModal",

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        rules: [],
        fileInputValue: null,
        dataset: {
            clientDocTypes: clientDocTypes,
            clients: [],
            loading: false,
            data: {
                category_id: null
            },
            doc: {
               name: null,
               type: null,
               file: null,
            }
        },
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            showPassword: false,
            showPasswordConfirmation: false,
        },
        dialog: {
            show: false,
            resolve: null,
            reject: null,
        },
    }),

    created() {
        this.loadData()
    },

    methods: {
       
        open() {
            this.dialog.show = true;
           
            if (this.dataset.doc && this.dataset.doc.name !== null && this.dataset.doc.name !== undefined && this.dataset.doc.name !== '') {
                
                this.page.isNew = true;
                this.page.title = 'Editar documento'
                this.dataset.doc.file = null;
                this.fileInputValue = null;
                this.rules = [];
    
            }else{

                this.page.isNew = false;
                this.page.title = 'Adicionar documento'
                this.dataset.doc.file = null;
                this.fileInputValue = null;
                this.rules = [this.$rules.required];
            }      
                        
            return new Promise((resolve, reject) => {
                this.dialog.resolve = resolve;
                this.dialog.reject = reject;
            });
            
        },

        close() {
            this.dialog.resolve();
            this.dialog.show = false;
        },

        goBack() {
            this.dataset.doc = {};
            this.close();
        },

        setData(data, item) {
            this.dataset.data = data;

            if (item) {
                this.dataset.doc = item;
            }
        },


        openDoc(doc) {
            this.selectedDoc = doc;
            this.dialogViewDoc = true;
        },

        async loadData() {
        
            await this.loadClients()
            await this.load();

        },

        
        onFileSelected(file) {
            this.dataset.doc.file = file;
        },


        async load() {

            try {
                const result = await axios.get(`/api/admin/client-docs/${this.$route.params.id}`);
         
                const data = result.data.content.data;

                this.dataset.data = data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        saveDocData(){

            this.page.isNew ? this.update() : this.add();

        },

        async add() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o documento?',
                title: 'Incluir documento',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;
            
            const payload = this.buildPayload();
            
            try {
                const response = await axios.post('/api/admin/client-docs', payload);

                this.$dialog.notify.info("Documento salvo com sucesso!", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar o documento?',
                title: 'Atualizar documento',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/api/admin/client-docs/${this.dataset.doc.id}`, payload);

                this.$dialog.notify.info("Documento salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        
        buildPayload() {
            const formData = new FormData()

            if (this.dataset.doc.name)
                formData.append('name', this.dataset.doc.name);

            if (this.dataset.doc.type)
                formData.append('type', this.dataset.doc.type);

            if (this.dataset.doc.id)
                formData.append('id', this.dataset.doc.id);

            if (this.dataset.data.id)
                formData.append('client_id', this.dataset.data.id);

            if (this.dataset.doc.file)
                formData.append('file', this.dataset.doc.file)

            return formData;
        },

    },
};
</script>

<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.doc-info{
    color: var(--v-primary-base);
    font-size: 16px;
    font-style: normal;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}
</style>