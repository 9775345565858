import Vue, { PluginObject } from "vue"; 
import * as jwt from "jwt-decode";

import adminProfiles from "@/common/data/admin-profiles.data";


class PermissionsPlugin implements PluginObject<any>{

    install = (_Vue: typeof Vue, options?: any) => {
        
        _Vue.prototype.$check = function(path: string, methods: string){
            
            var user = this.$root.user;

            if(!user) return false;

            const profile = adminProfiles.find((x) => x.id === (user.data ? user.data.profile : user.profile));

            if(profile.permissions && profile.permissions[0] === '*') return true;

            const checkPermission = profile.permissions.find((x) => x.path === path);

            if(!checkPermission) return false;

            const checkMethod = checkPermission.method.includes(methods)

            return checkMethod;

            // return data.permissions.some(perm => {
            //     let regex = new RegExp(perm.path);
            //     return regex.test(path) && methods.some(method => perm.methods.includes(method.toLowerCase()))
            // });
        }  
    };
    [key: string]: any;    
}

const plugin = new PermissionsPlugin;
Vue.use(plugin);
