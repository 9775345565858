const data = [
  { 
    id: 1,
    name: "Classe I - Trabalhista",
  },
  { 
    id: 2,
    name: "Classe II - Garantia Real",
  },
  { 
    id: 3,
    name: "Classe III - Quirografários",
  },
  { 
    id: 4,
    name: "Classe IV - ME/EPP",
  },
  { 
    id: 5,
    name: "Extraconcursais",
  },
];

export default [...data];
