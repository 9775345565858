<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="add"
                            :loading="dataForm.addLoading" v-if="page.isNew">Salvar</v-btn>
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="update"
                            :loading="dataForm.updateLoading" v-if="!page.isNew">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined
                            to="/incidentes">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="Número" v-model="dataset.data.code" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select dense :items="dataset.incidentTypesList" label="Tipo" required
                                                v-model="dataset.data.type" item-value="id" item-text="name" outlined
                                                :rules="[$rules.required]" hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="Nome Credor" v-model="dataset.data.creditor_name" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <!-- <v-text-field dense id="creditor_registration" label="CPF/CNPJ"
                                                v-model="dataset.data.creditor_registration" required
                                                outlined hide-details="auto">
                                            </v-text-field> -->

                                            <v-text-field dense id="creditor_registration" label="CPF/CNPJ"
                                                v-model="dataset.data.creditor_registration" required
                                                v-mask="dataset.data.creditor_registration.length < 15 ? this.$masks.cpf : this.$masks.cnpj"
                                                :rules="[$rules.required]"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                            
                                        </v-col>
                                    </v-row>
                                    
                                    <v-row>
                                        <v-col cols="12">   
                                            <v-autocomplete dense :items="dataset.clients" label="Caso" v-model="dataset.data.client_id" 
                                            item-value="id" item-text="name" outlined hide-details="auto"
                                            required :rules="[$rules.required]"  no-data-text="Nenhum caso encontrado">
                                            </v-autocomplete>
                                        </v-col>

                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="3">
                                            <v-currency-field label="Reserva de valor" dense
                                                v-model="dataset.data.amount_reservation" :allowNegative=false
                                                hide-details="auto" outlined>
                                            </v-currency-field>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-currency-field label="Valor do crédito" dense
                                                v-model="dataset.data.approved_amount" :allowNegative=false
                                                hide-details="auto" outlined >
                                            </v-currency-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select dense :items="dataset.incidentResultStatus" label="Resultado" 
                                                required :rules="[$rules.required]" v-model="dataset.data.status" 
                                                item-value="id" item-text="name" outlined hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    

                                    <!-- <v-row>
                                        <v-col cols="12">
                                            <p class="font-weight-bold">Descrição</p>
                                            <tiptap-vuetify v-model="dataset.data.description" :rules="[$rules.required]"
                                                :extensions="extensions" hide-details="auto" />
                                        </v-col>
                                    </v-row> -->

                                    
                                    <!-- <v-row>

                                        <v-btn @click="openCreditorSelectionModal(dataset.data)" color="primary" dark>Associação</v-btn>

                                    </v-row> -->

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!-- <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Credor</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col >
                                            <v-list-item dense v-if="dataset.modalResult">
                                                <v-list-item-avatar>
                                                    <v-icon size="32">mdi-account-outline</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title> {{ dataset.modalResult.name }}</v-list-item-title>
                                                    <v-list-item-subtitle> {{ dataset.modalResult.client.name }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item dense v-else>
                                                <v-list-item-avatar>
                                                    <v-icon size="32">mdi-account-outline</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>Nenhum credor associado ao incidente</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>

                                    
                                    <v-row>
                                    <v-col>
                                        <v-tooltip bottom>
                                            <template #activator="{ on }">
                                                <v-btn v-on="on" @click="openCreditorSelectionModal(dataset.data)" color="primary" dark elevation="2" small>
                                                    Associar
                                                </v-btn>
                                            </template>
                                            <span>Associar credor</span>
                                        </v-tooltip>

                                    </v-col>
                                </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col> -->
                        
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

        <incident-creditor-selection-modal ref="IncidentCreditorSelectionModalRef"></incident-creditor-selection-modal>

    </v-container>
</template>

<script>

import string from "@/common/util/string"

import axios from "axios"

import { eventHub } from "@/main";

import IncidentCreditorSelectionModal from "@/components/IncidentCreditorSelectionModal.vue"

import incidentTypesList from "@/common/data/incident-types.data"
import incidentResultStatus from "@/common/data/incident-result-status.data"

// import {
//     TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph
// } from 'tiptap-vuetify'


export default {
    name: "IncidentDetails",

    components: { 
       // 'tiptap-vuetify': TiptapVuetify,
        'incident-creditor-selection-modal': IncidentCreditorSelectionModal,
    
    },
  

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            breadcrumbItems: [],
            data: {  
                creditor_registration: ""
            },
            incidentTypesList: incidentTypesList,
            incidentResultStatus: incidentResultStatus,
            modalResult: null,
            clients: [],
        },
        dialogIncidentCreditorSelection: false,
       // dynamicTextSmallType: false,
       // selectedDoc: null,
       // dialogViewDoc: false,
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            showPassword: false,
            showPasswordConfirmation: false,
        },
        // extensions: [
        //     Underline,
        //     Strike,
        //     Italic,
        //     Bold,
        //     Paragraph,
        // ],
    }),

    created() {
        this.loadData()

        eventHub.$on('DIALOG-INCIDENT-CREDITOR-SELECTION', value => {
            this.dialogIncidentCreditorSelection = value;
        });

    },
   

    methods: {
        goBack() {
            this.$router.replace("/incidentes");
        },

        async loadData() {
            this.setPage();
            this.listBreadCrumbItems();
            await this.load();
            await this.loadClients();
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = `${this.page.isNew ? 'Novo incidente' : 'Detalhes do incidente'}`;
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Incidentes",
                disabled: false,
                to: "/incidentes",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.title,
                disabled: true,
                exact: true,
            });
        },

        async openCreditorSelectionModal(item) {
    
            this.$refs.IncidentCreditorSelectionModalRef.setData(item);

            this.$refs.IncidentCreditorSelectionModalRef.open().then((results) => {
                this.dataset.modalResult = results
              console.log("dados results: ", results)
            });

        },

        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/api/admin/judicial-incidents/${this.$route.params.id}`);
                console.log('result ', result)

                const data = result.data.content;

                this.dataset.data = data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async loadClients() {

            try {
                const result = await axios.get(`/api/clients`, { params: { start: 1, limit: 100000, search: {} } });

                const data = result.data.content.data
                
                data.sort((a, b) => a.name.localeCompare(b.name));
                
                this.dataset.clients = data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },


        async add() {
            if (!this.$refs.form.validate()) return;

            // if (!this.dataset.data.creditor_name){
            //     this.$dialog.notify.error("Nenhum credor está associado ao incidente! ", { position: "top-right", timeout: 2000 });

            //     return;
            // }

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o incidente?',
                title: 'Incluir incidente',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/api/admin/judicial-incidents', payload);

                this.$dialog.notify.info("Incidente salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar dados do incidente?',
                title: 'Atualizar incidente',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/api/admin/judicial-incidents/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Incidente atualizado com sucesso!", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        // buildPayload() {
        //     const data = {
        //         code: this.dataset.data.code,
        //         type: this.dataset.data.type,
        //         amount_reservation: this.dataset.data.amount_reservation,
        //         status: this.dataset.data.status,
        //         approved_amount: this.dataset.data.approved_amount,
        //         client_id: this.dataset.data.client_id,
        //         creditor_name: this.dataset.data.creditor_name,
        //         creditor_registration: this.dataset.data.creditor_registration   
        //     }

        //     return data;
        // },
       
        buildPayload() {
            const formData = new FormData()

            if (this.dataset.data.code)
                formData.append('code', this.dataset.data.code);

            if (this.dataset.data.type)
                formData.append('type', this.dataset.data.type);

            if (this.dataset.data.amount_reservation)
                formData.append('amount_reservation', this.dataset.data.amount_reservation)

            if (this.dataset.data.status)
                formData.append('status', this.dataset.data.status)

            if (this.dataset.data.approved_amount)
                formData.append('approved_amount', this.dataset.data.approved_amount)

            if (this.dataset.data.client_id)
                formData.append('client_id', this.dataset.data.client_id)

            if (this.dataset.data.creditor_name)
                formData.append('creditor_name', this.dataset.data.creditor_name)

            // if (this.dataset.data.creditor_registration)
            //     formData.append('creditor_registration', this.dataset.data.creditor_registration)
            if (this.dataset.data.creditor_registration) {
                formData.append('creditor_registration', this.dataset.data.creditor_registration.replace(/\D/g, ''));
            }

            return formData;
        },

    },
};
</script>

<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}
</style>