var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('v-card',[_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"primary"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"sub-title-page"},[_vm._v("Lista de votações")])])],1)],1),(_vm.dataset.data.name)?_c('v-col',[_c('v-card-title',{staticClass:"table-card-title d-flex justify-space-between"},[_vm._v(" Assembléia - "+_vm._s(_vm.dataset.data.name)+" ")])],1):_vm._e(),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"app-table hover-table",attrs:{"headers":_vm.dataList.headers,"items":_vm.dataList.data,"disable-sort":true,"loading":_vm.dataList.loading,"no-data-text":"Nenhuma votação encontrada","loading-text":"Carregando dados...","disable-pagination":true,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorByStatus(item.status),"label":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.getStatusName(item.status))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","text-color":"primary"}},[_vm._v(" "+_vm._s(_vm.getTypeName(item.type))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("defaultDate")(item.created_at,"DD/MM/YYYY HH:mm"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"me-2",attrs:{"disabled":!_vm.$check('User', 'PATCH'),"size":"large"},on:{"click":function($event){return _vm.editVoting(item)}}},on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Editar votação")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"me-2",attrs:{"disabled":!_vm.$check('User', 'PATCH'),"size":"large"},on:{"click":function($event){return _vm.deleteVoting(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Excluir votação")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"me-2",attrs:{"disabled":!_vm.$check('User', 'PATCH'),"size":"large","color":"accent"},on:{"click":function($event){return _vm.setStatus(item, 2)}}},on),[_vm._v(" mdi-play-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v("Iniciar votação")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"me-2",attrs:{"disabled":!_vm.$check('User', 'PATCH'),"size":"large","color":"warning"},on:{"click":function($event){return _vm.setStatus(item, 3)}}},on),[_vm._v(" mdi-pause-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v("Pausar votação")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"me-2",attrs:{"disabled":!_vm.$check('User', 'PATCH'),"size":"large","color":"red"},on:{"click":function($event){return _vm.setStatus(item, 4)}}},on),[_vm._v(" mdi-stop-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v("Finalizar votação")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"me-2 border ",attrs:{"icon":"","disabled":!_vm.$check('User', 'PATCH'),"size":"large","color":"primary"},on:{"click":function($event){return _vm.votingItem(item)}}},on),[_vm._v(" mdi-vote-outline ")])]}}],null,true)},[_c('span',[_vm._v("Votação")])])]}}],null,true)})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"grey","text":"","outlined":""},nativeOn:{"click":function($event){_vm.dialog.show=false}}},on),[_vm._v(" Voltar ")])]}}])},[_c('span',[_vm._v("Voltar para assembléia")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-5",attrs:{"color":"primary","elevation":"0","dark":""},on:{"click":function($event){return _vm.addVoting(_vm.dataset.data.id)}}},on),[_vm._v(" Incluir ")])]}}])},[_c('span',[_vm._v("Incluir votação")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }