<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Habilitações/Divergências</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Habilitações/Divergências',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>

                    <v-row justify="end" class="pa-2">
                        <v-col cols="auto">
                          <v-btn id="btnNew" type="button" color="primary" elevation="0" to="/analise-massa">Analise em Massa</v-btn>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn id="btnNew" type="button" color="primary" elevation="0" to="/event-create">Incluir</v-btn>
                        </v-col>
                    </v-row>

                    <!-- <div class="button-group">
                        <v-btn id="btnNew" type="button" color="primary" elevation="0"
                            to="/analise-massa">Analise em Massa</v-btn>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnNew" type="button" color="primary" elevation="0"
                            to="/analise-massa">Incluir</v-btn>
                    </div> -->
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="load()">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field dense id="name" label="Nome" v-model="dataList.filter.name" hide-details
                                        outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6">   
                                    <v-autocomplete dense :items="dataset.clients" label="Caso" v-model="dataList.filter.client_id" 
                                    item-value="id" item-text="name" outlined hide-details="auto"
                                    no-data-text="Nenhum caso encontrado">
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>

                                <v-col cols="12" sm="6">
                                    <v-select dense :items="dataset.eventStatus" label="Status" v-model="dataList.filter.status"
                                        item-value="id" item-text="name" outlined hide-details="auto">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-select dense :items="dataset.eventTypes" label="Tipo" v-model="dataList.filter.type"
                                        item-value="id" item-text="name" outlined hide-details="auto">
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn id="btnFilter" type="submit" color="primary" dark elevation="2"
                                        >Filtrar</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="table-card">
                    <v-card-title class="table-card-title">Hab/Div
<!-- 
                         <v-spacer></v-spacer>
                        <div class="icon-container">
                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-icon icon v-on="on" @click="load(true)" size="large">
                                        mdi-file-upload-outline
                                    </v-icon>
                                </template>
                                <span>Análises em massa</span>
                            </v-tooltip>
                        </div> -->

                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhum usuário foi encontrado" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">
                            <template v-slot:[`item.user.name`]="{ item }">
                                <span class="list-item-title text-capitalize">{{ item.user.name }}</span><br />
                            </template>

                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip :color="getColorByStatus(item.status)" label text-color="white">
                                    {{ item.status | enum(dataset.eventStatus, "id", "name") }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                            </template>

                            <template v-slot:[`item.type`]="{ item }">
                                <v-chip :color="item.type == 2 ? '#00BCD4' : '#009688'" label text-color="white">
                                    {{ item.type == 2 ? 'Divergência' : 'Habilitação' }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.client.name`]="{ item }">
                                {{ truncateText(item.client.name, 30) }}
                            </template>

                            <template v-slot:[`item.admin`]="{ item }">
                                {{ item.admin && item.admin.name ? item.admin.name : 'N/A' }}
                            </template>

                            <template v-slot:[`item.analyzed_at`]="{ item }">
                                <span v-if="item.analyzed_at && item.analyzed_at !== '0000-00-00 00:00:00'"> {{
                                    item.analyzed_at |
                                    defaultDate("DD/MM/YYYY HH:mm:ss") }}</span>
                                <span v-else>N/A</span>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon :disabled="!$check('users/1', ['PATCH'])" @click="viewItem(item)" size="large"
                                    class="me-2">
                                    mdi-pencil-outline
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import object from "@/common/util/object"
import eventStatus from "@/common/data/event-status.data";

Vue.use(VueLodash)

export default {
    name: "UserList",

    data: () => ({
        dataset: {
            eventStatus: [{ id: "", name: "Todos" }, ...eventStatus],
            eventTypes: [{ id: "", name: "Todos" }, { id: 1, name: "Habilitação" }, { id: 2, name: "Divergência" }],
            data: {},
            clients: [],
        },
        dataList: {
            filter: {
                name: "",
                status: "",
                type: "",
                client_id:""
            },
            headers: [
                { text: "ID", value: "id" },
                { text: "Credor", value: "user.name" },
                { text: "Caso", value: "client.name" },
                { text: "Tipo", value: "type" },
                { text: "Solicitado em", value: "created_at" },
                { text: "Analisado por", value: "admin" },
                { text: "Analisado em", value: "analyzed_at" },
                { text: "Status", value: "status" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
                pageText: '{0}-{1} de {2}',
            }
        },
    }),

    created() {
        this.load();
        this.loadClients();
    },

    methods: {
       
        pageChanged(page) {            
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {    
            this.dataList.options.itemsPerPage = size;
            this.load(true);
        },

        getColorByStatus(status) {
            switch (status) {
                case 0:
                    return 'warning'
                case 1:
                    return 'accent'
                case 2:
                    return 'error'
                case 3:
                return '#33691E'//return 'indigo darken-1'
                default:
                    break;
            }
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },

        // includeList() {
        //     this.$router.push('/divergencias/analise-massa');
        // },

        async load() {

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : {};

            if(this.dataList.filter.status === 0) search.status = 0
            
            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;
            
            try {
                const result = await axios.get('/api/admin/user-events/list', { params: { page: this.dataList.options.page, limit: limit, search: search } });

                this.dataList.data = result.data.content.data;
                this.dataList.count = result.data.content.paging.total;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async loadClients() {

            try {
                const result = await axios.get(`/api/clients`, { params: { start: 1, limit: 100000, search: {} } });

                const data = result.data.content.data
                
                data.sort((a, b) => a.name.localeCompare(b.name));
                
                this.dataset.clients = [{ id: "", name: "Todos " }, ...data];

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.slice(0, maxLength) + '...';
            }
            return text;
        },


        viewItem(item) {
            this.$router.push(`divergencias/${item.id}`);
        },
    },
};
</script>