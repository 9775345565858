<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Credores</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Credores',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="load()">
                            <v-row>
                                <v-col cols="12" sm="3">
                                    <v-text-field dense label="Nome" v-model="dataList.filter.name" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field dense label="E-mail" v-model="dataList.filter.email" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-text-field dense label="CPF" v-model="dataList.filter.registration" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-select no-data-text="Nenhuma informação disponível" dense :items="dataset.status" label="Status" v-model="dataList.filter.status"
                                        item-value="id" item-text="name" outlined hide-details="auto">
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn id="btnFilter" type="submit" color="primary" dark elevation="2">Filtrar</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="table-card">
                    <v-card-title class="table-card-title">Credores</v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhum usuário foi encontrado" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">
                            <template v-slot:[`item.name`]="{ item }">
                                <v-avatar :size="30">
                                    <img :src="'https://ui-avatars.com/api/?name=' + item.name" />
                                </v-avatar>
                                <span class="ml-4 list-item-title">{{ item.name }}</span><br />
                            </template>

                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip :color="item.status == 0 ? 'accent' : 'error'" label text-color="white">
                                    {{ item.status | enum(dataset.status, "id", "name") }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.account_verified_at`]="{ item }">
                                <v-chip :color="item.account_verified_at !== '0000-00-00 00:00:00' ? 'accent' : 'warning'" label text-color="white">
                                    {{ item.account_verified_at !== '0000-00-00 00:00:00' ? 'SIM' : 'NÃO' }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon :disabled="!$check('User', 'PATCH')" @click="viewItem(item)" size="large"
                                    class="me-2">
                                    mdi-pencil-outline
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import object from "@/common/util/object"
import status from "@/common/data/user-status.data";
import userTypes from "@/common/data/user-types.data";

Vue.use(VueLodash)

export default {
    name: "UserList",

    data: () => ({
        dataset: {
            status: [{ id: "", name: "Todos" }, ...status],
            userTypes: userTypes,
            clients: [],
            units: [],
            data: {}
        },
        dataList: {
            filter: {
                name: "",
                client: "",
                unit: "",
                status: ""
            },
            headers: [
                { text: "Nome", value: "name" },
                { text: "E-mail", value: "email" },
                { text: "Status", value: "status" },
                { text: "Verificado", value: "account_verified_at"},
                { text: "Criado em", value: "created_at" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
                pageText: '{0}-{1} de {2}',
            }
        },
    }),

    created() {
        this.load();
    },

    methods: {

        pageChanged(page) {
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.options.itemsPerPage = size;
            this.load(true);
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },

        async load() {

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;
           
            try {
                const result = await axios.get('/api/admin/users', { params: { page: this.dataList.options.page, limit: limit, search: search } });

                this.dataList.data = result.data.content.data;
                this.dataList.count = result.data.content.paging.total;

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        viewItem(item) {
            this.$router.push(`credores/${item.id}`);
        },
    },
};
</script>