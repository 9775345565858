var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-7"},[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"title-block"},[_c('span',{staticClass:"page-title",attrs:{"id":"title"}},[_vm._v("Analise em massa")]),_c('v-breadcrumbs',{staticClass:"app-breadcrumb",attrs:{"divider":"›","items":[
                        {
                            text: 'Homepage',
                            disabled: false,
                            to: '/home',
                            exact: true,
                        },
                        {
                            text: 'Incluir Lista',
                            disabled: true,
                            exact: true,
                        } ]}})],1),_c('div',{staticClass:"button-group"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"type":"button","color":"primary","elevation":"0","loading":_vm.dataset.loading},on:{"click":_vm.uploadFile}},on),[_vm._v("Enviar")])]}}])},[_c('span',[_vm._v("Enviar arquivos")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"btnGoBack","type":"button","color":"primary","elevation":"0","outlined":"","to":"/divergencias"}},on),[_vm._v("Voltar")])]}}])},[_c('span',[_vm._v("Voltar")])])],1)])])],1),_c('v-form',{ref:"form",model:{value:(_vm.dataForm.validForm),callback:function ($$v) {_vm.$set(_vm.dataForm, "validForm", $$v)},expression:"dataForm.validForm"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card-form-outlined"},[_c('v-card-title',{staticClass:"card-group-title"},[_vm._v("Arquivos")]),_c('v-row',[_c('v-col',[_c('v-banner',{attrs:{"single-line":"","outlined":""}},[_c('v-icon',{attrs:{"slot":"icon","color":"warning","size":"36"},slot:"icon"},[_vm._v("mdi-alert-outline")]),_vm._v(" O nome do(s) arquivo(s) deve(m) estar formatado(s) da seguinte forma: \"cpf-numerocredor-status.extensão\" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"height":"24px"},attrs:{"text":"","color":"primary","elevation":"0","outlined":"","dense":""},on:{"click":function($event){_vm.showDetails = !_vm.showDetails}}},on),[_vm._v(" "+_vm._s(_vm.showDetails ? 'Menos Detalhes' : 'Mais Detalhes')+" ")])]}}])},[_c('span',[_vm._v("Informações adicionais")])]),(_vm.showDetails)?_c('div',[_c('ul',[_c('li',[_c('strong',[_vm._v("CPF")]),_vm._v(": 11 dígitos sem pontuação ou traço (ex: 12345678900)")]),_c('li',[_c('strong',[_vm._v("Credor")]),_vm._v(": número identificador do credor no sistema (ex: 123)")]),_c('li',[_c('strong',[_vm._v("Status")]),_vm._v(": \"aprovado\" ou \"rejeitado\"")]),_c('li',[_c('strong',[_vm._v("Extensão")]),_vm._v(": \".csv\", \".xlsx\", \".docx\", etc.")])]),_c('br'),_c('p',[_vm._v("Exemplos de nomes válidos:")]),_c('ul',[_c('li',[_vm._v("12345678900-123-aprovado.csv")]),_c('li',[_vm._v("09876543210-456-rejeitado.xlsx")])])]):_vm._e()],1)],1)],1),_c('br'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"drop-area",class:{ 'grey lighten-2': _vm.dragover },on:{"drop":function($event){$event.preventDefault();return _vm.onDrop($event)},"dragover":function($event){$event.preventDefault();_vm.dragover = true},"dragenter":function($event){$event.preventDefault();_vm.dragover = true},"dragleave":function($event){$event.preventDefault();_vm.dragover = false}}},[(_vm.uploadedFiles.length == 0)?_c('p',{staticClass:"text-center"},[_vm._v(" Arraste e solte seus arquivos aqui! ")]):_vm._e(),(_vm.uploadedFiles.length > 0)?_c('v-virtual-scroll',{attrs:{"items":_vm.uploadedFiles,"height":"500","item-height":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var item = ref.item;
return [_c('v-list-item',{key:item.name},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" "),_c('span',{staticClass:"ml-3 text--secondary"},[_vm._v(" "+_vm._s(item.size)+" bytes ")])])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(item.name)}}},[_c('v-icon',[_vm._v(" mdi-close-circle ")])],1)],1)],1),_c('v-divider')]}}],null,false,4222449328)}):_vm._e()],1),_c('div',{staticClass:"file-count"},[_vm._v(" Total de arquivo(s): "),_c('strong',[_vm._v(_vm._s(_vm.fileCount))])])])],1),(_vm.dataset.progress)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"color":"green","height":"25","reactive":""},model:{value:(_vm.dataset.progress),callback:function ($$v) {_vm.$set(_vm.dataset, "progress", $$v)},expression:"dataset.progress"}},[_c('strong',[_vm._v(_vm._s(_vm.dataset.progress)+" %")])])],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }