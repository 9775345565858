import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import App from './App.vue'

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDkUPFO9VjpK9BX8HDrrFwzAV9K8BMlRPc',
    libraries: 'places',
  }
});