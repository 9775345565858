<template>
    <v-dialog v-model="dialog.show" :width="800" persistent>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col>
                        <div class="font-weight-bold mt-6 title-page text-left">Credenciamento</div>
                        <br>
                        <p>
                            Detalhes do credenciamento selecionado.
                        </p>
                    </v-col>
                </v-row>

                <v-row v-if="dataset.data && dataset.data.status == 0">
                    <v-col>
                        <v-banner single-line outlined>
                            <v-icon slot="icon" color="warning" size="36">
                                mdi-alert-outline
                            </v-icon>
                            Este credenciamento ainda não foi analisado!

                        </v-banner>
                    </v-col>
                </v-row>

                <!-- <v-row v-if="dataset.accreditation && dataset.accreditation.status == 1 || dataset.accreditation.status == 2"> -->
                <v-row
                    v-if="dataset.data && dataset.data.status !== 0 && dataset.data.accreditation && dataset.data.accreditation.user">
                    <v-col>
                        <v-banner single-line outlined>
                            <v-icon slot="icon" :color="dataset.data.status == 1 ? 'accent' : 'error'" size="36">
                                {{ dataset.data.status == 1 ? 'mdi-check-circle-outline' :
                                    'mdi-close-circle-outline' }}
                            </v-icon>
                            Este credenciamento foi marcado como <span class="text-uppercase"> <strong>{{
                                dataset.data.status == 1 ?
                                    'aprovado' : 'rejeitado' }}</strong></span> por
                            <span class="text-uppercase"> <strong>{{ dataset.data.admin.name
                                    }}</strong></span>
                        </v-banner>
                    </v-col>
                </v-row>

                <v-form v-if="dataset.data" ref="form" v-model="dataForm.validForm">
                    <v-row>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="12">
                                    <v-card class="card-form-outlined table-card">
                                        <v-card-title
                                            class="d-flex justify-space-between align-center table-card-title">Detalhes
                                            Credenciamento

                                        </v-card-title>
                                        <v-card-text v-if="dataset.data">
                                            <v-row v-if="dataset.data.accreditation && dataset.data.accreditation.user">
                                                <v-col cols="12" sm="4">
                                                    <v-text-field dense disabled id="name" label="Nome completo"
                                                        v-model="dataset.data.accreditation.user.name" required
                                                        :rules="[$rules.required]" outlined hide-details="auto">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="4">
                                                    <v-text-field dense disabled label="E-mail"
                                                        v-model="dataset.data.accreditation.user.email" required
                                                        outlined hide-details="auto">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="4">
                                                    <v-text-field dense id="cpf" label="CPF"
                                                        v-model="dataset.data.accreditation.user.registration" outlined
                                                        hide-details="auto" disabled>
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col cols="12" sm="4" v-if="dataset.data.has_procurator == 1">
                                                    <v-text-field dense id="procurador" label="Nome procurador"
                                                        :disabled="dataset.data.status !== 0"
                                                        v-model="dataset.data.procurator_name" outlined
                                                        hide-details="auto">
                                                    </v-text-field>
                                                </v-col>

                                                <v-col cols="12" sm="4" v-if="dataset.data.has_procurator == 1">
                                                    <v-text-field dense id="email_procurador" label="Email procurador"
                                                        :disabled="dataset.data.status !== 0"
                                                        v-model="dataset.data.procurator_email" outlined
                                                        hide-details="auto">
                                                    </v-text-field>
                                                </v-col>

                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-card class="card-form-outlined table-card">
                                        <v-card-title class="table-card-title">Documentos</v-card-title>

                                        <v-card-text v-for="(item, index) in dataset.data.docs" :key="index">
                                            <div class="action-container"
                                                :style="{ 'border': '1px solid ' + getColorByStatus(item.doc.type, item.doc.status) + ' !important' }">
                                                <span>
                                                    <strong>{{ index + 1 }}.</strong>
                                                    {{ item.doc.name.toUpperCase() }}
                                                    (<i>{{
                                                        item.doc.type | enum(dataset.documentTypes, 'id', 'name')
                                                        }}</i>)</span>

                                                <span class="ml-5">
                                                    <strong>Status: </strong>
                                                    {{ filterStatusByDocumentType(item.doc.type, item.doc.status) }}
                                                </span>

                                                <v-spacer></v-spacer>

                                                <v-tooltip bottom>
                                                    <template #activator="{ on }">
                                                        <v-icon v-on="on" @click="openDoc(item.doc.fileUrl)" icon
                                                            size="large" class="me-2 mr-2">
                                                            mdi-eye
                                                        </v-icon>
                                                    </template>
                                                    <span>Visualizar documento</span>
                                                </v-tooltip>

                                                <v-menu bottom offset-y v-if="dataset.data.status == 0">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-tooltip bottom>
                                                            <template
                                                                v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                                                                <v-icon icon v-bind="attrs"
                                                                    v-on="Object.assign({}, on, tooltipOn)" size="large"
                                                                    class="me-2">
                                                                    mdi-list-status
                                                                </v-icon>
                                                            </template>
                                                            <span>Informe se o documento é válido ou inválido</span>
                                                        </v-tooltip>
                                                    </template>

                                                    <v-list>
                                                        <v-list-item
                                                            v-for="(itemList, indexList) in filterStatusListByDocumentType(item.doc.type)"
                                                            :key="indexList" @click="item.doc.status = itemList.id">
                                                            <v-list-item-title>{{ itemList.name }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>

                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>

                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-card class="card-form-outlined" color="gray">
                                    <v-card-title class="card-group-title">
                                        <div class="title-container">
                                            <div class="title-block">
                                                <span id="title" class="table-card-title">Motivo</span>
                                            </div>
                                        </div>
                                    </v-card-title>
                                    <v-card-text v-if="dataset.data">
                                        <v-row>
                                            <v-col cols="12">
                                                <p class="font-weight-bold">Texto</p>
                                                <tiptap-vuetify :disabled="dataset.data.status !== 0"
                                                    v-model="dataset.data.reason" :rules="[$rules.required]"
                                                    :extensions="extensions" hide-details="auto" />

                                                <v-tooltip bottom>
                                                    <template #activator="{ on }">
                                                        <v-icon v-on="on" v-show="dataset.data.status == 0"
                                                            @click="openDialogDynamicText(true)" class="me-2">
                                                            <!-- mdi-pencil-outline -->
                                                            mdi-text-short
                                                        </v-icon>
                                                    </template>
                                                    <span>Selecionar textos predefinidos</span>
                                                </v-tooltip>

                                            </v-col>
                                        </v-row>
                                        <!-- <v-row>
                                            <v-col cols="12">
                                                <p class="font-weight-bold">Texto longo</p>
                                                <tiptap-vuetify :disabled="dataset.data.status !== 0"
                                                    v-model="dataset.data.reason" :rules="[$rules.required]"
                                                    :extensions="extensions" hide-details="auto" />
                                                <v-tooltip bottom>
                                                    <template #activator="{ on }">
                                                        <v-icon v-on="on" v-show="dataset.data.status == 0"
                                                            @click="openDialogDynamicText(false)" class="me-2">
                                                            mdi-text-long
                                                        </v-icon>
                                                    </template>
                                                    <span>Selecionar textos longos predefinidos</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row> -->
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row v-if="dataset.data && dataset.data.id">
                    <v-card>
                        <v-card-text v-if="dataset.data.status == 0">
                            <span><strong>OBS: </strong> O status do credenciamento foi definido como <strong
                                    class="text-uppercase">{{
                                    getFinalStatus().text }}</strong> com
                                base nas
                                informações selecionadas acima. Caso esse não seja o resultado final desejado, por
                                favor,
                                faça
                                as alterações necessárias nas seleções!</span>
                        </v-card-text>
                        <v-card-text v-else>
                            <span><strong>OBS: </strong> O status do credenciamento foi definido como <strong
                                    class="text-uppercase">{{
                                    getFinalStatus().text }}</strong>!</span>
                        </v-card-text>
                    </v-card>
                </v-row>

                <v-dialog v-model="dialogDynamicText" :width="900">
                    <v-card :max-height="700">
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <div class="font-weight-bold mt-6 title-page">Textos Automáticos</div>
                                    <p class="mt-2">
                                        Selecione um texto abaixo para adicionar no motivo
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <div>
                                        <v-list-item two-line v-for="(item, index) in dataset.dynamicTexts" :key="index"
                                            style="cursor: pointer" @click="selectDynamicText(item)">
                                            <v-list-item-content>
                                                <v-list-item-title class="font-weight-bold">{{ item.name
                                                    }}</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    <div
                                                        v-html="item.description">
                                                    </div>
                                                    <v-divider></v-divider>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-btn type="button" elevation="0" color="error"
                                        @click="dialogDynamicText = false;">Cancelar</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>


            </v-card-text>
            <v-card-text>
                <v-tooltip bottom v-if="dataset.data.status == 0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" @click.native="actionGeneralMeeting" :loading="dataset.loading"
                            v-bind="attrs" v-on="on">
                            Finalizar
                        </v-btn>
                    </template>
                    <span>Aprovar credenciamento</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="grey" text @click.native="goBack" v-bind="attrs" v-on="on">
                            Cancelar
                        </v-btn>
                    </template>
                    <span>Cancelar e voltar</span>
                </v-tooltip>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import 'moment/locale/pt-br';

import axios from "axios"

import string from "@/common/util/string"
import documentTypes from "@/common/data/document-types.data"

import {
    TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History, Table, TableCell, TableHeader,
    TableRow, TodoList, TodoItem
} from 'tiptap-vuetify'

export default {
    data: () => ({
        dialogDynamicText: false,
        dynamicTextSmallType: false,

        dataset: {
            data: {
                reason: '',
            },
            dynamicTexts: [],
            accreditation: null,
            loading: false,
            documentTypes: documentTypes
        },

        dialog: {
            show: false,
            resolve: null,
            reject: null,
        },

        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
        },

        extensions: [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList,
            [Heading, {
                options: {
                    levels: [1, 2, 3]
                }
            }],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak,
            [Table, {
                options: {
                    resizable: true
                }
            }],
            TableCell,
            TableHeader,
            TableRow,
        ],
    }),

    components: { 'tiptap-vuetify': TiptapVuetify },

    mounted: function () {
    },

    methods: {

        open() {
            this.dialog.show = true;

            this.load()

            return new Promise((resolve, reject) => {
                this.dialog.resolve = resolve;
                this.dialog.reject = reject;
            });
        },

        close() {
            this.dialog.resolve()
            this.dialog.show = false;
        },

        setData(data) {
            this.dataset.accreditation = data;
        },

        goBack() {
            this.dataset.data = {};
            this.dataset.accreditation = null;
            this.close();
        },

        openDoc(doc) {
            window.open(doc, '_blank');
        },

        openDialogDynamicText(small = false) {
            this.loadDynamicTexts()

            this.dynamicTextSmallType = small;
            this.dialogDynamicText = true;

        },

        getFinalStatus() {

            if (!this.dataset.data.docs) return { id: -1, text: 'pendente' }

            let result = { id: 1, text: 'aprovado' };

            for (let item of this.dataset.data.docs) {
                if (item.doc.status === 0) return { id: -1, text: 'pendente' };

                const documentType = this.dataset.documentTypes.find((x) => x.id === item.doc.type)

                const resultStatus = documentType.status.find((x) => x.id === item.doc.status);

                if (!resultStatus.approved) return { id: 2, text: 'rejeitado' };

            }

            return result;

        },

        getColorByStatus(type, status) {
            if (status === 0) return '#FFEB3B'

            const documentType = this.dataset.documentTypes.find((x) => x.id === type)

            if (!documentType) return '#FFEB3B'

            const resultStatus = documentType.status.find((x) => x.id === status);

            if (!resultStatus) return '#FB3A04'

            return resultStatus.color
        },

        filterStatusByDocumentType(type, status) {
            const documentType = this.dataset.documentTypes.find((x) => x.id === type)

            if (!documentType) return 'Tipo de documento não encontrado'

            const resultStatus = documentType.status.find((x) => x.id === status);

            if (!resultStatus) return 'Pendente'

            return resultStatus.name
        },

        filterStatusListByDocumentType(type) {
            const documentType = this.dataset.documentTypes.find((x) => x.id === type)

            if (!documentType) return []

            return documentType.status
        },

        selectDynamicText(item) {
            this.dataset.data.reason += item.small_description

            this.dialogDynamicText = false;
        },

        async load() {
            this.dataset.loading = true;

            try {
                const result = await axios.get(`/api/admin/accreditations/find/${this.dataset.accreditation.id}`);
                const data = result.data.content;

                this.dataset.data = data;

                this.dataset.data.reason = data.reason || ""

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }

            this.dataset.loading = false;
        },


        async loadDynamicTexts() {

            try {
                const result = await axios.get(`/api/admin/dynamic-texts`, { params: { start: 1, limit: 100000, search: {} } });

                this.dataset.dynamicTexts = result.data.content.data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async actionGeneralMeeting() {

            const finalStatus = this.getFinalStatus();

            if (finalStatus.id === -1) {
                this.$dialog.notify.error('É necessário verificar todos os documentos do credenciamento para continuar!', {
                    position: 'top-right',
                    timeout: 2000
                })

                return;
            }

            if (!this.dataset.data.reason) {
                this.$dialog.notify.error('Para continuar infome um motivo!', {
                    position: 'top-right',
                    timeout: 2000
                })

                return;
            }

            const proceedAction = await this.$dialog.confirm({
                text: `Deseja salvar o credenciamento como ${finalStatus.text.toUpperCase()}?`,
                title: 'Atenção!',
                actions: {
                    false: 'Cancelar',
                    true: 'Confirmar'
                }
            })

            if (!proceedAction) return;

            try {

                let payload = this.buildPayload();

                payload.status = finalStatus.id;

                const response = await axios.patch(`/api/admin/accreditations/action/${this.dataset.data.id}`, payload);

                this.$dialog.notify.info("Credenciamento atualizado com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                // this.dataForm.addLoading = false;
            }
        },

        buildPayload() {
            const data = {
                procurator_email: this.dataset.data.procurator_email,
                procurator_name: this.dataset.data.procurator_name,
                reason: this.dataset.data.reason,
                docs: this.dataset.data.docs.map((x) => { return { id: x.doc.id, status: x.doc.status } })
            }

            return data;
        }
    }
};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: "Raleway";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.action-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
}


p {
    text-align: justify;
}
</style>
