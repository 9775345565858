var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-7"},[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"title-block"},[_c('span',{staticClass:"page-title",attrs:{"id":"title"}},[_vm._v("QGC")]),_c('v-breadcrumbs',{staticClass:"app-breadcrumb",attrs:{"divider":"›","items":[
                        {
                            text: 'Homepage',
                            disabled: false,
                            to: '/home',
                            exact: true,
                        },
                        {
                            text: 'QGC',
                            disabled: true,
                            exact: true,
                        } ]}})],1),_c('div',{staticClass:"button-group"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"btnNew","type":"button","color":"primary","elevation":"0","to":"/qgc/novo"}},on),[_vm._v("Incluir ")])]}}])},[_c('span',[_vm._v("Adicionar")])])],1)])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"filter-card"},[_c('v-card-title',{staticClass:"filter-card-title"},[_vm._v("Filtro")]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.load()}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"Nome","hide-details":"","outlined":""},model:{value:(_vm.dataList.filter.name),callback:function ($$v) {_vm.$set(_vm.dataList.filter, "name", $$v)},expression:"dataList.filter.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"E-mail","hide-details":"","outlined":""},model:{value:(_vm.dataList.filter.email),callback:function ($$v) {_vm.$set(_vm.dataList.filter, "email", $$v)},expression:"dataList.filter.email"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"CPF","hide-details":"","outlined":""},model:{value:(_vm.dataList.filter.registration),callback:function ($$v) {_vm.$set(_vm.dataList.filter, "registration", $$v)},expression:"dataList.filter.registration"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"dense":"","items":_vm.dataset.clients,"label":"Caso","item-value":"id","item-text":"name","outlined":"","hide-details":"auto","no-data-text":"Nenhum caso encontrado"},model:{value:(_vm.dataList.filter.client),callback:function ($$v) {_vm.$set(_vm.dataList.filter, "client", $$v)},expression:"dataList.filter.client"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"btnFilter","type":"submit","color":"primary","dark":"","elevation":"2"}},on),[_vm._v("Filtrar ")])]}}])},[_c('span',[_vm._v("Filtrar dados")])])],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"table-card"},[_c('v-card-title',{staticClass:"table-card-title"},[_vm._v("Credores "),_c('v-spacer'),_c('div',{staticClass:"icon-container"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"icon":"","size":"large"},on:{"click":function($event){return _vm.load(true)}}},on),[_vm._v(" mdi-microsoft-excel ")])]}}])},[_c('span',[_vm._v("Gerar CSV")])])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"app-table hover-table",attrs:{"headers":_vm.dataList.headers,"items":_vm.dataList.data,"server-items-length":_vm.dataList.count,"disable-sort":true,"loading":_vm.dataList.loading,"no-data-text":"Nenhum credor foi encontrado","loading-text":"Carregando dados...","options":_vm.dataList.options,"footer-props":_vm.dataList.footerOptions},on:{"update:items-per-page":_vm.pageSizeChanged,"update:page":_vm.pageChanged,"update:options":function($event){return _vm.$set(_vm.dataList, "options", $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                    var item = ref.item;
return [_c('v-avatar',{attrs:{"size":30}},[_c('img',{attrs:{"src":'https://ui-avatars.com/api/?name=' + item.name}})]),_c('span',{staticClass:"ml-4 list-item-title"},[_vm._v(_vm._s(item.name))]),_c('br')]}},{key:"item.client.name",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.truncateText(item.client.name, 30))+" ")]}},{key:"item.registration",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCpfCnpj(item.registration))+" ")]}},{key:"item.type",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","text-color":"primary"}},[_vm._v(" "+_vm._s(_vm.getTypeName(item.type))+" ")])]}},{key:"item.amount",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm._f("currency")(item.amount))+" ")]}},{key:"item.created_at",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("defaultDate")(item.created_at,"DD/MM/YYYY HH:mm:ss"))+" ")]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{staticClass:"me-2",attrs:{"disabled":!_vm.$check('User', 'PATCH'),"size":"large"},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" mdi-pencil-outline ")])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }