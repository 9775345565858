<template>
    <v-dialog v-model="dialog.show" persistent max-width="1000px">
        <v-card>
            <v-sheet color="primary" class="pa-3">
                <v-row no-gutters>
                  <v-col>
                    <div class="sub-title-page">Lista de votações</div>
                  </v-col>
                </v-row>
            </v-sheet>
                
            <v-col v-if="dataset.data.name">
               <v-card-title class="table-card-title d-flex justify-space-between">
                Assembléia - {{ dataset.data.name}}
                </v-card-title> 
            </v-col>
            
            <v-card-text>
                
                <v-row>
                    <v-col cols="12">
                       
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                        :disable-sort="true" :loading="dataList.loading" 
                        no-data-text="Nenhuma votação encontrada" loading-text="Carregando dados..."
                        class="app-table hover-table" 
                        :disable-pagination="true" hide-default-footer
                        
                        >
                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip :color="getColorByStatus(item.status)" label text-color="white">
                                    {{ getStatusName(item.status) }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.type`]="{ item }">
                                <v-chip label text-color="primary">
                                    {{ getTypeName(item.type) }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | defaultDate("DD/MM/YYYY HH:mm") }}
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">

                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon v-on="on" :disabled="!$check('User', 'PATCH')" @click="editVoting(item)" size="large" class="me-2">
                                            mdi-pencil-outline
                                        </v-icon>
                                    </template>
                                    <span>Editar votação</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon v-on="on" :disabled="!$check('User', 'PATCH')" @click="deleteVoting(item)" size="large" class="me-2">
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span>Excluir votação</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon v-on="on" :disabled="!$check('User', 'PATCH')" @click="setStatus(item, 2)" size="large" color="accent" class="me-2">
                                            mdi-play-circle-outline
                                        </v-icon>
                                    </template>
                                    <span>Iniciar votação</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon v-on="on" :disabled="!$check('User', 'PATCH')" @click="setStatus(item, 3)" size="large"  color="warning" class="me-2">
                                            mdi-pause-circle-outline
                                        </v-icon>
                                    </template>
                                    <span>Pausar votação</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon v-on="on" :disabled="!$check('User', 'PATCH')" @click="setStatus(item, 4)" size="large"  color="red" class="me-2">
                                            mdi-stop-circle-outline
                                        </v-icon>
                                    </template>
                                    <span>Finalizar votação</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon icon v-on="on" :disabled="!$check('User', 'PATCH')" @click="votingItem(item)"
                                            size="large" color="primary" class="me-2 border ">
                                            mdi-vote-outline
                                        </v-icon>
                                    </template>
                                    <span>Votação</span>
                                </v-tooltip>

                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>    
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-btn v-on="on" color="grey" text @click.native="dialog.show=false" outlined>
                            Voltar
                        </v-btn>
                    </template>
                    <span>Voltar para assembléia</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-btn v-on="on" color="primary" class="ml-5" elevation="0" dark @click="addVoting(dataset.data.id)">
                            Incluir
                        </v-btn>
                    </template>
                    <span>Incluir votação</span>
                </v-tooltip>

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import 'moment/locale/pt-br';

import agcVotingStatus from "@/common/data/agc-voting-status.data"
import agcVotingTypes from "@/common/data/agc-voting-types.data"

import { eventHub } from "@/main";

import axios from "axios"

import string from "@/common/util/string"

export default {

    data: () => ({
        dataset: {
            data: {},
            loading: false,
            assemblyVoting: null,
        },
        agcVotingTypes: agcVotingTypes,
        agcVotingStatus: agcVotingStatus,
        dataList: {
            filter: {
                name: "",
                registration: "",
                client: "",
                code: "",
                status: "",

            },
            headers: [
                { text: "Nome", value: "name" },
                { text: "Tipo", value: "type" },
                { text: "Status", value: "status" },
                { text: "Criado em", value: "created_at" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
           
        },

        dialog: {
            show: false,
            resolve: null,
            reject: null,
        },
    }),

    methods: {
        
        open() {
            this.dialog.show = true;

            this.load();

            return new Promise((resolve, reject) => {
                this.dialog.resolve = resolve;
                this.dialog.reject = reject;
            });
            
        },

        close() {
            this.dialog.show = false;
            this.dialog.resolve()
        },

        // close() {
        //     this.dialog.resolve()
        //     this.dialog.show = false;
        // },

        goBack() {
            this.dataset.data = {};
            this.close();
        },

        setData(data) {
            this.dataset.data = data;
            console.log("Dataset: ", data)
        },

        getStatusName(value) {
            if (value == null) {
                return;
            }

            const status = this.agcVotingStatus.find(s => s.id === value);
            return status ? status.name : 'N/A';
        },

        
        votingItem(item) {

           this.$router.push(`agcs/votacao/${item.id}`);
        },


        getTypeName(value) {
            const type = this.agcVotingTypes.find(s => s.id === value);
            return type.name ? type.name : 'N/A';
        },
        

        getColorByStatus(status) {
            switch (status) {
                case 1:
                    return 'grey'
                case 2:
                    return 'accent'
                case 3:
                    return 'warning'
                case 4:
                    return 'error'
                default:
                    break;
            }
        },

       
        addVoting(){
            
            let data = {
                agc_id: this.dataset.data.id,
                agc_name: this.dataset.data.name,
                new: true
            };
      
            this.$router.push({
                name: "VotingDetails",
                params: { data }
            });
        },

        editVoting(item){
            
            let data = {
                agc_id: this.dataset.data.id,
                agc_name: this.dataset.data.name,
                voting_id: item.id,
                voting_name: item.name,
                description: item.description,
                type: item.type,
                status: item.status,
                new: false
            };
      
            this.$router.push({
                name: "VotingDetails",
                params: { data }
            });
        },

        async deleteVoting(item){

            if (!item || !item.id) return;

            if (item.status !== 1 && item.status !== 4) {
                this.$dialog.notify.error("Votação não pode ser excluída! Verifique status da votação!", { position: "top-right", timeout: 5000 });
                return;
            }

            const proceedAction = await this.$dialog.confirm({
                text: 'Tem certeza que deseja continuar?',
                title: 'Excluir votação',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true

            try {

                const result = await axios.delete(`/api/admin/general-meeting-votings/${item.id}`)

                this.$dialog.notify.info("Votação excluída com sucesso!", { position: "top-right", timeout: 2000 });

                this.load();

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }
        },


        async setStatus(item, status){

            if (!item || !item.id) return;
            
            if (item.status === status) return;

            if (item.status === 4 && (status === 2 || status === 3)) {
                this.$dialog.notify.error("Votação não pode ser iniciada! Verifique status da votação!", { position: "top-right", timeout: 5000 });
                return;
            }

            if (status === 2 && this.dataset.data.status !== 5) {
                this.$dialog.notify.error("Votação não pode ser iniciada! Verifique status da Assembléia!", { position: "top-right", timeout: 5000 });
                return;
            }
        
            this.dataList.loading = true

            try {
                const response = await axios.patch(`/api/admin/general-meeting-votings/set-status/${item.id}`, {status: status});

                this.$dialog.notify.info("Status da votação atualizada com sucesso!", { position: "top-right", timeout: 2000 });

                this.load();

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataList.loading = false;
            }
        },


        async load() {

            console.log("Entrou no load votation data")

            this.dataset.loading = true;

            try {
                const result = await axios.get(`/api/admin/general-meeting-votings/action/${this.dataset.data.id}`);
                
                const data = result.data.content

                this.dataList.data = data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }

            this.dataset.loading = false;
        },
        
    }
};
</script>

<style lang="scss">

.title-page_old {
    color: var(--v-primary-base);
    font-family: "Raleway";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.sub-title-page {
    font-family: "Raleway";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    color: white;
    text-align: center;
}


</style>
