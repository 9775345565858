<template>
    <v-dialog v-model="dialog.show" persistent max-width="700px">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="font-weight-bold mt-6 title-page">Adicionar Informações</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="editor-container" >
              <tiptap-vuetify class="custom-editor" v-model="dataset.assemblyCommentText"
              :extensions="extensions" placeholder="Digite aqui informações adicionais da assembleia..."  hide-details="auto" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="saveCommentData" color="primary" dark>Salvar</v-btn>
        <v-btn color="grey" text @click.native="goBack">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
  </template>

<script>
import 'moment/locale/pt-br';

import { eventHub } from "@/main";

import axios from "axios"

import string from "@/common/util/string"

import {
    TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, 
    Blockquote, HardBreak, HorizontalRule, History, Table, TableCell, TableHeader, TableRow, TodoList, TodoItem
} from 'tiptap-vuetify'

export default {

    data: () => ({
        dataset: {
            data: {},
            loading: false,
            assemblyCommentText: null,
        },

        dialog: {
            show: false,
            resolve: null,
            reject: null,
        },

        extensions: [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList,
            [Heading, {
                options: {
                    levels: [1, 2, 3]
                }
            }],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak,
            [Table, {
                options: {
                    resizable: true
                }
            }],
            TableCell,
            TableHeader,
            TableRow,
        ],
    }),

    components: { 'tiptap-vuetify': TiptapVuetify },

    methods: {

        open() {
            this.dialog.show = true;
                        
            this.dataset.assemblyCommentText = this.dataset.data.comments

            return new Promise((resolve, reject) => {
                this.dialog.resolve = resolve;
                this.dialog.reject = reject;
            });
            
        },

        close() {
            this.dialog.resolve()
            this.dialog.show = false;
        },

        goBack() {
            this.dataset.data = {};
            this.close();
        },

        setData(item) {
            this.dataset.data = item
        },


        async saveCommentData() {
       
            if (this.dataset.assemblyCommentText.length > 2000) {
                this.$dialog.notify.error("O texto excedeu o limite de 2000 caracteres", { position: "top-right", timeout: 5000 });
                return; 
            }
           
            const id = this.dataset.data.id

            try {

                const response = await axios.patch(`/api/admin/general-meetings/${id}`, {comments: this.dataset.assemblyCommentText}, {
                    
                })

                this.$dialog.notify.info("Informações adicionadas com sucesso!", { position: "top-right", timeout: 3000 });

                this.goBack()

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } 

        },
        
    }
};
</script>

<style lang="scss">

.title-page {
    color: var(--v-primary-base);
    font-family: "Raleway";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

p {
    text-align: justify;
}

.editor-container {
  height: 500px; 
  overflow-y: auto; 
}

.custom-editor {
    .tiptap-vuetify-editor__content {
        min-height: 300px !important;
    }
        
    .ProseMirror {
        min-height: 300px !important;
    }
}


</style>
