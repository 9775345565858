<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Análise de Ofício</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Análise ofício',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnNew" type="button" color="primary" elevation="0"
                            to="/analise-oficio/novo">Incluir</v-btn>
                    </div>
                </div>

            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="load()">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field dense id="name" label="Nome" v-model="dataList.filter.name" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field dense id="email" label="Email" v-model="dataList.filter.email" hide-details
                                        outlined></v-text-field>   
                                </v-col>
                            </v-row>
                            
                            <v-row>

                                <v-col cols="12" sm="6">   
                                    <v-autocomplete dense :items="dataset.clients" label="Caso" v-model="dataList.filter.client" 
                                    item-value="id" item-text="name" outlined hide-details="auto"
                                    no-data-text="Nenhum caso encontrado">
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-select dense :items="dataset.creditorTypes" label="Classe"
                                        v-model="dataList.filter.type" item-value="id" item-text="name" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn id="btnFilter" type="submit" color="primary" dark elevation="2">Filtrar</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="table-card">
                    <v-card-title class="table-card-title">Análises</v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhuma análise foi encontrada" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">

                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                            </template>

                            <template v-slot:[`item.userCreditor.type`]="{ item }">
                                <v-chip label text-color="primary">
                                    {{ getTypeName(item.userCreditor.type) }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.userCreditor.client_id`]="{ item }">  
                                {{ getClientName(item.userCreditor.client_id) }} 
                            </template>
                            
                            <template v-slot:[`item.email`]="{ item }">
                                {{ item.email && item.email ? item.email : 'N/A' }}
                            </template>

                            <template v-slot:[`item.name`]="{ item }">
                                {{ item.name && item.name ? item.name : 'N/A' }}
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-row align="center">
                                    <v-icon :disabled="!$check('users/1', ['PATCH'])" @click="viewItem(item)" size="large"
                                        class="me-2">
                                        mdi-pencil-outline
                                    </v-icon>

                                    <v-icon :disabled="!$check('users/1', ['DELETE'])" @click="deleteItem(item)" size="large"
                                        class="me-2">
                                        mdi-delete
                                    </v-icon>
                                </v-row>    
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';
import creditorTypes from "@/common/data/creditor-types.data"
import object from "@/common/util/object"

Vue.use(VueLodash)

export default {
    name: "EmployeeList",

    data: () => ({
        dataset: {
            data: {},
            clients: [],
            creditorTypes: [ {id: "", name: "Todos"}, ...creditorTypes],
        },
        dataList: {
            filter: {
                name: "",
                email: "",
                client: "",
                type: "",
            },
            headers: [
                { text: "Nome", value: "userCreditor.name" },
                { text: "Email", value: "userCreditor.email" },
                { text: "Caso", value: "userCreditor.client_id" },
                { text: "Classe", value: "userCreditor.type" },
                { text: "Criado em", value: "created_at" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                size: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
                pageText: '{0}-{1} de {2}',
            }
        },
    }),

    created() {
        this.load();
        this.loadClients();
    },

    methods: {
        
        pageChanged(page) {            
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {    
            this.dataList.options.size = size;
            this.load(true);
        },

        getTypeName(typeId) {
           
            switch (typeId) {
                case 1:
                    return "Classe I - Trabalhista";
                case 2:
                    return "Classe II - Garantia Real";
                case 3:
                    return "Classe III - Quirografários";
                case 4:
                    return "Classe IV - ME/EPP";
                case 5:
                    return "Extraconcursais";
                default:
                    return "N/A";
            }
        },

        getClientName(client_id) {

            const client = this.dataset.clients.find(s => s.id === client_id);
            
            if (client.name) {
                return client.name.length > 30 ? client.name.slice(0, 30) + '...' : client.name;
            } else {
                return 'N/A';
            }
        },


        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },

        async load() {

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = this.dataList.options.size;
    
            try {
                const result = await axios.get('/api/admin/official-letters', { params: { page: this.dataList.options.page, limit: limit, search: search } });

                this.dataList.data = result.data.content.data;
                this.dataList.count = result.data.content.paging.total;

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async loadClients() {

            try {
                const result = await axios.get(`/api/clients`, { params: { start: 1, limit: 100000, search: {} } });

                const data = result.data.content.data
                
                data.sort((a, b) => a.name.localeCompare(b.name));
                
                this.dataset.clients = [{ id: "", name: "Todos " }, ...data];

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },


        viewItem(item) {
            this.$router.push(`analise-oficio/${item.id}`);
        },

        async deleteItem(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Tem certeza que deseja continuar?',
                title: 'Excluir Análise',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true

            try {

                const result = await axios.delete(`/api/admin/official-letters/${item.id}`)

                this.$dialog.notify.info("Análise excluída com sucesso!", { position: "top-right", timeout: 2000 });

                this.load()

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }

        },
    },
};
</script>