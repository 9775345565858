<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="finalizeAnalysis"
                            :loading="dataForm.addLoading" v-if="!page.isNew">Finalizar</v-btn>
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="add"
                            :loading="dataForm.addLoading" v-if="page.isNew">Salvar</v-btn>
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="update"
                            :loading="dataForm.updateLoading" v-if="!page.isNew">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined
                            to="/analise-oficio">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-currency-field label="Valor" dense v-model="dataset.data.amount"
                                                :allowNegative=false hide-details="auto" outlined required
                                                :rules="[$rules.required]">
                                            </v-currency-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense type="date" label="Prazo contraditório"
                                                :value="formatDate(dataset.data.contradictory_deadline_at)"
                                                @input="updateContraditory" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <!-- <v-row>
                                        <v-col cols="12">
                                            <p class="font-weight-bold">Analise</p>
                                            <tiptap-vuetify v-model="dataset.data.reason" :rules="[$rules.required]"
                                                :extensions="extensions" hide-details="auto" />
                                        </v-col>
                                    </v-row> -->

                                    <v-row>
                                        <v-col cols="12">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-card class="card-form-outlined" color="gray">
                                                        <v-card-title class="card-group-title">
                                                            <div class="title-container">
                                                                <div class="title-block">
                                                                    <span id="title" class="table-card-title">Análise</span>
                                                                    <br>
                                                                    <!-- <span class="text-subtitle-2 text-none font-weight-medium">Motivo para aprovação ou
                                                                        rejeição</span> -->
                                                                </div>
                                                            </div>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col cols="12">
                                                                    <tiptap-vuetify class="custom-editor-long"
                                                                        v-model="dataset.data.reason"
                                                                        :rules="[$rules.required]" :extensions="extensions"
                                                                        hide-details="auto" />
                                                                    <!-- <v-btn v-if="dataset.data.status === 0" type="button" elevation="0" color="primary"
                                                                        outlined class="mt-5" @click="openDialogDynamicText(false);">Adicionar
                                                                        texto</v-btn>-->
                                                                    <v-tooltip bottom>
                                                                        <template #activator="{ on }">
                                                                            <v-icon v-on="on"
                                                                                @click="openDialogDynamicText(false)"
                                                                                class="me-2">
                                                                                mdi-text-long
                                                                            </v-icon>
                                                                        </template>
                                                                        <span>Selecionar textos longos predefinidos</span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>




                                </v-card-text>
                            </v-card>
                        </v-col>

                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Credor</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <v-list-item dense v-if="dataset.modalResult.name">
                                                <v-list-item-avatar>
                                                    <v-icon size="32">mdi-account-outline</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title> {{ dataset.modalResult.name }}</v-list-item-title>
                                                    <v-list-item-subtitle> {{ dataset.modalResult.client.name
                                                    }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item dense v-else>
                                                <v-list-item-avatar>
                                                    <v-icon size="32">mdi-account-outline</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>Nenhum credor associado a esta
                                                        análise</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>


                                    <v-row>
                                        <v-col>
                                            <v-tooltip bottom>
                                                <template #activator="{ on }">
                                                    <v-btn v-on="on" @click="openCreditorSelectionModal(dataset.data)"
                                                        color="primary" dark elevation="2" small>
                                                        {{ dataset.modalResult.name ? 'Alterar' : 'Associar' }}
                                                    </v-btn>
                                                </template>
                                                <span>{{ dataset.modalResult.name ? 'Alterar credor' : 'Associar credor'
                                                }}</span>
                                            </v-tooltip>

                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-col>
            </v-row>
        </v-form>

        <v-dialog v-model="dialogDynamicText" :width="900">
            <v-card :max-height="700">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <div class="font-weight-bold mt-6 title-page">Textos Automáticos</div>
                            <p class="mt-2">
                                Selecione um texto abaixo para adicionar na análise
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div>
                                <v-list-item v-for="(item, index) in dataset.dynamicTexts" :key="index"
                                    style="cursor: pointer" @click="selectDynamicText(item)">
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold">{{ item.name }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            <div v-html="dynamicTextSmallType ? item.small_description : item.description">
                                            </div>
                                            <v-divider></v-divider>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <!-- <v-card class="rounded-lg mb-10" v-for="(item, index) in dataset.dynamicTexts" :key="index"
                                elevation="2">
                                <v-card-title>
                                    {{ item.name }}
                                </v-card-title>
                                <v-card-text>
                                    <div v-html="dynamicTextSmallType ? item.small_description : item.description"></div>
                                </v-card-text>
                            </v-card> -->
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-btn type="button" elevation="0" color="error"
                                @click="dialogDynamicText = false;">Cancelar</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <analysis-creditor-selection-modal ref="AnalysisCreditorSelectionModalRef"></analysis-creditor-selection-modal>

    </v-container>
</template>

<script>

import string from "@/common/util/string"

import axios from "axios"

import { eventHub } from "@/main";

import AnalysisCreditorSelectionModal from "@/components/LetterAnalysisCreditorSelectionModal.vue"

import incidentTypesList from "@/common/data/incident-types.data"

import {
    TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph
} from 'tiptap-vuetify'


export default {
    name: "LetterAnalysisDetails",

    components: {
        'tiptap-vuetify': TiptapVuetify,
        'analysis-creditor-selection-modal': AnalysisCreditorSelectionModal,

    },


    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            breadcrumbItems: [],
            dynamicTexts: [],
            data: {
                contradictory_deadline_at: null,
            },
            incidentTypesList: incidentTypesList,
            modalResult: {
                name: null,
                client: { name: null }

            },
        },
        dialogAnalysisCreditorSelection: false,
        dynamicTextSmallType: false,
        dialogDynamicText: false,
        selectedDoc: null,
        dialogViewDoc: false,
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            showPassword: false,
            showPasswordConfirmation: false,
        },
        extensions: [
            Underline,
            Strike,
            Italic,
            Bold,
            Paragraph,
        ],
    }),

    created() {
        this.loadData()

        eventHub.$on('DIALOG-ANALYSIS-CREDITOR-SELECTION', value => {
            this.dialogAnalysisCreditorSelection = value;
        });

    },


    methods: {
        goBack() {
            this.$router.replace("/analise-oficio");
        },

        async loadData() {
            this.setPage();
            this.listBreadCrumbItems();
            await this.load();
        },

        openDialogDynamicText(small = false) {
            this.loadDynamicTexts()

            this.dynamicTextSmallType = small;
            this.dialogDynamicText = true;

        },

        formatDate(date) {
            if (!date || date === "0000-00-00 00:00:00") return null;
            return this.$moment(date).format('YYYY-MM-DD');
        },

        updateContraditory(value) {
            this.dataset.data.contradictory_deadline_at = value;
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = `${this.page.isNew ? 'Nova análise' : 'Detalhes da análise'}`;
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Análise de oficio",
                disabled: false,
                to: "/analise-oficio",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.title,
                disabled: true,
                exact: true,
            });
        },

        async loadDynamicTexts() {

            try {
                const result = await axios.get(`/api/admin/dynamic-texts`, { params: { start: 1, limit: 100000, search: {} } });

                this.dataset.dynamicTexts = result.data.content.data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        selectDynamicText(item) {
            if (this.dynamicTextSmallType) {
                this.dataset.data.smallReason += item.small_description
            } else {
                this.dataset.data.reason += item.description
            }

            this.dialogDynamicText = false;
        },


        async openCreditorSelectionModal(item) {

            this.$refs.AnalysisCreditorSelectionModalRef.setData(item);

            this.$refs.AnalysisCreditorSelectionModalRef.open().then((results) => {

                if (!results) {
                    return
                }

                this.dataset.modalResult = results

            });

        },

        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/api/admin/official-letters/${this.$route.params.id}`);
                console.log('result ', result)

                const data = result.data.content;

                this.dataset.data = data;

                console.log("nome: ", data.userCreditor.name)

                if (data) {
                    this.dataset.modalResult.name = data.userCreditor.name;

                    const client_id = data.userCreditor.client_id

                    try {
                        const result = await axios.get(`/api/clients/${client_id}`);

                        const data = result.data.content;

                        this.dataset.modalResult.client.name = data.name

                        console.log("client: ", data.name)

                    } catch (error) {
                        this.$dialog.notify.error(this.$codes(error), {
                            position: 'top-right',
                            timeout: 2000
                        })
                    }

                }

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },


        async add() {

            if (!this.dataset.modalResult.name) {
                this.$dialog.notify.error("Credor não informado!", { position: "top-right", timeout: 3000 });
                return;
            }

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir a análise?',
                title: 'Incluir análise',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/api/admin/official-letters', payload);

                this.$dialog.notify.info("Análise salva com sucesso!", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        async finalizeAnalysis() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja finalizar a análise?',
                title: 'Finalisar análise',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            // this.dataForm.addLoading = true;

            // const payload = this.buildPayload();

            // try {
            //     const response = await axios.post('/api/admin/official-letters', payload);

            //     this.$dialog.notify.info("Análise salva com sucesso!", { position: "top-right", timeout: 2000 });

            //     this.goBack()
            // } catch (error) {
            //     this.$dialog.notify.error(this.$codes(error), {
            //         position: 'top-right',
            //         timeout: 2000
            //     })
            // } finally {
            //     this.dataForm.addLoading = false;
            // }

        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar dados da análise?',
                title: 'Atualizar análise',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/api/admin/official-letters/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Análise atualizada com sucesso!", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },


        buildPayload() {
            const formData = new FormData()

            if (this.dataset.data.contradictory_deadline_at)
                formData.append('contradictory_deadline_at', this.dataset.data.contradictory_deadline_at);

            if (this.dataset.data.amount)
                formData.append('amount', this.dataset.data.amount);

            if (this.dataset.data.reason)
                formData.append('reason', this.dataset.data.reason);

            // if (this.dataset.data.client_id)
            //     formData.append('client_id', this.dataset.data.client_id)

            if (this.dataset.modalResult.id)
                formData.append('user_creditor_id', this.dataset.modalResult.id)

            return formData;
        },

    },
};
</script>

<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}

.custom-editor-short {
    .tiptap-vuetify-editor__content {
        min-height: 100px !important;
    }

    .ProseMirror {
        min-height: 100px !important;
    }
}

.custom-editor-long {
    .tiptap-vuetify-editor__content {
        min-height: 200px !important;
    }

    .ProseMirror {
        min-height: 200px !important;
    }
}</style>