var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-7"},[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"title-block"},[_c('span',{staticClass:"page-title",attrs:{"id":"title"}},[_vm._v("Textos Dinâmicos (Site)")]),_c('v-breadcrumbs',{staticClass:"app-breadcrumb",attrs:{"divider":"›","items":[
                        {
                            text: 'Homepage',
                            disabled: false,
                            to: '/home',
                            exact: true,
                        },
                        {
                            text: 'Textos dinâmicos',
                            disabled: true,
                            exact: true,
                        } ]}})],1),_c('div',{staticClass:"button-group"},[_c('v-btn',{attrs:{"id":"btnNew","type":"button","color":"primary","elevation":"0","to":"/textos-dinamicos/novo"}},[_vm._v("Incluir")])],1)])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"table-card"},[_c('v-card-title',{staticClass:"table-card-title"},[_vm._v("Textos dinâmicos")]),_c('v-card-text',[_c('v-data-table',{staticClass:"app-table hover-table",attrs:{"headers":_vm.dataList.headers,"items":_vm.dataList.data,"server-items-length":_vm.dataList.count,"disable-sort":true,"loading":_vm.dataList.loading,"no-data-text":"Nenhum texto foi encontrado","loading-text":"Carregando dados...","options":_vm.dataList.options,"footer-props":_vm.dataList.footerOptions},on:{"update:items-per-page":_vm.pageSizeChanged,"update:page":_vm.pageChanged,"update:options":function($event){return _vm.$set(_vm.dataList, "options", $event)}},scopedSlots:_vm._u([{key:"item.key",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("enum")(item.key,_vm.dataset.dynamicInfoKeys, "key", "name"))+" ")]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{staticClass:"me-2",attrs:{"disabled":!_vm.$check('users/1', ['PATCH']),"size":"large"},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" mdi-pencil-outline ")])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }