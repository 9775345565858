<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›"
                            :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn v-on="on" id="btnBack" type="button" elevation="0" color="primary" outlined
                                    to="/agcs">Voltar</v-btn>
                            </template>
                            <span>Voltar para Assembleia</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-col>
                <span class="text-uppercase"> <strong>{{ dataset.data.name }}</strong></span>
            </v-col>
        </v-row> -->
        <v-row >
            <v-col cols="12">
              <v-sheet elevation="1" class="pa-2" style="border-radius: 4px;" color="grey lighten-4">
                <v-col v-if='dataset.data.assemblyName' class="text-uppercase">
                    <span class="primary--text" >Assembléia - </span> <strong>{{ dataset.data.assemblyName }}</strong>
                </v-col>
                <v-col v-if='dataset.data.clientName' class="text-uppercase mt-n5">
                    <span class="primary--text">Caso - </span><strong>{{ dataset.data.clientName }}</strong>
                </v-col>
              </v-sheet>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="filterSearch()">
                            <v-row>
                                <v-col cols="12" sm="4">
                                    <v-text-field dense id="name" label="Nome" v-model="dataset.filter.name"
                                        hide-details outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-select dense :items="dataset.presenceTypes" label="Presença"
                                        v-model="dataset.filter.presence" item-value="id" item-text="name" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-select dense :items="dataset.creditorTypes" label="Classe"
                                        v-model="dataset.filter.type" item-value="id" item-text="name" outlined
                                        hide-details="auto">
                                    </v-select>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn id="btnFilter" type="submit" color="primary" dark
                                        elevation="2">Filtrar</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-card class="table-card">
                    <v-card-title class="table-card-title d-flex justify-space-between">Lista de Credores
                        <div class="icon-container">
                            <v-spacer></v-spacer>
                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-icon icon v-on="on" @click="generateCSV()" size="large">
                                        mdi-microsoft-excel
                                    </v-icon>
                                </template>
                                <span>Gerar lista presença CSV</span>
                            </v-tooltip>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhuma lista de credores foi encontrada" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">

                            <template v-slot:[`item.name`]="{ item }">
                                <v-row class="align-center ">
                                    <v-col cols="12">
                                        <span><strong>{{ item.name.toUpperCase() }}</strong></span>
                                    </v-col>
                                </v-row>
                            </template>

                            <template v-slot:[`item.type`]="{ item }">
                                <v-row class="align-center ">
                                    <v-col cols="12">
                                        <span>
                                            {{ item.type | enum(dataset.creditorTypes, 'id', 'name') }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </template>

                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip :color="item.presence ? 'accent' : 'red'" label>
                                    <span>{{ item.presence ? 'SIM' : 'NÃO' }}</span>
                                </v-chip>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip bottom v-if="item.presence">
                                    <template #activator="{ on }">
                                        <v-icon icon v-on="on" @click="presenceUncheck(item)" size="large" color="red"
                                            class="me-2">
                                            mdi-account-cancel-outline
                                        </v-icon>
                                    </template>
                                    <span>Ausente</span>
                                </v-tooltip>

                                <v-tooltip bottom v-if="!item.presence">

                                    <template #activator="{ on }">
                                        <v-icon icon v-on="on" @click="presenceCheck(item)" size="large" color="green"
                                            class="me-2">
                                            mdi-account-check-outline
                                        </v-icon>
                                    </template>
                                    <span>Presente</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>

            <!-- <v-col cols="12" md="4">
                <v-card class="table-card">
                    <v-card-title class=" d-flex justify-space-between table-card-title ">Estatística

                    <div class="icon-container">
                            <v-spacer></v-spacer>
                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-icon icon v-on="on" @click="generatePDF()" size="large" class="me-2">
                                        mdi-file-pdf-box
                                    </v-icon>
                                </template>
                                <span>Gerar PDF</span>
                            </v-tooltip>
                        </div> 

                    </v-card-title>
                    <v-card-text>

                        <div class="summary-item">
                            Participante(s): <span class="summary-value">{{ dataList.data.length }}</span>
                            <br>
                        </div>
                        <div class="summary-item">
                            SIM: <span class="summary-value color-yes">{{ yesCount }}</span> <br>
                            Percentual SIM: <span class="summary-value color-yes" >{{ yesPercentage }} %</span>
                        </div>
                        <div class="summary-item">
                            NÃO: <span class="summary-value color-no">{{ noCount }}</span> <br>
                            Percentual NÃO: <span class="summary-value color-no">{{ noPercentage }} %</span>
                        </div>
                        <div class="summary-item">
                            NULO: <span class="summary-value color-null">{{ nullCount }}</span> <br>
                            Percentual NULO: <span class="summary-value color-null">{{ nullPercentage }} %</span>
                        </div>
                        <div class="summary-item">
                            PENDENTE: <span class="summary-value color-pending">{{ pendingCount }}</span> <br>
                            Percentual PENDENTE: <span class="summary-value color-pending">{{ pendingPercentage
                            }} %</span>
                        </div>
                    </v-card-text>

                </v-card>

            </v-col> -->

        </v-row>

    </v-container>
</template>

<script>

import Vue from "vue";

import presenceTypes from "@/common/data/presence-types.data"
import creditorTypes from "@/common/data/creditor-types.data"
import string from "@/common/util/string"

import object from "@/common/util/object"
import { cpf, cnpj } from 'cpf-cnpj-validator'

import axios from "axios"

export default {
    name: "GeneralMeetingPresence",

    data: () => ({

        page: {
            isNew: false,
            title: "",
        },
        menu: false,
        dataset: {
            breadcrumbItems: [],
            date: null,
            data: {},
            clients: [],
            filter: {
                name: "",
                presence: "",
            },
            generalMeetingId: null,
            presenceTypes: presenceTypes,
            creditorTypes: [ {id: "", name: "Todos"}, ...creditorTypes],
        },
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
        },

        eventStatus: [
            { id: 0, name: "NÃO" },
            { id: 1, name: "SIM" },
        ],
        dataList: {
            headers: [
                { text: "Nome", value: "name" },
                { text: "Presença", value: "status" },
                { text: "Classe", value: "type" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],

            loading: false,
            //count: 11,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
                pageText: '{0}-{1} de {2}',
            },
        },
    }),

    created() {
        this.dataset.generalMeetingId = this.$route.params.id;

        this.loadData()
    },

    computed: {
        computedDateFormatted() {
            return this.formatDate(this.dataset.data.date)
        },

        checkedCountComputed() {
            return this.dataList.data.filter(item => item.checked).length;
        },
        uncheckedCountComputed() {
            return this.dataList.data.filter(item => !item.checked).length;
        },
    },


    methods: {

        pageChanged(page) {
            this.dataList.options.page = page;
            this.filterSearch();
        },

        pageSizeChanged(size) {
            this.dataList.options.itemsPerPage = size;
            this.filterSearch(true);
        },

        goBack() {
            this.$router.replace("/agcs");
        },

        async loadData() {
            this.setPage();
            this.listBreadCrumbItems();

            await this.filterSearch();

            await this.loadAssembly();
        },

        setPage() {
            this.page.title = `Presença na Assembleia`;
        },

        formatDate(date) {
            if (!date) return null

            return this.$moment(date).format('DD/MM/YYYY')
        },
        parseDate(date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "AGC",
                disabled: false,
                to: "/agcs",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: `Presença na Assembleia`,
                disabled: true,
                exact: true,
            });
        },

        getColorByStatus(status) {
            switch (status) {
                case 0:
                    return 'warning'
                case 1:
                    return 'accent'
                case 2:
                    return 'error'
                default:
                    break;
            }
        },



        async filterSearch() {

            let search = Object.fromEntries(Object.entries(this.dataset.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : {};

            //     //search.general_meeting_id = id

            //     if(!search.presence && this.dataset.filter.presence === 0) search.presence = 0

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;

            try {
                const result = await axios.get('/api/admin/general-meetings/list-presence', { params: { page: this.dataList.options.page, limit: limit, search: search, general_meeting_id: this.dataset.generalMeetingId } });

                const resultContent = result.data.content

                this.dataList.data = resultContent.data

                this.dataList.count = resultContent.paging.total;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        buildPayload() {
            const data = {
                name: this.dataset.data.name,
                date: this.dataset.data.date,
                client: this.dataset.data.client_id,
                description: this.dataset.data.description,
            }

            return data;
        },

        async presenceCheck(item) {

            const index = this.dataList.data.findIndex(obj => obj.id === item.id);

            const presence = this.dataList.data[index].presence

            !presence ? this.dataList.data[index].presence = { status } : this.dataList.data[index].presence.status = status

            try {

                const response = await axios.post(`/api/admin/general-meetings/presence/${this.dataset.generalMeetingId}`, { user_creditor_id: item.id });

                this.$dialog.notify.info("Presença contabilizada com sucesso!", { position: "top-right", timeout: 2000 });

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },


        async presenceUncheck(item) {

            const index = this.dataList.data.findIndex(obj => obj.id === item.id);

            if (index !== -1) {
                this.dataList.data[index].presence = false;
            }

            try {

                const result = await axios.delete(`/api/admin/general-meetings/absence/${this.dataset.generalMeetingId}`, { data: {user_creditor_id: item.id }})

                this.$dialog.notify.info("Ausência contabilizada com sucesso!", { position: "top-right", timeout: 2000 });

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }

        },

        async loadAssembly() {

            try {
                const result = await axios.get(`/api/admin/general-meetings/${this.$route.params.id}`);

                const data = result.data.content;

                this.dataset.data = data;

                this.dataset.data.date = this.$moment(this.dataset.data.date).format("YYYY-MM-DD HH:mm")
                this.dataset.data.assemblyName = data.name
                this.dataset.data.clientName = data.client.name

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },


        async generateCSV() {
            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja exportar os dados para CSV?',
                title: 'Exportar dados',
                actions: {
                    false: 'Cancelar',
                    true: 'Exportar'
                }
            })

            if (!proceedAction) return

            const data = this.dataList.data

            if (data.length == 0) {
                this.$dialog.notify.error('Sem dados para gerar CSV!', {
                    position: 'top-right',
                    timeout: 2000
                })

                return
            }

            const newData = data.map(obj => ({
                'NOME CREDOR': obj.name.toUpperCase(),
                'EMAIL CREDOR': obj.email,
                'CPF/CNPJ': this.formatCpfCnpj(obj.registration), 
                'PRESENCA': obj.presence !== null ? 'SIM' : 'NAO'
            }))

            try {
                const header = Object.keys(newData[0])
                const csvContent = [header.join(';')]

                newData.forEach(item => {
                    const row = header
                        .map(key => {
                            const value = item[key]
                            return typeof value === 'object' && value !== null ? value.join(';') : value
                        })
                        .join(';')
                    csvContent.push(row)
                })

                const csv = csvContent.join('\r\n')

                const formattedDate = this.getFormattedDate();

                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.setAttribute('href', url)
                link.setAttribute('download', "lista-votacao" + '-' + this.dataset.data.assemblyName + "_" + formattedDate + '.csv');
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

                this.$dialog.notify.info('Arquivo CSV gerado com sucesso!', {
                    position: 'top-right',
                    timeout: 2000
                })
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        getFormattedDate() {
            const currentDate = new Date();
            const day = String(currentDate.getDate()).padStart(2, '0'); 
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const year = currentDate.getFullYear(); 

            return `${day}-${month}-${year}`;
        },

        formatCpfCnpj(value) {
            return value.length == 14 ? cnpj.format(value) : cpf.format(value);
        },

    },
};
</script>

<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}

.list-card {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
}

.summary-item {
    margin-bottom: 8px;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
}

.summary-value {
    font-weight: bold;
}

.color-yes {
    color: green;
}

.color-no {
    color: red;
}

.color-null {
    color: grey;
}

.color-pending {
    color: orange;
}
</style>