<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Analise em massa</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Incluir Lista',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn v-on="on" type="button" color="primary" elevation="0" @click="uploadFile"
                                    :loading="dataset.loading">Enviar</v-btn>
                            </template>
                            <span>Enviar arquivos</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn v-on="on" id="btnGoBack" type="button" color="primary" elevation="0" outlined
                                    to="/divergencias">Voltar</v-btn>
                            </template>
                            <span>Voltar</span>
                        </v-tooltip>
                    </div>
                </div>

            </v-col>
        </v-row>

        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Arquivos</v-card-title>

                                    <v-row>

                                        <v-col>
                                            <v-banner single-line outlined>
                                                <v-icon slot="icon" color="warning" size="36">mdi-alert-outline</v-icon>
                                                
                                                    O nome do(s) arquivo(s) deve(m) estar formatado(s) da seguinte forma: "cpf-numerocredor-status.extensão"
                                                
                                                <v-tooltip bottom>
                                                    <template #activator="{ on }">
                                                        <v-btn v-on="on" text @click="showDetails = !showDetails" color="primary" elevation="0" outlined dense style="height: 24px;">
                                                            {{ showDetails ? 'Menos Detalhes' : 'Mais Detalhes' }}
                                                        </v-btn>
                                                    </template>
                                                    <span>Informações adicionais</span>
                                                </v-tooltip>
                                            
                                                <div v-if="showDetails">
                                                    <ul>
                                                        <li><strong>CPF</strong>: 11 dígitos sem pontuação ou traço (ex: 12345678900)</li>
                                                        <li><strong>Credor</strong>: número identificador do credor no sistema (ex: 123)</li>
                                                        <li><strong>Status</strong>: "aprovado" ou "rejeitado"</li>
                                                        <li><strong>Extensão</strong>: ".csv", ".xlsx", ".docx", etc.</li>
                                                    </ul>
                                                    <br>
                                                    <p>Exemplos de nomes válidos:</p>
                                                    <ul>
                                                        <li>12345678900-123-aprovado.csv</li>
                                                        <li>09876543210-456-rejeitado.xlsx</li>
                                                    </ul>
                                                </div>
                                            </v-banner>
                                        </v-col>

                                    </v-row>
                                    <br>
                                <v-card-text>
                                    
                                    <v-row>
                                        <v-col cols="12">
                                            <div
                                                class="drop-area"
                                                @drop.prevent="onDrop($event)"
                                                @dragover.prevent="dragover = true"
                                                @dragenter.prevent="dragover = true"
                                                @dragleave.prevent="dragover = false"
                                                :class="{ 'grey lighten-2': dragover }"
                                                >
                                                <p v-if="uploadedFiles.length == 0" class="text-center">
                                                Arraste e solte seus arquivos aqui!
                                                </p>
                                                <v-virtual-scroll
                                                v-if="uploadedFiles.length > 0"
                                                :items="uploadedFiles"
                                                height="500"
                                                item-height="50"
                                                >
                                                <template v-slot:default="{ item }">
                                                    <v-list-item :key="item.name">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                        {{ item.name }}
                                                        <span class="ml-3 text--secondary">
                                                            {{ item.size }} bytes
                                                        </span>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-btn @click.stop="removeFile(item.name)" icon>
                                                        <v-icon> mdi-close-circle </v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                    </v-list-item>
                                                    <v-divider></v-divider>
                                                </template>
                                                </v-virtual-scroll>
            
                                            </div>
                                            <div class="file-count">
                                                Total de arquivo(s): <strong>{{ fileCount }}</strong>
                                            </div>
                                        </v-col>
                                    </v-row>
                               
                                    <v-row v-if="dataset.progress">
                                        <v-col cols="12">
                                            <v-progress-linear v-model="dataset.progress" color="green" height="25"
                                                reactive>
                                                <strong>{{ dataset.progress }} %</strong>
                                            </v-progress-linear>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

    </v-container>
</template>
  

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

Vue.use(VueLodash)

export default {
    name: "bulkAnalysis",
    props: {
    multiple: {
      type: Boolean,
      default: true,
    },
  },
    data() {
        return {
            dataset: {
                data: {},
                progress: null,
                loading: false,
            },
            formDataUpload: [],

            dataForm: {
                validForm: true,
            },
            currFiles: [],
            previousFiles: [],
            files: [],
            uploadedFiles: [],
            dragover: false,
            fileCount: 0,
            showDetails: false,
        }
    },

   
    watch: {
        files(val) {
            this.previousFiles = val
        }  
    },

    methods: {

        goBack() {
            this.$router.replace("/divergencias");
        },


        onDrop(e) {

            e.dataTransfer.files.forEach(file => {
                if (!this.fileExists(file.name)) {
                    this.uploadedFiles.push(file);
                }
            });

            this.updateFileCount();

        },

        fileExists(fileName) {
            return this.uploadedFiles.some(file => file.name === fileName);
        },

        removeFile(fileName) {
            const index = this.uploadedFiles.findIndex(
                file => file.name === fileName
            );
            if (index > -1) this.uploadedFiles.splice(index, 1);

            this.updateFileCount();
        },

        updateFileCount() {
        this.fileCount = this.uploadedFiles.length;
        },

        async uploadFile() {

            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja enviar a lista de arquivos para analise?',
                title: 'Enviar arquivos',
                actions: {
                    false: 'Cancelar',
                    true: 'Enviar'
                }
            })

            if (!proceedAction) return;


            if(this.uploadedFiles.length == 0){
                this.$dialog.notify.error("Nenhum arquivo selecionado para envio!", { position: "top-right", timeout: 3000 });
                return
            }
            
            this.dataset.loading = true;
            this.dataset.progress = 0;

            try {

                const payload = this.buildPayload()

                const response = await axios.post('/api/admin/bulk-reviews', payload, {
                    onUploadProgress: (event) => {
                        this.dataset.progress = Math.round((100 * event.loaded) / event.total);
                    }
                })

                this.$dialog.notify.info("Arquivo(s) enviado(s) com sucesso!", { position: "top-right", timeout: 3000 });

                this.goBack()

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
                this.dataset.progress = 0;
            }
        },

        buildPayload() {

            this.formDataUpload = new FormData();

            for (let i = 0; i < this.uploadedFiles.length; i++) {
                this.formDataUpload.append(`files[${i}]`, this.uploadedFiles[i]);
            }

            return this.formDataUpload;
        }

    }

};
</script>


<style>

.drop-area {
    border: 2px dashed #757575;
    padding: 20px;
    text-align: left;
}

.file-count {
    margin-top: 10px;
    font-size: 14px;
    text-align: right;
}
</style>