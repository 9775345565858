<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="add"
                            :loading="dataForm.addLoading" v-if="page.isNew">Salvar</v-btn>
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="update"
                            :loading="dataForm.updateLoading" v-if="!page.isNew">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined to="/admins">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <!-- <v-row v-if="dataset.data.type !== 3 && !page.isNew">
            <v-col :cols="12">
                <div class="title-container">
                    <div class="button-group">
                        <v-btn :color="dataset.isViewUnits ? 'mediumGray' : 'secondary'" outlined
                            @click="dataset.isViewUnits = false">
                            Detalhes

                            <v-icon right>mdi-format-list-text</v-icon>

                        </v-btn>
                        <v-btn outlined @click="dataset.isViewUnits = true"
                            :color="!dataset.isViewUnits ? 'mediumGray' : 'secondary'">
                            Unidades
                            <v-icon right>mdi-source-branch</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <unit-list v-if="dataset.isViewUnits && !page.isNew && dataset.data.type !== 3"
            :client="dataset.data.id"></unit-list> -->
        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12" sm="8">
                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title>Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense id="name" label="Nome" v-model="dataset.data.name" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select dense id="type" :items="dataset.adminProfiles" label="Perfil"
                                                v-model="dataset.data.profile" item-value="id" item-text="name" outlined
                                                hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field dense id="email" type="email" label="E-mail"
                                                v-model="dataset.data.email" :rules="[$rules.requried, $rules.email]"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-row>
                        <v-col cols="12">
                            <v-card color="theme-card">
                                <v-card-title class="card-group-title">Eventos</v-card-title>
                                <!-- <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field id="urlLogin" label="URL Login" v-model="dataset.data.themeSlug"
                                                :rules="[$rules.slug]" persistent-hint outlined>
                                            </v-text-field>
                                            <v-file-input accept="image/png, image/jpeg" prepend-icon="" outlined counter
                                                :rules="[$rules.fileSize(4)]" hint="O arquivo não pode ser superior a 4MB"
                                                hide-details="auto" label="Logotipo" :clearable="false"
                                                @change="onLogoSelected" ref="logoFile">
                                            </v-file-input>
                                        </v-col>
                                    </v-row>
                                </v-card-text> -->
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
  
<script>
import adminProfiles from "@/common/data/admin-profiles.data"
import string from "@/common/util/string"

import axios from "axios"

export default {
    name: "AdminDetails",

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            adminProfiles: adminProfiles,
            breadcrumbItems: [],
            data: {},
        },
        dataForm: {
            files: {},
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            created: null,
        },
    }),

    created() {
        this.setPage();
        this.listBreadCrumbItems();
        this.load();
    },

    methods: {
        goBack() {
            this.$router.replace("/admins");
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = this.page.isNew ? "Novo Admin" : "Detalhes do admin";
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Administradores",
                disabled: false,
                to: "/admins",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.isNew ? "Incluir admin" : "Detalhes do admin",
                disabled: true,
                exact: true,
            });
        },

        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/api/admin/find/${this.$route.params.id}`);

                this.dataset.data = result.data.content;

                this.page.title = this.dataset.data.name
            } catch (error) {
                console.log('error ', error)
            }
        },

        async add() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o admin?',
                title: 'Incluir admin',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/api/admin/create', payload);

                this.$dialog.notify.info("Admin salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar o admin?',
                title: 'Atualizar admin',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/api/admin/update/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Admin salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        buildPayload() {

            const data = {
                name: this.dataset.data.name,
                email: this.dataset.data.email,
                profile: this.dataset.data.profile
            }

            return data;
        },

    },
};
</script>
  
<style lang="scss">
.card-group-title {
    text-transform: capitalize;
    font-weight: normal;
    font-size: 22px;
    color: #2c3153;
}

.theme-card {

    .card-section-title {
        color: var(--v-mediumGray-base) !important;
        text-transform: capitalize !important;
        // font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }

    background: var(--v-gray-base) !important;
}

.color-row {
    display: flex;
    flex-direction: row;

    .color-column {
        padding-right: 24px;

        .color-picker {
            height: 55px;
            width: 55px;
        }
    }

    .description-column {
        display: flex;
        flex-direction: column;

        .color-title {
            color: #2c3153;
            font-size: 14px;
            font-weight: 700;
        }
    }
}

input[type="color"] {
    height: 32px;
    width: 32px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    cursor: pointer;
}

.v-text-field__prefix {
    padding-right: 0px !important;
}

.link-icon {
    cursor: pointer;
}
</style>