<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Banner de Aviso</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Banner de Aviso',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnNew" type="button" color="primary" elevation="0"
                            to="/banner-aviso/novo">Incluir</v-btn>
                    </div>
                </div>

            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="load()">
                            <v-row>
                                <v-col>
                                    <v-text-field dense id="name" label="Nome" v-model="dataList.filter.name"
                                        hide-details outlined></v-text-field>
                                </v-col>
                                <!-- <v-col>
                                    <v-text-field dense id="email" label="Email" v-model="dataList.filter.email" hide-details
                                        outlined></v-text-field>   
                                </v-col> -->
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn id="btnFilter" type="submit" color="primary" dark
                                        elevation="2">Filtrar</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="table-card">
                    <v-card-title class="table-card-title">Banner de Aviso</v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhum banner foi encontrado" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">

                            <template v-slot:[`item.name`]="{ item }">
                                {{ item.name && item.name ? item.name : 'N/A' }}
                            </template>

                            <template v-slot:[`item.start_at`]="{ item }">
                                <span v-if="item.start_at">
                                    {{ item.start_at | defaultDate("DD/MM/YYYY") }}
                                </span>
                            </template>

                            <template v-slot:[`item.end_at`]="{ item }">
                                <span v-if="item.end_at">
                                    {{ item.end_at | defaultDate("DD/MM/YYYY") }}
                                </span>
                            </template>

                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip :color="getColorByStatus(item.status)" label text-color="white">
                                    {{ getStatusText(item.status) }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-row align="center">
                                    <v-icon :disabled="!$check('users/1', ['PATCH'])" @click="viewItem(item)"
                                        size="large" class="me-2">
                                        mdi-pencil-outline
                                    </v-icon>

                                    <v-icon :disabled="!$check('users/1', ['DELETE'])" @click="deleteItem(item)"
                                        size="large" class="me-2">
                                        mdi-delete
                                    </v-icon>
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import object from "@/common/util/object"

Vue.use(VueLodash)

export default {
    name: "EmployeeList",

    data: () => ({
        dataset: {
            data: {},
            eventStatus: [{ id: "0", name: "Inativo" }, { id: "1", name: "Ativo" }],
        },
        dataList: {
            filter: {
                name: "",
                email: "",
            },
            headers: [
                { text: "Nome", value: "name" },
                { text: "Inicio", value: "start_at" },
                { text: "Fim", value: "end_at" },
                { text: "Status", value: "status" },
                { text: "Criado em", value: "created_at" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                size: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
                pageText: '{0}-{1} de {2}',
            }
        },
    }),

    created() {
        this.load();
    },

    methods: {

        pageChanged(page) {
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.options.size = size;
            this.load(true);
        },

        getColorByStatus(status) {
            switch (status) {
                case 0:
                    return 'red'
                case 1:
                    return 'accent'
                default:
                    break;
            }
        },

        getStatusText(status) {
            return status === 1 ? 'Ativo' : 'Inativo';
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },

        async load() {

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = this.dataList.options.size;

            try {
                const result = await axios.get('/api/admin/banners', { params: { page: this.dataList.options.page, limit: limit, search: search } });

                this.dataList.data = result.data.content.data;
                this.dataList.count = result.data.content.paging.total;

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        viewItem(item) {
            this.$router.push(`banner-aviso/${item.id}`);
        },

        async deleteItem(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Tem certeza que deseja continuar?',
                title: 'Excluir banner',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true

            try {

                const result = await axios.delete(`/api/admin/banners/${item.id}`)

                this.$dialog.notify.info("Banner de aviso excluído com sucesso!", { position: "top-right", timeout: 2000 });

                this.load()

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }

        },
    },
};
</script>