const data = [
  // { key: 'home-most-consulted-processes-title', name: "Página inicial (Processos)" },
  // { key: 'home-team', name: "Página inicial (Nossa equipe)" },
  // { key: 'team-title', name: "Nossa equipe (Título)" },
  { key: 'resume-description-user-anonymous', name: "Área do Credor > Resumo de Casos (Usuário deslogado)" },
  { key: 'create-user-event-anonymous', name: "Fomulário Habilitações/Divergências (Usuário deslogado)" },
  { key: 'create-user-event-logged', name: "Fomulário Habilitações/Divergências (Usuário logado)" },
  { key: 'user-event-anonymous', name: "Habilitações/Divergências (Usuário deslogado)" },
  { key: 'user-event-logged', name: "Habilitações/Divergências (Usuário logado)" },
  { key: 'contact-description', name: "Contato (Conteúdo)" },
  
  { key: 'judicial-recovery-description', name: "Casos > Recuperação Judicial (Introdução)" },
  { key: 'bankruptcies-description', name: "Casos > Falências (Introduçao)" },
  { key: 'judicial-liquidation-description', name: "Casos > Liquidação Judicial (Introduçao)" },
  { key: 'civil-insolvency-description', name: "Casos > Insolvência Civil (Introdução)" },
  
  { key: 'general-meeting-description', name: "Assembleia de Credores (Introdução)" },
  { key: 'faq-description', name: "Dúvidas (Introdução)" },
  { key: 'documents-template-description', name: "Modelos de Documentos (Introdução)" },
  
  // { key: 'bankruptcies-title', name: "Casos > Falências (Título)" },
  // { key: 'judicial-settlement-description', name: "Casos > Liquidação Judicial (Descrição)" },
  // { key: 'judicial-settlement-title', name: "Casos > Liquidação Judicial (Título)" },
  // { key: 'civil-insolvency-title', name: "Casos > Insolvência Civil (Título)" },
  
];

export default [...data];
