<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">QGC</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'QGC',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn v-on="on" id="btnNew" type="button" color="primary" elevation="0"
                                    to="/qgc/novo">Incluir
                                </v-btn>
                            </template>
                            <span>Adicionar</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="load()">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field dense label="Nome" v-model="dataList.filter.name" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field dense label="E-mail" v-model="dataList.filter.email" hide-details
                                        outlined></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>    
                                <v-col cols="12" sm="6">
                                    <v-text-field dense label="CPF" v-model="dataList.filter.registration" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">   
                                    <v-autocomplete dense :items="dataset.clients" label="Caso" v-model="dataList.filter.client" 
                                    item-value="id" item-text="name" outlined hide-details="auto"
                                    no-data-text="Nenhum caso encontrado">
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-tooltip bottom>
                                        <template #activator="{ on }">
                                            <v-btn 
                                                v-on="on" id="btnFilter" type="submit" color="primary" dark elevation="2">Filtrar
                                            </v-btn>
                                        </template>
                                        <span>Filtrar dados</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="table-card">
                    <v-card-title class="table-card-title">Credores
                        <v-spacer></v-spacer>
                        <div class="icon-container">
                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-icon icon v-on="on" @click="load(true)" size="large">
                                        mdi-microsoft-excel
                                    </v-icon>
                                </template>
                                <span>Gerar CSV</span>
                            </v-tooltip>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhum credor foi encontrado" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">
                            <template v-slot:[`item.name`]="{ item }">
                                <v-avatar :size="30">
                                    <img :src="'https://ui-avatars.com/api/?name=' + item.name" />
                                </v-avatar>
                                <span class="ml-4 list-item-title">{{ item.name }}</span><br />
                            </template>

                            <template v-slot:[`item.client.name`]="{ item }">
                                {{ truncateText(item.client.name, 30) }}
                            </template>
                          
                            <template v-slot:[`item.registration`]="{ item }">
                                {{ formatCpfCnpj(item.registration) }}
                            </template>

                            <template v-slot:[`item.type`]="{ item }">
                                <v-chip label text-color="primary">
                                    {{ getTypeName(item.type) }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.amount`]="{ item }">
                                R$ {{ item.amount | currency }}
                            </template>

                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon :disabled="!$check('User', 'PATCH')" @click="viewItem(item)" size="large"
                                    class="me-2">
                                    mdi-pencil-outline
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';
import { cpf, cnpj } from 'cpf-cnpj-validator'
import creditorTypes from "@/common/data/creditor-types.data"

import object from "@/common/util/object"

import userTypes from "@/common/data/user-types.data";

Vue.use(VueLodash)

export default {
    name: "UserList",

    data: () => ({
        dataset: {
           // status: [{ id: "", name: "Todos" }, ...status],
            userTypes: userTypes,
            clients: [],
            data: {},
            creditorTypes: creditorTypes
        },

        dataList: {
            filter: {
                name: "",
                client: "",
                email: "",
                registration: ""
            },
            headers: [
                { text: "Nome", value: "name" },
                { text: "E-mail", value: "email" },
                { text: "CPF/CNPJ", value: "registration" },
                { text: "Caso", value: "client.name" },
                { text: "Classe", value: "type" },
                { text: "Valor", value: "amount" },
                { text: "Criado em", value: "created_at" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            
            loading: false,
            count: 0,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
                pageText: '{0}-{1} de {2}',
            }
        },
    }),

    created() {
        this.load();
        this.loadClients();
    },

    methods: {

        pageChanged(page) {
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.options.itemsPerPage = size;
            this.load();
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },

        getTypeName(typeId) {
            const type = this.dataset.creditorTypes.find(s => s.id === typeId);
            return type.name ? type.name : 'N/A';
        },

        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.slice(0, maxLength) + '...';
            }
            return text;
        },

        formatCpfCnpj(value) {
            return value.length == 14 ? cnpj.format(value) : cpf.format(value);
        },

        async load(exportCsv) {

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v || v === 0))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = exportCsv ? 1000000 : (this.dataList.options.itemsPerPage || 10);

            var start = (exportCsv !== undefined && exportCsv) ? 1 : this.dataList.options.page
           
            try {
                
                const result = await axios.get('/api/admin/users-creditors', { params: { page: start , limit: limit, search: search } });

                if(!exportCsv) {
                    this.dataList.data = result.data.content.data;
                    this.dataList.count = result.data.content.paging.total;
                    
                    return;
                }

                this.generateCSV(result.data.content.data)

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async loadClients() {

            try {
                const result = await axios.get(`/api/clients`, { params: { start: 1, limit: 100000, search: {} } });

                const data = result.data.content.data
                
                data.sort((a, b) => a.name.localeCompare(b.name));
                
                this.dataset.clients = [{ id: "", name: "Todos " }, ...data];//[{"name": "Todos"}, ...data];

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        viewItem(item) {
            this.$router.push(`qgc/${item.id}`);
        },


        async generateCSV(data) {

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja exportar os dados para CSV?',
                title: 'Exportar dados',
                actions: {
                    false: 'Cancelar',
                    true: 'Exportar'
                }
            })

            if (!proceedAction) return;

            if (data.length == 0) {
                this.$dialog.notify.error("Sem dados para gerar arquivo CSV!", { position: "top-right", timeout: 2000 });

                return;
            }

            const newData = data.map(obj => ({
                "NOME CREDOR": obj.name,
                "CPF/CNPJ": this.formatCpfCnpj(obj.registration),
                "E-MAIL": obj.email,
                "CASO": obj.client.name,
                "VALOR": obj.amount ? obj.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "N/A",
                "CLASSE": obj.type === 1 ? "Classe I - Trabalhista" : 
                        (obj.type === 2 ? "Classe II - Garantia Real" : 
                        (obj.type === 3 ? "Classe III - Quirografários" : 
                        (obj.type === 4 ? "Classe IV - ME/EPP" : 
                        (obj.type === 5 ? "Extraconcursais" : "N/A")))),
                "CRIADO EM": obj.created_at,

            }));

            try{

                const header = Object.keys(newData[0]);
                const csvContent = [header.join(';')];

                newData.forEach(item => {
                    const row = header.map(key => {
                        const value = item[key];
                        return typeof value === 'object' && value !== null ? value.join(';') : value;
                    }).join(';');
                    csvContent.push(row);
                });

                const csv = csvContent.join('\r\n');

                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', 'QGC.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                this.$dialog.notify.info("Arquivo CSV gerado com sucesso!", { position: "top-right", timeout: 2000 });

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

    },
};
</script>