<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="add"
                            :loading="dataForm.addLoading" v-if="page.isNew">Salvar</v-btn>
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="update"
                            :loading="dataForm.updateLoading" v-if="!page.isNew">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined to="/textos-automaticos">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <!-- <v-row v-if="dataset.data.type !== 3 && !page.isNew">
            <v-col :cols="12">
                <div class="title-container">
                    <div class="button-group">
                        <v-btn :color="dataset.isViewUnits ? 'mediumGray' : 'secondary'" outlined
                            @click="dataset.isViewUnits = false">
                            Detalhes

                            <v-icon right>mdi-format-list-text</v-icon>

                        </v-btn>
                        <v-btn outlined @click="dataset.isViewUnits = true"
                            :color="!dataset.isViewUnits ? 'mediumGray' : 'secondary'">
                            Unidades
                            <v-icon right>mdi-source-branch</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <unit-list v-if="dataset.isViewUnits && !page.isNew && dataset.data.type !== 3"
            :client="dataset.data.id"></unit-list> -->
        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-title>Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field dense id="name" label="Nome" v-model="dataset.data.name" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <p class="font-weight-bold">Texto curto</p>
                                            <tiptap-vuetify dense v-model="dataset.data.small_description"
                                                :rules="[$rules.required]" :extensions="extensions" hide-details="auto" />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <p class="font-weight-bold">Texto longo</p>
                                            <tiptap-vuetify dense v-model="dataset.data.description" :rules="[$rules.required]"
                                                :extensions="extensions" hide-details="auto" />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>
  
<script>
import string from "@/common/util/string"

import axios from "axios"

import {
    TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History, Table, TableCell, TableHeader,
    TableRow, TodoList, TodoItem
} from 'tiptap-vuetify'

export default {
    name: "DynamicTextDetails",

    components: { 'tiptap-vuetify': TiptapVuetify },

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            breadcrumbItems: [],
            data: {
                small_description: '',
                description: ''
            },
        },
        dataForm: {
            files: {},
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            created: null,
        },
        extensions: [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList,
            [Heading, {
                options: {
                    levels: [1, 2, 3]
                }
            }],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak,
            [Table, {
                options: {
                    resizable: true
                }
            }],
            TableCell,
            TableHeader,
            TableRow,
        ],
    }),

    created() {
        this.setPage();
        this.listBreadCrumbItems();
        this.load();
    },

    methods: {
        goBack() {
            this.$router.replace("/textos-automaticos");
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = this.page.isNew ? "Novo texto" : "Detalhes do texto";
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Textos automáticos",
                disabled: false,
                to: "/textos-automaticos",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.isNew ? "Incluir texto" : "Detalhes do texto",
                disabled: true,
                exact: true,
            });
        },

        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/api/admin/dynamic-texts/${this.$route.params.id}`);

                this.dataset.data = result.data.content;

                this.page.title = this.dataset.data.name
            } catch (error) {
                console.log('error ', error)
            }
        },

        async add() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o texto?',
                title: 'Incluir texto',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/api/admin/dynamic-texts', payload);

                this.$dialog.notify.info("Texto salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar o texto?',
                title: 'Atualizar texto',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/api/admin/dynamic-texts/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Texto salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        buildPayload() {

            const data = {
                name: this.dataset.data.name,
                smallDescription: this.dataset.data.small_description,
                description: this.dataset.data.description
            }

            return data;
        },

    },
};
</script>
  
<style lang="scss">
.card-group-title {
    text-transform: capitalize;
    font-weight: normal;
    font-size: 22px;
    color: #2c3153;
}

.theme-card {

    .card-section-title {
        color: var(--v-mediumGray-base) !important;
        text-transform: capitalize !important;
        // font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }

    background: var(--v-gray-base) !important;
}

.color-row {
    display: flex;
    flex-direction: row;

    .color-column {
        padding-right: 24px;

        .color-picker {
            height: 55px;
            width: 55px;
        }
    }

    .description-column {
        display: flex;
        flex-direction: column;

        .color-title {
            color: #2c3153;
            font-size: 14px;
            font-weight: 700;
        }
    }
}

input[type="color"] {
    height: 32px;
    width: 32px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    cursor: pointer;
}

.v-text-field__prefix {
    padding-right: 0px !important;
}

.link-icon {
    cursor: pointer;
}
</style>