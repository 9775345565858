<template>
    <v-dialog v-model="dialog.show" persistent  max-width="800px">
       
            
            <v-card>
                <!-- <v-card-title class="title-page ">Associação de Credor</v-card-title> -->
                <v-card-text>
            <v-row>
                <v-col :cols="12">
                    <br>
               
                    <span id="title" class="page-title">  Associação Credor</span>
    
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="filter-card">
                        <v-card-title class="filter-card-title">Filtro</v-card-title>
                        <v-card-text>
                            <v-form @submit.prevent="load()">
                                <v-row>
                                    <!-- <v-col>   
                                        <v-autocomplete dense :items="dataset.clients" label="Caso" v-model="dataList.filter.client"
                                            item-value="id" item-text="name" outlined hide-details="auto" no-data-text="Nenhum caso encontrado">
                                        </v-autocomplete>
                                    </v-col> -->
                                    <v-col>
                                        <v-text-field dense id="name" label="Nome" v-model="dataList.filter.name" hide-details
                                            outlined></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field dense id="registration" label="CPF" v-model="dataList.filter.registration" hide-details
                                            outlined></v-text-field>
                                    </v-col>
                                    <!-- <v-col>
                                        <v-text-field dense id="email" label="Email" v-model="dataList.filter.email" hide-details
                                            outlined></v-text-field>
                                    </v-col> -->
                                    
                                     <!-- <v-col>   
                                        <v-autocomplete dense :items="dataset.clients" label="Caso" v-model="dataList.filter.client"
                                            item-value="id" item-text="name" outlined hide-details="auto" no-data-text="Nenhum caso encontrado">
                                        </v-autocomplete>
                                    </v-col> -->
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-tooltip bottom>
                                            <template #activator="{ on }">  
                                                <v-btn v-on="on" id="btnFilter" type="submit" color="primary" dark elevation="2"
                                                    >Filtrar</v-btn>
                                            </template>
                                            <span>Filtrar dados selecionados</span>
                                        </v-tooltip>     
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="table-card">
                        <v-card-title class="table-card-title">Credores</v-card-title>
                        <v-card-text>
                            <v-data-table
                                :headers="dataList.headers"
                                :items="dataList.data"
                                :server-items-length="dataList.count"
                                @update:items-per-page="pageSizeChanged"
                                @update:page="pageChanged"
                                :disable-sort="true"
                                :loading="dataList.loading"
                                no-data-text="Nenhum credor foi encontrado"
                                loading-text="Carregando dados..."
                                class="app-table hover-table"
                                :options.sync="dataList.options"
                                :footer-props="dataList.footerOptions"
                            >
                                <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                                </template>

                                <template v-slot:[`item.client`]="{ item }">
                                <strong> {{ item.client && item.client.name ? item.client.name : 'N/A' }}</strong>
                                </template>

                                <template v-slot:[`item.actions`]="{ item }">
                                <v-icon :disabled="!$check('Document', 'PATCH')" @click="viewItem(item)" size="large" class="me-2">
                                    mdi-pencil-outline
                                </v-icon>
                                <v-icon :disabled="!$check('Document', 'DELETE')" @click="deleteItem(item)" size="large">
                                    mdi-delete-outline
                                </v-icon>
                                </template>
                                <template v-slot:[`item.checkbox`]="{ item }">
                                <v-checkbox v-model="selectedItem" :value="item" @change="handleCheckboxChange(item)"></v-checkbox>
                                </template>
                            </v-data-table>
                        </v-card-text>

                        <v-divider></v-divider>
                        <v-card-actions>
                            <div class="button">
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-btn v-on="on" @click="associateSelectedCreditor()" color="primary" dark elevation="2" :loading="dataset.loading">
                                            Associar
                                        </v-btn>
                                    </template>
                                    <span>Associar credor selecionado</span>
                                </v-tooltip>
                            </div>
                            <div>
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-btn v-on="on" color="grey" text @click.native="goBack()">Cancelar</v-btn>
                                    </template>
                                    <span>Fechar</span>
                                </v-tooltip>
                            </div>
                        </v-card-actions>

                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
        </v-card>
      
    </v-dialog>
  </template>

<script>
import 'moment/locale/pt-br';

//import { eventHub } from "@/main";

import axios from "axios"

import string from "@/common/util/string"
import object from "@/common/util/object"
import _ from 'lodash';

export default {

    data: () => ({
        selectedItem: null,
        dataset: {
            data: {},
            loading: false,
            clients: [{ id: null, name: "Todos: " }]
        },
        dataList: {
            filter: {
                name: "",
                email: "",
                registration: "",
                client: ""
            },
            headers: [
                { text: '', value: 'checkbox', sortable: false },
                { text: "Nome", value: "name" },
                { text: "Caso", value: "client" },
               // { text: 'Tipo', value: "type" },
                { text: "Criado em", value: "created_at" },
               //{ text: 'Ações', value: 'actions', sortable: false },
               
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                size: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
                pageText: '{0}-{1} de {2}',
            }
        },

        dialog: {
            show: false,
            resolve: null,
            reject: null,
        },
    }),

    created() {
      //  this.load();
        this.loadClients();
    },


    methods: {

        handleCheckboxChange(selectedItem) {
            this.dataList.data.forEach(item => {
                if (item !== selectedItem) {
                item.selected = false;
                }
            });
        },
        

        open() {
            this.dialog.show = true;
                        
            return new Promise((resolve, reject) => {
                this.dialog.resolve = resolve;
                this.dialog.reject = reject;
            });
            
        },

        close() {
            this.dialog.resolve(this.selectedItem)

            this.dataList.data = [];
            
            this.dialog.show = false;
        },

        goBack() {
            this.dataset.data = {};
            this.close();
        },

        setData(data) {
            console.log('set data ', data)
            this.dataset.data = data;
        },

        pageChanged(page) {            
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {    
            this.dataList.options.size = size;
            this.load(true);
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },


        async loadClients() {

            try {
                const result = await axios.get(`/api/clients`, { params: { start: 1, limit: 100000, search: {} } });

                const data = result.data.content.data
                
                data.sort((a, b) => a.name.localeCompare(b.name));
                
                this.dataset.clients = [{ id: "", name: "Todos: " }, ...data];

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },


        async load() {


            console.log("entrou em pesquisar!!!")

    
            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = this.dataList.options.itemsPerPage;

            try {
                const result = await axios.get('/api/admin/users', { params: { page: this.dataList.options.page, limit: limit, search: search } });

                this.dataList.data = result.data.content.data;
                this.dataList.count = result.data.content.paging.total;

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },



        async associateSelectedCreditor() {

           // console.log("Executou associação")

            console.log("Item Associado: ", this.selectedItem)

            
            if (!this.selectedItem) {
                this.$dialog.notify.error("Necessário selecionar um credor!", { position: "top-right", timeout: 5000 });
                return; 
            }
           
            this.goBack()
       
        //     if (this.dataset.assemblyCommentText.length > 2000) {
        //         this.$dialog.notify.error("O texto excedeu o limite de 2000 caracteres", { position: "top-right", timeout: 5000 });
        //         return; 
        //     }
           
        //     const id = this.dataset.data.id

        //     try {

        //         const response = await axios.patch(`/api/admin/general-meetings/${id}`, {comments: this.dataset.assemblyCommentText}, {
                    
        //         })

        //         this.$dialog.notify.info("Informações adicionadas com sucesso!", { position: "top-right", timeout: 3000 });

        //         this.goBack()

        //     } catch (error) {
        //         this.$dialog.notify.error(this.$codes(error), {
        //             position: 'top-right',
        //             timeout: 2000
        //         })
        //     } 

        },
        
    }
};
</script>

<style lang="scss">

.title-page {
    color: var(--v-primary-base);
    //font-family: "Montserrat" !important;
    font-size: 28px !important;
    //font-style: bold !important;
    font-weight: 600 !important;
    line-height: 20px !important;
    text-align: center !important;
}

p {
    text-align: justify;
}

.editor-container {
  height: 500px; 
  overflow-y: auto; 
}


</style>
