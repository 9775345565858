<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn type="button" elevation="0" color="primary" @click=updateUser()>Atualizar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined
                            to="/credores">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="dataset.data.account_verified_at === '0000-00-00 00:00:00'">
            <v-col>
                <v-banner single-line outlined>
                    <v-icon slot="icon" color="warning" size="36">
                        mdi-alert-outline
                    </v-icon>
                    Este credor ainda não verificou o cadastro

                    <template v-slot:actions>
                        <v-btn color="primary" text @click="verifyAccount()">
                            VERIFICAR AGORA
                        </v-btn>
                    </template>
                </v-banner>
            </v-col>
        </v-row>
        <v-row v-if="dataset.data.account_approved_at !== '0000-00-00 00:00:00' && dataset.data.type === 1">
            <v-col>
                <v-banner single-line outlined>
                    <v-icon slot="icon" color="accent" size="36">
                        mdi-check-circle-outline
                    </v-icon>
                    Este credor está incluso na lista de credores
                </v-banner>
            </v-col>
        </v-row>
        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense id="name" label="Nome completo" v-model="dataset.data.name"
                                                required :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense label="E-mail" v-model="dataset.data.email" required
                                                outlined hide-details="auto" :disabled="dataset.data.type === 3">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense id="registration" label="CPF/CNPJ"
                                                v-model="dataset.data.registration" required @input="formatRegistration()"
                                                    :rules="[$rules.required, (dataset.data.registration && dataset.data.registration.length < 15 ? $rules.cpf : $rules.cnpj)]"
                                                    outlined hide-details="auto">
                                                   
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <span class="text-uppercase font-weight-bold primary--text" color="primary">TIPO
                                                DE CREDOR: </span>{{ dataset.data.type | enum(dataset.userTypes, "id",
                                                    "name") }}
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

        <v-row>
            <v-col cols="12">
                <v-card class="card-form-outlined" color="gray">
                    <v-card-title class="card-group-title">
                        <div class="title-container">
                            <div class="title-block">
                                <span id="title" class="table-card-title">Ações</span>
                            </div>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-btn type="button" elevation="0" color="primary" outlined :href="dataset.data.documentUrl"
                                    target="_blank">Visualizar
                                    documento</v-btn>
                                <!-- <v-btn type="button" elevation="0" color="primary" outlined >Aprovar</v-btn> -->
                                <v-btn 
                                    type="button" 
                                    elevation="0" 
                                    :color="dataset.data.status === 0 ? 'error' : 'accent'" 
                                    @click=blockUser() 
                                    class="ml-5">
                                    {{ dataset.data.status === 0 ? 'Bloquear' : 'Desbloquear' }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card class="card-form-outlined" color="gray">
                    <v-card-title class="card-group-title">
                        <div class="title-container">
                            <div class="title-block">
                                <span id="title" class="table-card-title">Movimentações</span>
                            </div>
                        </div>
                    </v-card-title>
                    <v-card-text v-for="(item, index) in dataset.events" :key="index">
                        <v-row>
                            <v-col>
                                <span class="text-uppercase font-weight-bold primary--text" color="primary">{{
                                    getTitleByTypeEvent(item) }}:</span> {{ getMessageByTypeEvent(item) }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text v-if="!dataset.events.length">
                        <v-row>
                            <v-col cols="12">
                                Nenhuma movimentação encontrada
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
  
<script>
import states from "@/common/data/states.data"
import sanitizers from '@/common/util/string.js'
import { cpf, cnpj } from 'cpf-cnpj-validator'

import userTypes from "@/common/data/user-types.data"

import axios from "axios"

export default {
    name: "UserDetails",

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            userTypes: userTypes,
            breadcrumbItems: [],
            states: states,
            data: {
                registration: ""
            },
            events: []
        },
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            showPassword: false,
            showPasswordConfirmation: false,
        },
    }),

    created() {
        this.setPage();
        this.listBreadCrumbItems();
        this.loadData()
    },

    methods: {
        goBack() {
            this.$router.replace("/credores");
        },

        getMessageByTypeEvent(item) {
            let result = '';

            switch (item.type) {
                case 'login-account':
                    result = `Credor acessou o sistema no dia ${this.$moment(item.created_at).format('DD/MM/YYYY HH:mm')}`;
                    break;

                case 'approve-user':
                    result = `Credor foi incluído na lista no dia ${this.$moment(item.created_at).format('DD/MM/YYYY HH:mm')}`;
                    break;

                case 'accept-value':
                    result = `Credor aceitou o crédito no valor de R$ ${this.dataset.data.requested_value} no dia ${this.$moment(item.created_at).format('DD/MM/YYYY HH:mm')}`;
                    break;

                case 'verify-account':
                    result = `Credor verificou a conta no dia ${this.$moment(item.created_at).format('DD/MM/YYYY HH:mm')}`;
                    break;

                case 'register-user':
                    result = `Credor criou a conta no dia ${this.$moment(item.created_at).format('DD/MM/YYYY HH:mm')}`;
                    break;

                case 'create-event':
                    result = `Credor registrou uma divergência no dia ${this.$moment(item.created_at).format('DD/MM/YYYY HH:mm')}`;
                    break;

                default:
                    break;
            }

            return result;
        },

        getTitleByTypeEvent(item) {
            let result = '';

            switch (item.type) {
                case 'login-account':
                    result = `acesso`;
                    break;

                case 'approve-user':
                    result = `LISTA`;
                    break;

                case 'accept-value':
                    result = `CRÉDITO`;
                    break;

                case 'verify-account':
                    result = `verificação`;
                    break;

                case 'register-user':
                    result = `cadastro`;
                    break;

                case 'create-event':
                    result = `divergência`;
                    break;

                default:
                    break;
            }

            return result;
        },

        async loadData() {
            await this.load();
            await this.loadEvents();
        },

        formatCpfCnpj(value) {
            return value.length == 14 ? cnpj.format(value) : cpf.format(value);
        },

        formatRegistration() {
            this.dataset.data.registration = this.formatCpfCnpj(this.dataset.data.registration);
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = "Detalhes do credor";
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Credores",
                disabled: false,
                to: "/credores",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: "Detalhes do credor",
                disabled: true,
                exact: true,
            });
        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar o credor?',
                title: 'Atualizar credor',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/users/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Credor salvo com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        async approveUser() {
            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja aprovar o credor?',
                title: 'Aprovar credor',
                actions: {
                    false: 'Cancelar',
                    true: 'Aprovar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            try {
                const response = await axios.post(`/api/admin/users/approve/${this.$route.params.id}`, {});

                this.$dialog.notify.info("Credor aprovado com sucesso", { position: "top-right", timeout: 2000 });

                this.loadData();
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        async verifyAccount() {
            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja verificar a conta?',
                title: 'Verificar conta',
                actions: {
                    false: 'Cancelar',
                    true: 'Verificar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            try {
                const response = await axios.post(`/api/admin/users/verify-account/${this.$route.params.id}`, {});

                this.$dialog.notify.info("Conta verificada com sucesso", { position: "top-right", timeout: 2000 });

                this.loadData();
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        async load() {

            try {
                const result = await axios.get(`/api/admin/users/${this.$route.params.id}`);

                this.dataset.data = result.data.content;

                const registration = this.dataset.data.registration;
                if (registration) {
                    this.dataset.data.registration = registration.length < 14 ?  cpf.format(registration) : cnpj.format(registration) ;
                }
                 
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async loadEvents() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/api/admin/user-logs/user/${this.$route.params.id}`);

                this.dataset.events = result.data.content;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        buildPayload() {
            const data = {
                name: this.dataset.data.name,
                email: this.dataset.data.email,
                description: this.dataset.data.description,
                client: this.dataset.data.client,
                unit: this.dataset.data.unit,
                associations: this.normalizeAssociations(),
                credential: {
                    username: this.dataset.data.email,
                    password: this.dataset.data.password,
                },
                roles: this.dataset.data.roles

            }

            return data;
        },

        async updateUser() {

            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar os dados do credor?<br><br>Note que efetuar alteração no email ou CPF/CNPJ do credor, poderá causar instabilidade na identificação e acesso do mesmo ao sistema.',
                title: 'Atualizar credor',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = {
                name: this.dataset.data.name,
                email: this.dataset.data.email,
                registration: sanitizers.sanitizeCpfCnpj(this.dataset.data.registration)
            }

            try {
                const response = await axios.patch(`/api/admin/users/update/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("Credor atualizado com sucesso!", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        async blockUser() {

            const isBlocked = this.dataset.data.status === 3;
            const text = isBlocked ? 'Deseja desbloquear o credor?' : 'Deseja bloquear o credor?';
            const title = isBlocked ? 'Desbloquear credor' : 'Bloquear credor';
            const actionTrue = isBlocked ? 'Desbloquear' : 'Bloquear';
            const payload = isBlocked ? 0 : 3;

            const proceedAction = await this.$dialog.confirm({
                text,
                title,
                actions: {
                false: 'Cancelar',
                true: actionTrue,
                },
            });
                        
            if (!proceedAction) return;

            try {
                const response = await axios.patch(`/api/admin/users/update/${this.$route.params.id}`, {status: payload});

                this.$dialog.notify.info(`Credor ${isBlocked ? 'desbloqueado' : 'bloqueado'} com sucesso!`, { position: "top-right", timeout: 3000 });

                this.goBack()

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } 
        },

    },
};
</script>
  
<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}
</style>