<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="add"
                            :loading="dataForm.addLoading" v-if="page.isNew">Salvar</v-btn>
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="update"
                            :loading="dataForm.updateLoading" v-if="!page.isNew">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined
                            to="/qgc">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="Nome" v-model="dataset.data.name" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                       
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense id="registration" label="CPF/CNPJ"
                                                v-model="dataset.data.registration" required @input="formatRegistration()"
                                                    :rules="[$rules.required, (dataset.data.registration && dataset.data.registration.length < 15 ? $rules.cpf : $rules.cnpj)]"
                                                    outlined hide-details="auto">
                                                   
                                            </v-text-field>
                                        </v-col>
                                        
                                    </v-row>
                                    <v-row>

                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="Email" v-model="dataset.data.email" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="Telefone" v-model="dataset.data.phone_number" 
                                                    outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" sm="6">   
                                            <v-autocomplete dense :items="dataset.clients" label="Caso" v-model="dataset.data.client_id" 
                                            item-value="id" item-text="name" outlined hide-details="auto"
                                            required :rules="[$rules.required]"  no-data-text="Nenhum caso encontrado">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-select dense :items="dataset.creditorTypes" label="Classe" required
                                                v-model="dataset.data.type" item-value="id" item-text="name" outlined
                                                :rules="[$rules.required]" hide-details="auto">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="2">
                                            <v-currency-field label="Valor" dense
                                                v-model="dataset.data.amount" :allowNegative=false
                                                hide-details="auto" outlined required :rules="[$rules.required]">
                                            </v-currency-field>
                                        </v-col>
                                    </v-row>
                                
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

    </v-container>
</template>

<script>

import string from "@/common/util/string"
import creditorTypes from "@/common/data/creditor-types.data"
import { cpf, cnpj } from 'cpf-cnpj-validator'

import axios from "axios"

export default {
    name: "QgcDetails",

    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            breadcrumbItems: [],
            data: {  
                registration: ""
            },
            clients: [],
            creditorTypes: creditorTypes,
        },
       // dialogDynamicText: false,
       // dynamicTextSmallType: false,
        //selectedDoc: null,
       // dialogViewDoc: false,
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            showPassword: false,
            showPasswordConfirmation: false,
        },
       
    }),

    created() {
        this.loadData()
    },

    methods: {
        goBack() {
            this.$router.replace("/qgc");
        },

        // openDoc(cover) {
        //     this.selectedDoc = cover;
        //     this.dialogViewDoc = true;
        // },

        async loadData() {
            this.setPage();
            this.listBreadCrumbItems();
            await this.load();
            await this.loadClients();
        },

        setPage() {
            this.page.isNew = this.$route.params.id === "novo";
            this.page.title = `${this.page.isNew ? 'Novo credor' : 'Detalhes do credor'}`;
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "QGC",
                disabled: false,
                to: "/qgc",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.title,
                disabled: true,
                exact: true,
            });
        },

        formatCpfCnpj(value) {
            return value.length == 14 ? cnpj.format(value) : cpf.format(value);
        },

        formatRegistration() {
            this.dataset.data.registration = this.formatCpfCnpj(this.dataset.data.registration);
        },

        async load() {

            if (this.page.isNew) return;

            try {
                const result = await axios.get(`/api/admin/users-creditors/${this.$route.params.id}`);
                console.log('result ', result)

                const data = result.data.content;

                this.dataset.data = data;

                const registration = this.dataset.data.registration;
                if (registration) {
                    this.dataset.data.registration = registration.length < 14 ?  cpf.format(registration) : cnpj.format(registration) ;
                }

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async loadClients() {

            try {
                const result = await axios.get(`/api/clients`, { params: { start: 1, limit: 100000, search: {} } });

                const data = result.data.content.data
                
                data.sort((a, b) => a.name.localeCompare(b.name));
                
                this.dataset.clients = data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },


        async add() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o QGC?',
                title: 'Incluir QGC',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/api/admin/users-creditors', payload);

                this.$dialog.notify.info("QGC salvo com sucesso!", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar dados do QGC?',
                title: 'Atualizar QGC',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/api/admin/users-creditors/${this.$route.params.id}`, payload);

                this.$dialog.notify.info("QGC atualizado com sucesso!", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },

        // onFileSelected(file) {
        //     this.dataset.data.file = file;
        // },

        buildPayload() {
            const formData = new FormData()

            if (this.dataset.data.name)
                formData.append('name', this.dataset.data.name);

            if (this.dataset.data.email)
                formData.append('email', this.dataset.data.email);

            if (this.dataset.data.type)
                formData.append('type', this.dataset.data.type);

            if (this.dataset.data.amount)
                formData.append('amount', this.dataset.data.amount)

            if (this.dataset.data.client_id)
                formData.append('client_id', this.dataset.data.client_id)

            if (this.dataset.data.phone_number)
                formData.append('phone_number', this.dataset.data.phone_number);

            if (this.dataset.data.registration) {
                formData.append('registration', this.dataset.data.registration.replace(/\D/g, ''));
            }

            return formData;
        },

    },
};
</script>

<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}
</style>