<template>
    <v-container>

        <v-row>
            <v-col>
                <div class="font-weight-bold mt-10 title-page">Visão Geral</div>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card class="card-form-outlined">
                    <v-card-text>
                        <v-row justify="center" align="center">
                            <v-col align="center">
                                <span class="dashboard-card-title">{{ countUsers }}</span>
                            </v-col>
                            <v-col align="center">
                                <span class="dashboard-card-metric">Credores cadastrados</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card class="card-form-outlined">
                    <v-card-text>
                        <v-row justify="center" align="center">
                            <v-col align="center">
                                <span class="dashboard-card-title">{{ countClients }}</span>
                            </v-col>
                            <v-col align="center">
                                <span class="dashboard-card-metric">Devedoras cadastradas</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card class="card-form-outlined">
                    <v-card-text>
                        <v-row justify="center" align="center">
                            <v-col align="center">
                                <span class="dashboard-card-title">{{ countEvents }}</span>
                            </v-col>
                            <v-col align="center">
                                <span class="dashboard-card-metric">Hab/Div pendentes</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <span class="text-none" color="primary">Prazos para habilitação de crédito</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" class="mb-2" no-gutters v-for="(item, index) in deadlineEvents"
                            v-bind:key="index">
                            <v-col sm="3">
                                <div><strong><span class="text-overflow-handle">{{ truncateText(item.name, 25) }}</span></strong></div>
                            </v-col>
                            <v-col sm="6">
                                <v-progress-linear class="mb-2" :background-opacity="0.4"
                                    :value="item.totalDiffPercentage" :height="5"
                                    :color="resolveColorDeadlineEvents(item.totalDiffPercentage)"></v-progress-linear>

                                <span v-if="item.totalDiff == 0">
                                    <strong>Último dia</strong>
                                </span>
                                <span v-else>
                                    {{ item.totalDiff }} <strong>dias restantes</strong>
                                </span>

                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card class="card-form-outlined">
                    <v-card-title class="card-group-title">
                        <span class="text-none" color="primary">Divergências nos últimos 7 dias</span>
                    </v-card-title>
                    <v-card-text>
                        <highcharts class="hc" :options="lastEventsType1" ref="chart"></highcharts>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card class="card-form-outlined">
                    <v-card-title class="card-group-title">
                        <span class="text-none" color="primary">Habilitações nos últimos 7 dias</span>
                    </v-card-title>
                    <v-card-text>
                        <highcharts class="hc" :options="lastEventsType2" ref="chart"></highcharts>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card class="card-form-outlined">
                    <v-card-title class="card-group-title">
                        <span class="text-none" color="primary">Usuários cadastrados nos últimos 7 dias</span>
                    </v-card-title>
                    <v-card-text>
                        <highcharts class="hc" :options="lastUsers" ref="chart"></highcharts>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import 'moment/locale/pt-br';

import axios from "axios"

export default {
    data: () => ({
        user: null,
        lastUsers: {
            title: null,
            series: [
                {
                    data: [0, 0, 0, 0, 0, 0, 0],
                    type: 'column',
                    color: '#2b3985'
                },
            ],
            xAxis: {
                categories: []
            },
            yAxis: {
                text: "Usuários"
            }
        },
        countUsers: {},
        countEvents: 0,
        countClients: 0,
        deadlineEvents: [],
        lastEventsType1: {
            title: null
        },
        lastEventsType2: {
            title: null
        },
    }),

    mounted: function () {
        this.user = this.$root.user;
    },

    created() {
        this.loadLastUsers();
        this.loadLastEvents();
        this.loadCountUsers();
        this.loadCountClients();
        this.loadDeadlineEvents();
        this.loadCountEvents();
    },

    methods: {

        async loadLastUsers() {

            try {
                const result = await axios.get('/api/admin/dashboard/last-users', { params: {} });

                this.resolveChartLastUsers(result.data.content)

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }

        },

        async loadLastEvents() {

            try {
                const result = await axios.get('/api/admin/dashboard/last-events', { params: {} });

                this.resolveChartLastEvents(result.data.content)

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }

        },

        async loadCountUsers() {

            try {
                const result = await axios.get('/api/admin/dashboard/count-users', { params: {} });

                this.countUsers = result.data.content;

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }

        },

        async loadCountEvents() {

            try {
                const result = await axios.get('/api/admin/dashboard/count-events', { params: {} });

                this.countEvents = result.data.content;

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }

        },

        async loadCountClients() {

            try {
                const result = await axios.get('/api/admin/dashboard/count-clients', { params: {} });

                this.countClients = result.data.content;

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }

        },

        async loadDeadlineEvents() {

            try {
                const result = await axios.get('/api/admin/dashboard/deadline-events', { params: {} });

                const data = result.data.content;

                this.deadlineEvents = this.resolveDeadlineEvents(data);

            } catch (error) {
                console.log('error ', error)
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            }

        },

        resolveDeadlineEvents(data) {

            if (!Array.isArray(data)) return [];

            for (let item of data) {
                const startDate = this.$moment(item.start_event_at);
                const endDate = this.$moment(item.end_event_at);

                const totalDeadline = this.$moment(endDate).diff(startDate, 'd');
                const totalDiffDeadline = this.$moment(endDate).diff(new Date(), 'd');

                const totalDiff = this.$moment(endDate).diff(new Date(), 'days');
                const duration = this.$moment(endDate).diff(startDate, 'days')
                const totalDiffPercentage = (100 * (duration - totalDiff)) / duration;

                item.totalDiff = totalDiff;
                item.duration = duration;
                item.totalDiffPercentage = totalDiffPercentage;

                console.log('item.totalDiffDeadline ', item.totalDiffPercentage, totalDiff, duration)


                if (totalDiffPercentage > 0 && totalDiffPercentage < 50)
                    item.status = 4;
                if (totalDiffPercentage > 50 && totalDiffPercentage < 80)
                    item.status = 3;
                else
                    item.status = 1;
            }

            return data;
        },

        truncateText(text, maxLength) {
            return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
        },

        resolveColorDeadlineEvents(status) {
            console.log('status ', status)

            if (status > 0 && status < 50)
                return '#FFCC80';

            if (status > 50 && status < 80)
                return '#F9AA33';

            if (status > 80 && status < 90)
                return '#FF5252';

            return '#FF5252';
        },

        resolveChartLastUsers(data) {

            let categories = []

            this.lastUsers = {
                title: null,
                series: [
                    {
                        name: "Usuários",
                        data: [0, 0, 0, 0, 0, 0, 0],
                        type: 'column',
                        color: '#2b3985'
                    },
                ],
                xAxis: {
                    categories: []
                },
                yAxis: {
                    text: "Usuários"
                }
            }

            for (let index = 0; index < 7; index++) {
                let day = this.$moment().subtract(index + 1, 'days').format("DD/MM/YYYY")

                categories.push(day);
            }

            for (let item of data) {
                const date = this.$moment(item.date).format("DD/MM/YYYY");

                const dateIndex = categories.findIndex((x) => x === date);

                if (dateIndex === -1) continue;

                this.lastUsers.series[0].data[dateIndex] = item.total;
            }

            this.lastUsers.xAxis.categories = categories;
        },

        resolveChartLastEvents(data) {

            let categories = []

            const dataType1 = [0, 0, 0, 0, 0, 0, 0]
            const dataType2 = [0, 0, 0, 0, 0, 0, 0]

            for (let index = 0; index < 7; index++) {
                let day = this.$moment().subtract(index + 1, 'days').format("DD/MM/YYYY")

                categories.push(day);
            }

            for (let item of data) {
                const date = this.$moment(item.date).format("DD/MM/YYYY");

                const dateIndex = categories.findIndex((x) => x === date);

                if (dateIndex === -1) continue;

                dataType1[dateIndex] = item.total_type1;
                // dataType2[dateIndex] = item.total_type2;
            }

            this.lastEventsType1 = {
                title: null,
                series: [
                    {
                        name: "",
                        data: [],
                        type: 'column',
                        color: '#2b3985'
                    },
                ],
                xAxis: {
                    categories: categories
                },
                yAxis: {
                    text: ""
                }
            };

            this.lastEventsType2 = {
                title: null,
                series: [
                    {
                        name: "",
                        data: [],
                        type: 'column',
                        color: '#2b3985'
                    },
                ],
                xAxis: {
                    categories: categories
                },
                yAxis: {
                    text: ""
                }
            };

            this.lastEventsType1.series[0].data = dataType1;
            this.lastEventsType1.series[0].name = "Divergências";

            this.lastEventsType2.series[0].data = dataType2;
            this.lastEventsType2.series[0].name = "Habilitações";
        },
    }
};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.dashboard-card-title {
    color: var(--v-primary-base);
    font-size: 24px;
    font-weight: 700;
}

.dashboard-card-metric {
    color: var(--v-primary-base);
    font-size: 20px;
    font-weight: 500;
}
</style>
