<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Incluir Divergência</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Incluir Divergência',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn v-on="on" type="button" color="primary" elevation="0" @click="save"
                                    :loading="dataset.loading">Salvar</v-btn>
                            </template>
                            <span>Salvar hab/div</span>
                        </v-tooltip>
                        <v-btn id="btnGoBack" type="button" color="primary" elevation="0" outlined
                            to="/divergencias">Voltar</v-btn>
                    </div>
                </div>

            </v-col>
        </v-row>

        <v-form class="pt-10" ref="form" v-model="dataForm.validForm">


            <p class="font-weight-bold">Selecione a empresa devedora relacionada a esta habilitação ou divergência de
                crédito
            </p>

            <v-row>
                <v-col cols="12" sm="12">
                    <v-autocomplete :disabled="dataset.loading" dense required :items="dataset.clients"
                        label="Selecione a devedora" v-model="dataset.data.client" item-value="id" item-text="name"
                        :rules="[$rules.required]" outlined hide-details="auto">
                    </v-autocomplete>
                </v-col>
            </v-row>

            <template v-if="dataset.data.client">

                <v-row>
                    <v-col cols="4">
                        <v-text-field :disabled="dataset.loading" dense label="Nome credor"
                            v-model="dataset.data.user_name" required :rules="[$rules.required]" outlined
                            hide-details="auto">
                        </v-text-field>
                    </v-col>

                    <v-col cols="4">
                        <v-text-field dense id="registration" label="CPF/CNPJ credor"
                            v-model="dataset.data.user_registration" required @input="formatRegistration()"
                            :rules="[$rules.required, (dataset.data.user_registration && dataset.data.user_registration.length < 15 ? $rules.cpf : $rules.cnpj)]"
                            outlined hide-details="auto">
                        </v-text-field>
                    </v-col>

                    <v-col cols="4">
                        <v-text-field :disabled="dataset.loading" dense label="Email credor"
                            v-model="dataset.data.user_email" required :rules="[$rules.required]" outlined
                            hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <strong>Negócios</strong>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <p v-if="!dataset.loading">
                            Para adicionar um novo negócio,
                            <a href="#" @click.prevent="addItem"><strong>clique aqui</strong></a>
                        </p>
                    </v-col>
                </v-row>
            </template>

            <div v-for="(item, i) in dataset.data.items" :key="i">
                <v-row>
                    <v-col>
                        <strong>Negócio #{{ getItemNumber(i) }}</strong>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <div>
                                        <strong>Origem: </strong>
                                        <span>{{
                                            truncateText(item.origin || 'N/A', 30)
                                        }}</span>
                                        /
                                        <strong>Valor: </strong>
                                        <span v-if="item.amount">{{
                                            item.currency
                                            | enum(
                                                dataset.currencyTypes,
                                                'id',
                                                'currencyFormat'
                                            )
                                            }}
                                            {{ item.amount | currency }}</span><span v-else>N/A</span>
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col cols="12">
                                            <span class="mr-3"> <strong>Informe a origem do negócio</strong></span>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon small :color="'primary'" v-bind="attrs"
                                                        v-on="on">mdi-help-circle</v-icon>
                                                </template>
                                                <span>Digite o motivo que gerou os valores do negócio</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>

                                            <v-text-field :disabled="dataset.loading" dense label="Origem do negócio"
                                                v-model="item.origin" required :rules="[$rules.required]" outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <span class="mr-3"> <strong>Informe a moeda e o valor referente ao
                                                    negócio</strong></span>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon small :color="'primary'" v-bind="attrs"
                                                        v-on="on">mdi-help-circle</v-icon>
                                                </template>
                                                <span>Informe o tipo de moeda e o valor devido</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-select :disabled="dataset.loading" dense id="type"
                                                :items="dataset.currencyTypes" label="Selecione uma moeda"
                                                v-model="item.currency" item-value="id" item-text="name" outlined
                                                hide-details="auto">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-currency-field :disabled="dataset.loading" label="Valor" dense
                                                v-model="item.amount" :allowNegative="false" hide-details="auto"
                                                outlined required :rules="[$rules.required]">
                                            </v-currency-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <span class="mr-3"> <strong>Informe a classe no processo</strong></span>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon small :color="'primary'" v-bind="attrs"
                                                        v-on="on">mdi-help-circle</v-icon>
                                                </template>
                                                <span>Selecione a classe no processo</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-select dense :items="selectCreditorTypes()" label="Classe"
                                                v-model="item.type" item-value="id" item-text="name" outlined
                                                hide-details="auto" :rules="[$rules.required]">
                                            </v-select>
                                        </v-col>
                                    </v-row>

                                    <!-- DOCS COMPROBATÓRIOS -->

                                    <v-row>
                                        <v-col cols="12" lg="12">
                                            <p>
                                                <strong>Adicione documentos comprobatórios
                                                    do negócio</strong>
                                            </p>

                                            <v-stepper v-model="item.step" vertical flat>

                                                <!-- STEP 1 -->
                                                <v-stepper-step :complete="item.step > 1" step="1">

                                                    <v-row no-gutters justify='center'>
                                                        <v-col cols='11'>
                                                            <h4>Petição <span
                                                                    class='font-weight-light mr-10'>(opcional)</span>
                                                            </h4>
                                                            <small>Realize o envio da Petição</small>
                                                        </v-col>

                                                        <v-col cols='1'>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon small
                                                                        :color="item.step === 1 ? 'primary' : ''"
                                                                        v-bind="attrs"
                                                                        v-on="on">mdi-help-circle</v-icon>
                                                                </template>
                                                                <span>Informação sobre a Petição</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                </v-stepper-step>

                                                <v-stepper-content step="1">

                                                    <v-file-input dense accept="image/png, image/jpeg, application/pdf" outlined
                                                        counter :rules="[$rules.fileSize(15)]" show-size class="custom-file-input"
                                                        hint="O arquivo não pode ser superior a 15MB" hide-details="auto"
                                                        label="Petição" :clearable="true" :disabled="dataset.loading"
                                                        @change="onFileSelected('filePetition', $event)" ref="filePetition">
                                                    </v-file-input>

                                                    <v-btn class='mt-3' color="primary" @click="onInput(i, 1)"
                                                        :disabled="!hasDocument(i, 5, false)">
                                                        Próximo
                                                    </v-btn>
                                                    <v-btn class='ml-3 mt-3' @click="onCancel(i, 1)" text>
                                                        Cancelar
                                                    </v-btn>
                                                </v-stepper-content>

                                                <!-- STEP 2 -->
                                                <v-stepper-step :complete="item.step > 2" step="2">

                                                    <v-row no-gutters justify='center'>
                                                        <v-col cols='11'>
                                                            <h4> Procuração <span
                                                                    class='font-weight-light mr-5'>(opcional)</span>
                                                            </h4>
                                                            <small>Realize o envio da
                                                                Procuração</small>
                                                        </v-col>

                                                        <v-col cols='1'>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon small
                                                                        :color="item.step === 2 ? 'primary' : ''"
                                                                        v-bind="attrs"
                                                                        v-on="on">mdi-help-circle</v-icon>
                                                                </template>
                                                                <span>Informação sobre a Procuração</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                </v-stepper-step>

                                                <v-stepper-content step="2">

                                                    <v-file-input dense accept="image/png, image/jpeg, application/pdf" outlined
                                                        counter :rules="[$rules.fileSize(15)]" show-size class="custom-file-input"
                                                        hint="O arquivo não pode ser superior a 15MB" hide-details="auto"
                                                        label="Procuração" :clearable="true" :disabled="dataset.loading"
                                                        @change="onFileSelected('fileProcuration', $event)" ref="fileProcuration">
                                                    </v-file-input>

                                                    <v-btn class='mt-3' color="primary" @click="onInput(i, 2)"
                                                        :disabled="!hasDocument(i, 2, false)">
                                                        Próximo
                                                    </v-btn>
                                                    <v-btn class='ml-3 mt-3' @click="onCancel(i, 2)" text>
                                                        Cancelar
                                                    </v-btn>
                                                </v-stepper-content>

                                                <!-- STEP 3 -->
                                                <v-stepper-step :complete="item.step > 3" step="3">

                                                    <v-row no-gutters justify='center'>
                                                        <v-col cols='11'>
                                                            <h4> Planilha de Cálculos <span
                                                                    class='font-weight-light'>(opcional)</span></h4>
                                                            <small>Realize o envio da Planilha de
                                                                Cálculos</small>
                                                        </v-col>

                                                        <v-col cols='1'>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon small
                                                                        :color="item.step === 3 ? 'primary' : ''"
                                                                        v-bind="attrs"
                                                                        v-on="on">mdi-help-circle</v-icon>
                                                                </template>
                                                                <span>Informação sobre a Planilha de Cálculos</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>

                                                </v-stepper-step>

                                                <v-stepper-content step="3">

                                                    <v-file-input dense accept=".csv, .xls, .xlsx, application/vnd.ms-excel" outlined
                                                        counter :rules="[$rules.fileSize(15)]" show-size class="custom-file-input"
                                                        hint="O arquivo não pode ser superior a 10MB" hide-details="auto"
                                                        label="Planilha de Cálculos" :clearable="true" :disabled="dataset.loading"
                                                        @change="onFileSelected('fileSpreadsheet', $event)" ref="fileSpreadsheet">
                                                    </v-file-input>

                                                    <v-btn class='mt-3' color="primary" @click="onInput(i, 3)"
                                                        :disabled="!hasDocument(i, 6, false)">
                                                        Próximo
                                                    </v-btn>
                                                    <v-btn class='ml-3 mt-3' @click="onCancel(i, 3)" text>
                                                        Cancelar
                                                    </v-btn>
                                                </v-stepper-content>

                                                <!-- STEP 4 -->
                                                <v-stepper-step :complete="item.step > 4" step="4">

                                                    <v-row no-gutters justify='center'>
                                                        <v-col cols='11'>
                                                            <h4>Documentos Comprobatórios <span
                                                                    class='font-weight-light'>(obrigatório)</span></h4>
                                                            <small>Realize o envio de Documentos Comprobatórios</small>
                                                        </v-col>

                                                        <v-col cols='1'>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon small
                                                                        :color="item.step === 4 ? 'primary' : ''"
                                                                        v-bind="attrs"
                                                                        v-on="on">mdi-help-circle</v-icon>
                                                                </template>
                                                                <span>Informação sobre os Documentos
                                                                    Comprobatórios</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                </v-stepper-step>

                                                <v-stepper-content step="4">

                                                    <v-file-input v-model="proofFiles" 
                                                        small-chips 
                                                        :show-size="1000"
                                                        counter
                                                        dense
                                                        outlined
                                                        placeholder="Selecione os arquivos"
                                                        multiple
                                                        class="custom-file-input"
                                                        clearable 
                                                        label="Documentos Comprobatórios"
                                                        @change="proofFileAdded">
                                                        <template v-slot:selection="{ text, index}">
                                                            <v-chip small text-color="white" color="#295671" close @click:close="removeProof(index)">
                                                                {{ text }}
                                                            </v-chip>
                                                        </template>
                                                    </v-file-input>

                                                    <v-btn class='mt-3' color="primary" @click="onInput(i, 4)"
                                                        :disabled="hasDocument(i, 3, true)">
                                                        Próximo
                                                    </v-btn>
                                                    <v-btn class='ml-3 mt-3' @click="onCancel(i, 4)" text>
                                                        Cancelar
                                                    </v-btn>
                                                </v-stepper-content>

                                                <!-- STEP 5 -->
                                                <v-stepper-step step="5">

                                                    <v-row no-gutters justify='center'>
                                                        <v-col cols='11'>
                                                            <h4> Outros <span
                                                                    class='font-weight-light'>(opcional)</span></h4>
                                                            <small>Caso tenha mais documentos, faça o envio</small>
                                                        </v-col>

                                                        <v-col cols='1'>
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon small
                                                                        :color="item.step === 5 ? 'primary' : ''"
                                                                        v-bind="attrs"
                                                                        v-on="on">mdi-help-circle</v-icon>
                                                                </template>
                                                                <span>Informação sobre Outros documentos</span>
                                                            </v-tooltip>
                                                        </v-col>
                                                    </v-row>
                                                </v-stepper-step>

                                                <v-stepper-content step="5">


                                                    <v-file-input v-model="otherFiles" 
                                                        small-chips 
                                                        :show-size="1000"
                                                        counter
                                                        dense
                                                        class="custom-file-input"
                                                        outlined
                                                        placeholder="Selecione os arquivos"
                                                        multiple 
                                                        clearable 
                                                        label="Outros Documentos"
                                                        @change="otherFileAdded">
                                                        <template v-slot:selection="{ text, index}">
                                                            <v-chip small text-color="white" color="#295671" close @click:close="removeOther(index)">
                                                                {{ text }}
                                                            </v-chip>
                                                        </template>
                                                    </v-file-input>

                                                    <v-btn class='mt-3' @click="onCancel(i, 5)" text>
                                                        Cancelar
                                                    </v-btn>

                                                </v-stepper-content>
                                            </v-stepper>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12">
                                            <p>
                                                <strong>Informe uma observação referente ao
                                                    negócio</strong>
                                            </p>
                                            <v-textarea :disabled="dataset.loading" dense id="description"
                                                label="Informações Adicionais" :rules="[
                                                    $rules.maxlength(
                                                        item.observation,
                                                        500
                                                    )
                                                ]" counter="500" v-model="item.observation" outlined
                                                hide-details="auto" rows="2" auto-grow></v-textarea>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col>
                                            <v-btn color="primary" text :loading="dataset.loading"
                                                @click.native="removeItem(i)">
                                                <v-icon> mdi-delete-outline </v-icon>
                                                Excluir</v-btn>
                                        </v-col>
                                    </v-row>

                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
            </div>

        </v-form>

    </v-container>
</template>



<script>
import 'moment/locale/pt-br';

import { eventHub } from '@/main';

import sanitizers from '@/common/util/string.js'
import { cpf, cnpj } from 'cpf-cnpj-validator'

import currencyTypes from '@/common/data/currency-types.data';
import itemTypes from "@/common/data/item-types.data.js";
import creditorTypes from "@/common/data/creditor-types.data.js";

import { v4 as uuidv4 } from 'uuid';

import axios from 'axios';

export default {

    data: () => ({
      
        previousProofFiles: [],
        proofFiles: [],
        previousOtherFiles: [],
        otherFiles: [],
        valid: true,
        panel: [0],
        showConfirmationDialog: false,
        loadingDocuments: false,
        dataset: {
            currencyTypes: currencyTypes,
            contentUserAnonymous: '',
            contentUserLogged: '',
            clients: [],
            selectedEventItem: -1,
            data: {
                items: [],
            },
            loading: false,
            itemTypes: itemTypes,
            creditorTypes: creditorTypes,
            filePetition: null,
            fileProcuration: null,
            fileSpreadsheet: null,

        },
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            created: null
        },
        
    }),

    mounted: function () { },

    computed: {
        isAnyDocumentSelected() {
            if (this.currentDocType === 'idOtherDocuments') {
                return false;
            }
            return this.documentsCenter.some(doc => doc.isSelected);
        },

    },


    created() {
      
        this.loadClients();
    },

    watch: {
        proofFiles(val) {
            this.previousProofFiles = val
        },

        otherFiles(val) {
            this.previousOtherFiles = val
        }  
    },

    methods: {

        getDocTypeName(docTypeId) {
            let docType = this.dataset.itemTypes.find(type => type.id === docTypeId);
            return docType ? docType.name : 'Tipo Desconhecido';
        },

        removeProof (index) {
            this.proofFiles.splice(index, 1)
        },

        removeOther (index) {
            this.otherfiles.splice(index, 1)
        },

        otherFileAdded () {
            console.log(this.otherFiles)
            if (this.previousOtherFiles.length > 0) {
                this.otherFiles.push(...this.previousOtherFiles)
            }
        },

        proofFileAdded () {
            console.log(this.proofFiles)
            if (this.previousProofFiles.length > 0) {
                this.proofFiles.push(...this.previousProofFiles)
            }
        },

       
        selectCreditorTypes() {

           // const client_id = this.dataset.data.client

           // let client = this.dataset.clients.find(item => item.id == client_id);

           // if (client.is_event_opened === true) {
                return this.dataset.creditorTypes;
           // } else {
           //     return this.dataset.creditorTypes.filter(type => type.id === 1);
           // }
        },

        checkDocumentsBeforeClose() {
            if (this.documentsCenter.some(doc => doc.isSelected)) {
                this.showConfirmationDialog = true;
            } else {
                this.closeModal();
            }
        },
        

        truncateText(text, maxLength) {
            return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
        },


        onFileSelected(field, file) {
            this.dataset.data[field] = file;
        },


        onFileSelectedProof(field, file) {
            this.dataset.data[field] = file;
        },

        onFileSelectedOthers(field, event) {
            if (event && event.length > 0) {
                this.dataset.data[field] = Array.from(event);
            } else {
                this.dataset.data[field] = [];
            }
        },
    
        hasDocument(index, type, required) {
            if (!required) return true;

            const items = this.dataset.data.items[index].files.filter((x) => x.type == type);

            return items.length > 0;
        },

        formatCpfCnpj(value) {
            return value.length == 14 ? cnpj.format(value) : cpf.format(value);
        },

        formatRegistration() {
            this.dataset.data.user_registration = this.formatCpfCnpj(this.dataset.data.user_registration);
        },

        onCancel(index, step) {
            if (step > 1) {
                this.dataset.data.items[index].step = step - 1;
            }

        },

        getItemNumber(i) {
            return i + 1;
        },

        onInput(index, step) {
            if (!this.dataset.data.items[index].step) this.dataset.data.items[index].step = 1;

            if (this.hasDocument(index, step)) {
                this.dataset.data.items[index].step = step + 1;
            } else {
                this.$dialog.notify.error('Selecione um documento antes de continuar', {
                    position: 'top-right',
                    timeout: 2000
                });
            }
        },


        async loadClients() {
            try {
                const result = await axios.get(`/api/clients`, { params: { limit: 100000 } });

                if (!result.data.content.data) return;

                this.dataset.clients = result.data.content.data;
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                });
            }
        },

        async loadUserInfo() {
            this.dataset.loading = true;

            try {
                const result = await axios.get('/api/users/info');

                this.user = result.data.content;
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 5000
                });
            } finally {
                this.dataset.loading = false;
            }
        },

        async load() {
            this.dataset.loading = true;

            try {
                const result = await axios.get('/api/user-events/request');

                this.data = result.data.content;
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 5000
                });
            } finally {
                this.dataset.loading = false;
            }
        },


        validateFormItems() {
            if (!this.dataset.data.items.length) return false;

            for (let item of this.dataset.data.items) {
                if (!item.origin || !item.amount || !item.currency) {
                    return false;
                }

                if (!item.type || (Array.isArray(item.file4) && item.file4.length === 0)) {
                    return false;
                }
            }
            return true;
        },


        async save() {

            if (!this.$refs.form.validate()) {

                this.$dialog.notify.error(
                    'Preencha as informações obrigatórias de um negócio antes de continuar',
                    {
                        position: 'top-right',
                        timeout: 2000
                    }
                );
                return;
            }


            if (!this.dataset.data.items.length) {
                this.$dialog.notify.error(
                    'Preencha as informações de um negócio antes de continuar',
                    {
                        position: 'top-right',
                        timeout: 2000
                    }
                );
                return;
            }

            if (!this.validateFormItems()) {
                this.$dialog.notify.error('Preencha todos os campos antes de continuar', {
                    position: 'top-right',
                    timeout: 2000
                });
                return;
            }

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja concluir o formulário?',
                title: 'Finalizar',
                actions: {
                    false: 'Cancelar',
                    true: 'Concluir'
                }
            });

            if (!proceedAction) return;

            this.dataset.loading = true;

            const payload = this.buildPayload();

            try {
        
                const response = await axios.post('/api/admin/user-events/request', payload);

                await this.$dialog.confirm({
                    // text: 'Informações enviadas com sucesso! Você receberá um e-mail assim que sua solicitação for analisada.',
                    text: 'Habilitação / Divergência criada com sucesso!',
                    title: 'Sucesso',
                    actions: {
                        true: 'OK'
                    }
                });

                this.dataset.data = { items: [] };

                this.$router.replace('/divergencias');
                
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                });
            } finally {
                this.dataset.loading = false;
            }
        },


        buildPayload() {
            let payload = new FormData();

            payload.append('client_id', this.dataset.data.client);
            payload.append('user_name', this.dataset.data.user_name);
            payload.append('user_email', this.dataset.data.user_email);
            payload.append('user_registration', sanitizers.sanitizeCpfCnpj(this.dataset.data.user_registration));

            this.dataset.data.items.forEach((item, index) => {
                payload.append(`items[${index}][currency]`, item.currency);
                payload.append(`items[${index}][origin]`, item.origin);
                payload.append(`items[${index}][amount]`, item.amount);
                payload.append(`items[${index}][observation]`, item.observation || '');
                payload.append(`items[${index}][type]`, item.type);

                const appendFile = (file, type) => {
                    const uuid = uuidv4();
                    const originalFileName = file.name;
                    const fileName = `${uuid}_${type}_${originalFileName}`;
                    payload.append(`items[${index}][files][]`, file, fileName);
                };

                if (this.dataset.data.filePetition) {
                    appendFile(this.dataset.data.filePetition, 5);
                }

                if (this.dataset.data.fileProcuration) {
                    appendFile(this.dataset.data.fileProcuration, 2);
                }

                if (this.dataset.data.fileSpreadsheet) {
                    appendFile(this.dataset.data.fileSpreadsheet, 6);
                }

                if (this.proofFiles && this.proofFiles.length > 0) {
                    this.proofFiles.forEach((file) => {
                        appendFile(file, 3);
                    });
                }

                if (this.otherFiles && this.otherFiles.length > 0) {
                    this.otherFiles.forEach((file) => {
                        appendFile(file, 4);
                    });
                }
            });

            return payload;
        },

       
        addItem() {
            this.dataset.data.items.push({
                currency: 1,
                step: 1,
                files: []
            });
        },

        removeItem(index) {
            this.dataset.data.items.splice(index, 1);
        },
      
    }

}

</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: 'Raleway';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    font-family: 'Raleway';
    font-size: 14px;
    font-style: normal;
}

.custom-modal-title {
    font-size: 20px;

    @media (max-width: 600px) {
        font-size: 14px;
    }
}

.custom-file-input {
    padding-top: 5px !important;
  }
</style>