<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn v-on="on" id="btnSave" type="button" elevation="0" color="primary" dark @click="add"
                                    :loading="dataForm.addLoading" v-if="page.isNew">Salvar</v-btn>
                            </template>
                            <span>Salvar informações</span>
                        </v-tooltip>    
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn v-on="on" id="btnSave" type="button" elevation="0" color="primary" dark @click="update"
                                    :loading="dataForm.updateLoading" v-if="!page.isNew">Atualizar</v-btn>
                            </template>
                            <span>Atualizar informações</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-btn v-on="on" id="btnBack" type="button" elevation="0" color="primary" 
                                    outlined to="/agcs">Voltar</v-btn>
                            </template>
                            <span>Voltar para listagem</span>
                        </v-tooltip>    
                    </div>
                </div>
            </v-col>
        </v-row>
     
        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field dense label="Nome da assembléia" v-model="dataset.data.agc_name" disabled
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="Nome da votação" v-model="dataset.data.name" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="Descrição da votação" v-model="dataset.data.description" required
                                                :rules="[$rules.required]" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                            
                                    </v-row>

                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-select dense :items="dataset.agcVotingTypes" label="Tipo" required
                                                v-model="dataset.data.type" item-value="id" item-text="name" outlined
                                                :rules="[$rules.required]" hide-details="auto">
                                            </v-select>
                                        </v-col>
                                        
                                        <v-col cols="12" sm="6">
                                            <v-select dense :items="dataset.agcVotingStatus" label="Status" 
                                                required :rules="[$rules.required]" v-model="dataset.data.status" 
                                                item-value="id" item-text="name" outlined hide-details="auto">
                                            </v-select>
                                        </v-col>    
                                    </v-row>
                                    
                                </v-card-text>
                            </v-card>
                        </v-col>
                        
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
       
    </v-container>
</template>

<script>

import string from "@/common/util/string"

import axios from "axios"

import { eventHub } from "@/main";


import agcVotingStatus from "@/common/data/agc-voting-status.data"
import agcVotingTypes from "@/common/data/agc-voting-types.data"

export default {
    name: "VotingDetails",
  
    data: () => ({
        page: {
            isNew: false,
            title: "",
        },
        dataset: {
            breadcrumbItems: [],
            data: {  
                creditor_registration: ""
            },
            agcVotingStatus: agcVotingStatus,
            agcVotingTypes: agcVotingTypes,
            modalResult: null,
            generalMeetings: [],
        },
      
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            showPassword: false,
            showPasswordConfirmation: false,
        },
       
    }),

    created() {
       this.loadData()
    },

    mounted() {
        let data = this.$route.params.data;

        console.log('data ', data)

        if (data.new){
            this.page.isNew = true;
            this.dataset.data.agcId = data.id
            this.dataset.data.agc_name = data.agc_name;
        }else{
            this.page.isNew = false;
            this.dataset.data.agcId = data.agc_id;
            this.dataset.data.votingId = data.voting_id;
            this.dataset.data.agc_name = data.agc_name;
            this.dataset.data.name = data.voting_name;
            this.dataset.data.description = data.description;
            this.dataset.data.type = data.type;
            this.dataset.data.status = data.status;
        }

        console.log("data is", data, this.page.isNew);

        this.setPage();
    },


    methods: {
        goBack() {
            this.$router.replace("/agcs");
        },

        async loadData() {
           // this.setPage();
            this.listBreadCrumbItems();
            //await this.load();
            //await this.loadGeneralMeetings();
        },


        setPage() {
         
            this.page.title = this.page.isNew ? 'Nova votação' : 'Detalhes da votação';
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Votação",
                disabled: false,
                to: "/votacao",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.title,
                disabled: true,
                exact: true,
            });
        },

        async load() {

            if (this.page.isNew) return;

           // if (this.clientId) return;

            try {
                const result = await axios.get(`/api/admin/general-meeting-votings/${this.$route.params.id}`);
                console.log('result ', result)

                const data = result.data.content;

                this.dataset.data = data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        // async loadGeneralMeetings() {

        //     try {
        //         const result = await axios.get(`/api/general-meetings`, { params: { start: 1, limit: 100000, search: {} } });

        //         const data = result.data.content
                
        //         data.sort((a, b) => a.name.localeCompare(b.name));
                
        //         this.dataset.generalMeetings = data;

        //     } catch (error) {
        //         this.$dialog.notify.error(this.$codes(error), {
        //             position: 'top-right',
        //             timeout: 2000
        //         })
        //     }
        // },


        async add() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir a votação?',
                title: 'Incluir votação',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.post('/api/admin/general-meeting-votings', payload);

                this.$dialog.notify.info("Votação criada com sucesso!", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }

        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar dados da votação?',
                title: 'Atualizar votação',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.patch(`/api/admin/general-meeting-votings/${this.dataset.data.votingId}`, payload);

                this.$dialog.notify.info("Votação atualizada com sucesso!", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },
       
        buildPayload() {
            const formData = new FormData()

            if (this.page.isNew){
                formData.append('general_meeting_id', this.dataset.data.agcId);
            }else{
                formData.append('id', this.dataset.data.votingId);
            } 

            if (this.dataset.data.type)
                formData.append('type', this.dataset.data.type);

            if (this.dataset.data.status)
                formData.append('status', this.dataset.data.status)

            if (this.dataset.data.name)
                formData.append('name', this.dataset.data.name)

            if (this.dataset.data.description)
                formData.append('description', this.dataset.data.description)

            return formData;
        },

    },
};
</script>

<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}
</style>