<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Processos Mais Consultados</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Processos Mais Consultados',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Inserir Processo</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="insertItem()">
                            <v-row>
                                <v-col cols="12">
                                    <v-autocomplete dense id="type" :items="dataset.clients" label="Caso" 
                                        v-model="dataset.data.client_id" item-value="id" item-text="name" outlined
                                        hide-details="auto" no-data-text="Nenhum caso encontrado">
                                    </v-autocomplete>
                                </v-col>      
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn id="btnFilter" type="submit" color="primary" dark elevation="2"
                                        >Inserir</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
           <v-container fluid justify-center>
                <v-flex class="elevation-1">
                    <v-toolbar class="mb-2" color="primary" dark>
                        <v-toolbar-title>Ordem de Apresentação</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="action('edit')" v-if="!editing" class="mr-5">
                            <v-icon>mdi-sort</v-icon>
                            Ordenar
                        </v-btn>
                        <v-btn icon @click="action('done')" v-if="editing" class="mr-10">
                            <v-icon>mdi-check</v-icon>
                            Salvar
                        </v-btn>
                        <v-btn icon @click="action('undo')" v-if="editing" class="mr-10 ml-10">
                            <v-icon>mdi-close</v-icon>
                            Desfazer
                        </v-btn>
                    </v-toolbar>
                    <v-list one-line>
                        <draggable :options="options" v-model="items">
                            <template v-for="(v, i) in items">
                                <v-list-item :key="v.order" avatar>
                                    <v-list-item-avatar color="grey">
                                        <span style="user-select:none;">{{ i + 1 }}</span>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="v.client.name"></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon @click="removeItem(i, v.id)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                        </draggable>
                    </v-list>
                </v-flex>
            </v-container>
        </v-row>
    </v-container>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import object from "@/common/util/object"
import eventStatus from "@/common/data/event-status.data";
import draggable from 'vuedraggable';

Vue.use(VueLodash)

export default {
    name: "MostConsultedList",

    components: {
        draggable,
    },

    data: () => ({
        dataset: {
            data: {},
            clients: [{ id: null, name: "Todos: " }],
        },
        before: null,
        editing: false,
        items: [],
        dataList: {
            data: [],
            loading: false,
        },
    }),

    computed: {
        options () {
            return {
                disabled: !this.editing
            }
        }
    },

    created() {
        this.load();
        this.loadClients();
    },

    methods: {
        
        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },

        async load() {        

            this.dataList.loading = true;
            
            try {

                const result = await axios.get('/api/most-consulted-processes');

                this.items = result.data.content;
            
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async loadClients() {

            try {
                const result = await axios.get(`/api/clients`, { params: { limit: 10000000 } });

                const data = result.data.content.data;

                for (const obj2 of this.items) {
                    const index = data.findIndex(obj1 => obj1.id === obj2.client_id);
                    if (index !== -1) {
                        data.splice(index, 1);
                    }   
                }

                data.sort((a, b) => a.name.localeCompare(b.name));

               this.dataset.clients = data;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async insertItem(){
        
            if (!this.dataset.data.client_id) {

                this.$dialog.notify.error("Nenhum Caso selecionado", { position: "top-right", timeout: 2000 });
  
                return;
            }

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja incluir o processo na lista?',
                title: 'Incluir Processo',
                actions: {
                    false: 'Cancelar',
                    true: 'Salvar'
                }
            })

            if (!proceedAction) return;

            const payload = this.buildPayload();
         
            try {
                const response = await axios.post('/api/admin/most-consulted-processes/', payload);
                
                this.$dialog.notify.info("Processo salvo na lista com sucesso", { position: "top-right", timeout: 2000 });

                await this.load();
                await this.loadClients();

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        action(e) {
            switch (e) {
                case 'edit':
                    this.before = Object.assign([], this.items);
                    break;
                case 'undo':
                    this.items = this.before;
                    break;
                case 'done':
                    this.saveMostConsultedProcess()
                    break;
                default:
                    break;
            }

            this.editing = !this.editing
        },

        async saveMostConsultedProcess() {

            const data = this.items.map(item => ({
                id: item.id,
                client_id: item.client_id,
                order: item.order
            }))

            data.forEach((item, index) => {
                item.order = index + 1;
            });

            try {
                const response = await axios.patch('/api/admin/most-consulted-processes', { list: data  });

                this.$dialog.notify.info("Nova ordenação da lista salva com sucesso", { position: "top-right", timeout: 2000 });
                
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 2000 })
            }
        },

        async removeItem(index, id) {
            
            const proceedAction = await this.$dialog.confirm({
                text: 'Tem certeza que deseja continuar?',
                title: 'Excluir Processo da Lista de Apresentação',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })
            
            if (!proceedAction) return;
            
            this.items.splice(index, 1);

            try {

                const result = await axios.delete(`/api/admin/most-consulted-processes/${id}`)

                this.$dialog.notify.info("Processo removido da lista com sucesso", { position: "top-right", timeout: 2000 });

                await this.load();
                await this.loadClients();

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        buildPayload() {

            const lastValue = this.items.reduce((max, obj) => (obj.order > max ? obj.order : max), -Infinity);

            const data = {
                order: isFinite(lastValue) ? lastValue + 1 : 1,
                client: this.dataset.data.client_id 
            } 

            return data;
        },

    }
};
</script>