<template>
    <v-container class="py-7">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Incidentes</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Incidentes',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnNew" type="button" color="primary" elevation="0"
                            to="/incidentes/novo">Incluir</v-btn>
                    </div>
                </div>

            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="filter-card">
                    <v-card-title class="filter-card-title">Filtro</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="load()">
                            <v-row>
                                <v-col  cols="12" sm="6">
                                    <v-text-field dense id="name" label="Nome" v-model="dataList.filter.name" hide-details
                                        outlined></v-text-field>
                                </v-col>
                                <v-col  cols="12" sm="6">
                                    <v-text-field dense id="registrastion" label="CPF/CNPJ" v-model="dataList.filter.registration" hide-details
                                        outlined></v-text-field>   
                                </v-col>
                            </v-row>
                            <v-row>

                                <v-col cols="12" sm="6">   
                                    <v-autocomplete dense :items="dataset.clients" label="Caso" v-model="dataList.filter.client" 
                                    item-value="id" item-text="name" outlined hide-details="auto"
                                    no-data-text="Nenhum caso encontrado">
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-select dense :items="dataset.incidentResultStatusFilter" label="Status" v-model="dataList.filter.status" 
                                        item-value="id" item-text="name" outlined hide-details="auto">        
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="2">
                                    <v-text-field dense id="code" label="Número" v-model="dataList.filter.code" hide-details
                                        outlined></v-text-field>
                                </v-col>
                            </v-row>
                                
                            <v-row>
                                <v-col cols="12">
                                    <v-btn id="btnFilter" type="submit" color="primary" dark elevation="2">Filtrar</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="table-card">
                    <v-card-title class="table-card-title d-flex justify-space-between">Incidentes
                        <v-spacer></v-spacer>
                        <div class="icon-container">
                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-icon icon v-on="on" @click="load(true)" size="large">
                                        mdi-microsoft-excel
                                    </v-icon>
                                </template>
                                <span>Gerar CSV</span>
                            </v-tooltip>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhum incidente foi encontrado" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">

                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                            </template>

                            <template v-slot:[`item.status`]="{ item }">
                                <v-chip label text-color="primary">
                                    {{ getStatusName(item.status) }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.creditor_registration`]="{ item }">
                                {{ formatCpfCnpj(item.creditor_registration) }}
                            </template>

                            <template v-slot:[`item.email`]="{ item }">
                                {{ item.email && item.email ? item.email : 'N/A' }}
                            </template>

                            <template v-slot:[`item.name`]="{ item }">
                                {{ item.name && item.name ? item.name : 'N/A' }}
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-row align="center">
                                    <v-icon :disabled="!$check('users/1', ['PATCH'])" @click="viewItem(item)" size="large"
                                        class="me-2">
                                        mdi-pencil-outline
                                    </v-icon>

                                    <v-icon :disabled="!$check('users/1', ['DELETE'])" @click="deleteItem(item)" size="large"
                                        class="me-2">
                                        mdi-delete
                                    </v-icon>
                                </v-row>    
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';
import { cpf, cnpj } from 'cpf-cnpj-validator'
import incidentResultStatus from "@/common/data/incident-result-status.data"
import incidentResultStatusFilter from "@/common/data/incident-result-status.data"

import object from "@/common/util/object"

Vue.use(VueLodash)

export default {
    name: "IncidentList",

    data: () => ({
        dataset: {
            data: {},
            clients: [],
            incidentResultStatusFilter: [{ id: "", name: "Todos"}, ...incidentResultStatusFilter],
        },
        incidentResultStatus: incidentResultStatus,
        dataList: {
            filter: {
                name: "",
                registration: "",
                client: "",
                code: "",
                status: "",

            },
            headers: [
                { text: "Número", value: "code" },
                { text: "Credor", value: "creditor_name" },
                { text: "CPF/CNPJ", value: "creditor_registration" },
                { text: "Status", value: "status" },
                { text: "Criado em", value: "created_at" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                size: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20, 30, 40, 50],
                pageText: '{0}-{1} de {2}',
            }
        },
    }),

    created() {
        this.load();
        this.loadClients();
    },

    methods: {
        
        pageChanged(page) {            
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {    
            this.dataList.options.size = size;
            this.load();
        },

        getColorByStatus(status) {
            switch (status) {
                case 0:
                    return 'warning'
                case 1:
                    return 'accent'
                case 2:
                    return 'error'
                default:
                    break;
            }
        },

        getStatusName(statusId) {
            const status = this.incidentResultStatus.find(s => s.id === statusId);
            return status.name ? status.name : 'N/A';
        },
      

        formatCpfCnpj(value) {
            return value.length == 14 ? cnpj.format(value) : cpf.format(value);
        },

        debounce: _.debounce(function () {
            this.reload();
        }, 500),

        reload: function () {
            this.load();
        },


        async load(exportCsv) {

            let search = Object.fromEntries(Object.entries(this.dataList.filter).filter(([_, v]) => v || v === 0))

            search = !object.isEmpty(search) ? search : null;

            this.dataList.loading = true;

            var limit = exportCsv ? 1000000 : (this.dataList.options.itemsPerPage || 10);

            var start = (exportCsv !== undefined && exportCsv) ? 1 : this.dataList.options.page;

            try {
                const result = await axios.get('/api/admin/judicial-incidents', { params: { page: start, limit: limit, search: search } });

                if(!exportCsv) {
                    this.dataList.data = result.data.content.data;
                    this.dataList.count = result.data.content.paging.total;
                    
                    return;
                }

                this.generateCSV(result.data.content.data)

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },


        async loadClients() {

            try {
                const result = await axios.get(`/api/clients`, { params: { start: 1, limit: 100000, search: {} } });

                const data = result.data.content.data
                
                data.sort((a, b) => a.name.localeCompare(b.name));
                
                this.dataset.clients = [{ id: "", name: "Todos " }, ...data];//[{"name": "Todos"}, ...data];

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        viewItem(item) {
            this.$router.push(`incidentes/${item.id}`);
        },

        async deleteItem(item) {

            if (!item || !item.id) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Tem certeza que deseja continuar?',
                title: 'Excluir Incidente',
                actions: {
                    false: 'Cancelar',
                    true: 'Excluir'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true

            try {

                const result = await axios.delete(`/api/admin/judicial-incidents/${item.id}`)

                this.$dialog.notify.info("Incidente excluído com sucesso!", { position: "top-right", timeout: 2000 });

                this.load()

            } catch (error) {

                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })

            } finally {
                this.dataList.loading = false;
            }

        },

        async generateCSV(data) {

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja exportar os dados para CSV?',
                title: 'Exportar dados',
                actions: {
                    false: 'Cancelar',
                    true: 'Exportar'
                }
            })

            if (!proceedAction) return;

            if (data.length == 0) {
                this.$dialog.notify.error("Sem dados para gerar arquivo CSV!", { position: "top-right", timeout: 2000 });

                return;
            }

            const newData = data.map(objeto => ({
                "NÚMERO": objeto.code,
                "NOME CREDOR": objeto.creditor_name,
                "CPF/CNPJ": this.formatCpfCnpj(objeto.creditor_registration),
                "NOME DEVEDORA": objeto.client.name,
                "RESERVA DE VALOR": objeto.amount_reservation ? objeto.amount_reservation.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "N/A",
                "VALOR DO CRÉDITO": objeto.approved_amount ? objeto.approved_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "N/A",
                "TIPO": objeto.type === 1 ? "Habilitação de crédito retardatária" : 
                        (objeto.type === 2 ? "Impugnação de crédito retardatária" : 
                        (objeto.type === 3 ? "Impugnação de crédito tempestiva" : "N/A")),
                "STATUS": objeto.status === 1 ? "Deferido" : 
                        (objeto.status === 2 ? "Parcialmente deferido" : 
                        (objeto.status === 3 ? "Indeferido" : 
                        (objeto.status === 4 ? "Extinto" : 
                        (objeto.status === 5 ? "Pendente de julgamento" : "N/A")))),
                "CRIADO EM": objeto.created_at,

            }));

            try{

                const header = Object.keys(newData[0]);
                const csvContent = [header.join(';')];

                newData.forEach(item => {
                    const row = header.map(key => {
                        const value = item[key];
                        return typeof value === 'object' && value !== null ? value.join(';') : value;
                    }).join(';');
                    csvContent.push(row);
                });

                const csv = csvContent.join('\r\n');

                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', 'Incidentes.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                this.$dialog.notify.info("Arquivo CSV gerado com sucesso!", { position: "top-right", timeout: 2000 });

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },
    },
};
</script>